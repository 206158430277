.tooltipTrigger {
  justify-content: center;
  align-items: center;
  position: relative;
  display: inline-flex;

  &Clickable {
    cursor: pointer;
  }
}

.tooltipTriggerDefault {
  img {
    width: 17px;
  }
}

// Mui-tooltip overrides
.tooltipOverride {
  font-size: 14px !important;
  line-height: 22px !important;
  pointer-events: auto;
  &[class~="pad"]{
    padding: 0.75rem 1rem !important;
    padding-right: 0.5rem !important;
  }
  &[class~="no-pad"]{
    padding: 0 !important;
  }
  &[class~="light"] {
    background-color: white !important;
    color: black !important;
  }
  &[class~="dark"] {
    background-color: black !important;
    color: white !important;
  }

  & > div {
    max-height: 460px;
    padding-right: 0.5em;
    overflow: auto;
    &::-webkit-scrollbar-track {
      background-color: white !important;
    }
  }
}

.arrowOverride {
  margin-top: -50%;
  &[class~="light"] {
    color: white !important;
  }
  &[class~="dark"] {
    color: black !important;
  }
}
