@import "../../../styles/variables";

.teamCoaches {
  position: relative;

  &__Row {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    & > * {
      margin-right: 10px;

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 57px;
        min-width: 57px;
        max-width: 57px;
        margin-right: 0;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
