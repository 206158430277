@import "../../styles/variables";

.accountPage {
  display: grid;
  grid-template-columns: 230px 1fr;
  min-height: 100%;

  &Sidebar {
    margin: 0;
    padding: 20px 0;
    list-style: none;
    background-color: $tuna;

    &Link {
      padding: 0;
      margin: 0;
      & > a {
        display: inline-block;
        padding: 10px 0px 10px 20px;
        color: $white;
        width: calc(100% - 20px);
        position: relative;
        &:hover {
          color: $bright-sun;
        }
        &[class~="active"] {
          &:after {
            content: "";
            position: absolute;
            top: 5px;
            bottom: 5px;
            right: 0;
            width: 4px;
            background-color: $sunglow;
          }
        }
      }
    }
  }

  &Content {
    padding: 20px 60px;
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 17px;
      font-weight: normal;
    }
  }
}

.AccountSettings {
  &Fields {
    border: none;
    display: grid;
    grid-template-columns: 160px 250px;
    padding: 20px 0;

    & > label {
      align-self: center;
      margin-bottom: 24px;
    }

    .buttonBlock {
      display: inline;
    }
    .deleteButton {
      text-transform: uppercase;
      color: $burnt-sienna;
    }

      .editTeamButton {
        text-transform: uppercase;
        color: var(--ion-color-tertiary);
      }
  }
  &PrivacyPolicyLink {
    color: $white;
    opacity: 0.8;
    margin-top: 18px;
    img {
      transform: rotate(180deg);
      vertical-align: middle;
      margin-top: -0.25em;
      margin-left: 8px;
    }
    &:hover {
      opacity: 1;
    }
  }
}

.NoLicenseText {
  margin-bottom: 1.5rem;
}

.SubscriptsTabUpdateButton {
  margin-right: 12px;
}

.TeamListing {
  margin: 0 0 60px;
  padding: 0;
  max-width: 800px;
  &Single {
    display: grid;
    grid-template-columns: 74px 1fr 120px 140px 75px;
    gap: 18px;
    background-color: $mirage;
    border-radius: 8px;
    padding: 18px 27px;
    align-items: center;
    margin-bottom: 16px;

    img {
      max-width: 100%;
    }
    &Details {
      font-size: 14px;
      color: $spun-pearl;
      margin-bottom: 16px;
      strong {
        display: inline-block;
        font-size: 17px;
        padding-right: 8px;
        color: $white;
        overflow-wrap: break-word;
        max-width: 200px;
      }
      &Address {
        color: $white;
      }
      &SmallHeader {
        text-transform: uppercase;
        color: $white;
        font-size: 11px;
        display: block;
      }
      &Color {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-right: 8px;
        margin-top: 12px;
      }
      &Setup {
        width: 100px;
        margin: 12px 0px;
      }
      &Tooltip {
        list-style: none;
        margin:0;
        padding: 0;
        font-size: 14px;
        &Line{
          margin: 10px 5px;
          margin-right: 10px; // Makes the centering look better
          > a {
            color: $dodger-blue-2;
            text-decoration: underline;
            &:hover {
              color: $dodger-blue;
            }
          }
        }
        &Bullet{
          display: inline-block;
          width: 16px;
          height: 16px;
          border: 2px solid $alto;
          border-radius: 10px;
          position: relative;
          vertical-align: middle;
          margin-right: 14px;
          & > svg {
            position: absolute;
            left: 2px;
            top: 3px;
            width: 12px;
            height: 12px;
            fill: transparent;
            display: none;
          }
          &[class~="active"]{
            border-color: $lightning2;
            & > svg {
              display: block;
            }
          }
        }
      }
    }
  }
  &Empty {
    display: grid;
    grid-template-columns: 74px 1fr 120px;
    gap: 18px;
    background-color: $tuna;
    border-radius: 8px;
    padding: 18px 27px;
    align-items: center;
    margin-bottom: 16px;
    &Team {
      border: 2px solid $abby;
      border-radius: 37px;
      width: 74px;
      height: 74px;
      font-size: 11px;
      color: $spun-pearl;
      text-align: center;
      padding: 9px;
      strong {
        display: block;
        font-size: 24px;
        color: $white;
      }
    }
  }
  &PendingInvite {
    display: grid;
    grid-template-columns: 74px 1fr 80px 80px;
    gap: 18px;
    background-color: $mirage;
    border-radius: 8px;
    padding: 18px 27px;
    align-items: center;
    margin-bottom: 16px;

    &TeamName {
      @extend %body-strong;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.teamEdit {
  &Line {
    border-color: $trout;
    margin: 0 -1rem;
  }
  &Wrap {
    margin: 1.25rem 0;
  }
  &Buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 1rem;
    & :first-child {
      text-align: left;
    }
    & :last-child {
      text-align: right;
    }

  }
}

.SubscriptsTab{
  &LicenseCard{
    margin-top: 48px;
  }
  &UpdateButton{
    margin-top: 29px;
    & > span {
      color: $lightning2;
    }
  }
  &ActivateButton {
    margin-top: 29px;
  }
}

.accountSetupModal {
  width: 840px;
  max-width: none !important;
  min-width: 840px !important;
  padding: 0 !important;
  background-color: $steel-gray !important;
  border-radius: 12px !important;
}
.accountTeamDeleteModal {
  font-size: 17px;
  text-align: center;
  padding: 10px 20px;
  max-width: 300px;
  &Buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 24px;
    & :first-child {
      text-align: left;
    }
    & :last-child {
      text-align: right;
    }
  }
}

.notificationsSection{
  display: grid;
  grid-template-columns: 1fr;
  margin: 0;
  padding: 0;
  border: none;
  max-width: 800px;
  font-size: 17px;
  line-height: 160%;
  margin-bottom: 36px;
  &Header{
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 100px 100px;
    border-bottom: 1px solid $trout;
    font-size: 14px;
    text-align: center;
    padding-bottom: 6px;
    & :first-child{
      text-align: left;
      text-transform: uppercase;
    }
  }
  &Options{
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 24px 0;
      display: grid;
      grid-template-columns: 1fr 100px 100px;
      text-align: center;
      & :first-child{
        text-align: left;
      }
    }
  }
}

.renewLicenseBtn {
  margin-top: 1.5rem;
}

span.expiredLicense {
  color: $punch;
}

p.expiredLicense {
  font-size: 24px;
}
