@import "../styles/variables";

.chartCardRow {
  display: flex;
  justify-content: space-between;

  >div {
    width: 33%;
    height: 320px;

    &:not(:last-of-type) {
      margin-right: 1.25rem;
    }
  }
}

.chartCardRowHalfs {
  display: flex;
  justify-content: space-between;

  >div {
    width: 50%;
    height: 320px;

    &:not(:last-of-type) {
      margin-right: 1.25rem;
    }
  }
}

.dashboardCard {
  background: $tuna;
  border-radius: 6px;
  padding: 0.75rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;

  .cardTitle {
    @extend %chakra-petch-h4;

    color: white;
    margin-bottom: 4px;
  }

  .cardSubtitle {
    color: $spun-pearl;
    font-size: 13px;
    margin-bottom: 20px;
  }

  .chartContainer {
    flex-grow: 1;
    min-height: 0px;
    height: 100%;
  }

  .cardSubtitleWithBottomBorder {
    color: #ffffff;
    font-size: 13px;
    margin-bottom: 20px;
    border-bottom: 1px solid $trout;
    padding-bottom: 10px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 15px;
  }
}