@import "../../../styles/variables";

.createGamePlanContainer {
  display: flex;
  flex-direction: column;

  .subText {
    font-size: 12px;
    line-height: 100%;
    margin-top: 12px;
    color: $create-gamePlan-subtext;
  }

  .playsSelected {
    margin-bottom: 20px;
  }

  .checkBoxes {
    margin-top: 12px;
  }

  .datePickerCtn {
    width: 230px;
    margin-top: 18px;
  }

  .btnContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 60px;

    .createBtn {
      width: fit-content;
      margin-left: 15px;
    }
  }
}