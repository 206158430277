@import "../../../styles/variables";

.teamDetails {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &_LeftSide,
  &_RightSide {
    display: flex;
    flex-direction: column;
  }

  &_LeftSide {
    width: 146px;
    min-width: 146px;
  }

  &_RightSide {
    flex: 1;
    margin-left: 40px;
  }

  &_RowSmall {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &_RowMedium {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &_FlexRow {
    display: flex;
    align-items: center;

    &_Columns {
      flex-direction: column;
    }
  }

  &_Label {
    @extend %caption;

    margin-bottom: 10px;
    color: $jumbo;
    text-transform: uppercase;
  }

  &_LogoButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 146px;
    color: $spun-pearl;
    fill: $spun-pearl;
    background: transparent;
    border: 1px dashed $spun-pearl;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }

    span {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &Image {
      width: 90%;
      max-height: 80px;
      object-fit: cover;
      object-position: center;
    }

    &CloseIcon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &_FileUpload {
    &Input {
      display: none;
    }
  }

  &_City,
  &_State,
  &_Zip {
    display: flex;
  }

  &_City {
    flex: 1;
    margin-right: 10px;
  }

  &_State {
    width: 150px;
    margin-right: 10px;
  }

  &_Zip {
    width: 123px;
  }

  &_ColorPicker {
    flex: 1;
    margin-right: 16px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
