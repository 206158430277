@import "../../styles/variables";

.switchContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.15s ease-out;

  .switch {
    position: absolute;
    transition: all 0.15s ease-out;
  }

  // jade theme
  &.jade {
    max-width: 30px;
    min-width: 30px;
    height: 16px;
    border-radius: 8px;
    border: 2px solid $jumbo;

    .switch {
      left: 1px;
      height: 10px;
      width: 10px;
      border-radius: 5px;
      background: $jumbo;
    }

    &.toggled {
      border-color: $jade;

      .switch {
        left: 15px;
        background: $jade;
      }
    }
  }

  // yellow theme
  &.yellow {
    width: 24px;
    height: 10px;
    border-radius: 39px;
    background-color: $jumbo;

    .switch {
      width: 16px;
      height: 16px;
      left: -8px;
      background-color: $white;
      border: 1px solid $athens-grey;
      border-radius: 50%;
      box-shadow: 0 2px 6px rgba(68, 80, 86, 0.3);
    }

    &.large {
      width: 48px;
      height: 28px;
      border-radius: 39px;

      .switch {
        width: 24px;
        height: 24px;
        left: 2px;
      }
    }

    &.toggled {
      background: linear-gradient(140.67deg, $lightning1 12.93%, $lightning2 77.48%);

      .switch {
        left: calc(100% - 8px);
        border: 1px solid $bright-gray;
      }

      &.large {
        .switch {
          left: calc(100% - 26px);
        }
      }
    }

    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}
