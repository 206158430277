@import "../../styles/variables";

.toggleSelect {
  display: flex;
  border: 1px solid var(--ion-color-tertiary);
  border-radius: 6px;

  .option {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    padding: 6px 0;
    text-transform: uppercase;
    cursor: pointer;
    color: $spun-pearl;
    font-size: 12px;

    &.selected {
      background: var(--ion-color-tertiary);
      color: $mirage;
    }

    &.isLeft {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &.isRight {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}