@import "../../styles/variables";

.tabs {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: var(--tabsBackground);

  .tab {
    flex: 1;
    position: relative;
    padding: 0.8rem 1rem;
    margin: 0 1rem;
    background-color: transparent;
    border: none;
    font-family: $quantico;
    font-weight: var(--tabWeight);
    text-transform: capitalize;
    outline: none;
    color: var(--tabColor);
    cursor: pointer;
  }

  &.primary {
    --tabColor: #{$nav-text};
    --tabWeight: normal;
    --tabsBackground: var(--ion-color-secondary);

    .tab.active {
      --tabColor: var(--ion-color-tertiary);
      --tabWeight: bold;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 4px;
        background-color: var(--ion-color-tertiary);
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
      }
    }
  }

  &.secondary {
    // Add in styling when needed
  }
}
