@import "../styles/variables";

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  border-radius: 25%;
  border: 1px solid $steel-gray;
}

::-webkit-scrollbar-thumb {
  background: $thumbnail-border;
  border-radius: 6px;
  border: 3px solid $thumbnail-border;
}

::-webkit-scrollbar-track, ::-webkit-scrollbar-corner {
  background: var(--ion-color-secondary);
}
