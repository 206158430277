@import "../../styles/variables";

.toastList {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 20;
}

.toast {
  z-index: 6;
  width: 375px;
  height: 42px;
  padding: 0.7rem 0.5rem 0 0.6rem;
  margin-top: 1rem;
  background: $toast-bg;
  border-radius: 6px;
  border-left: 6px solid;
  color: $dark-text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  animation: fadeIn 0.3s, fadeOut 0.3s 3.6s forwards; // Fade out just before 4s when the toast gets removed from DOM

  &.success {
    border-color: $success-toast;
  }

  &.error {
    border-color: $error-toast;
  }

  &.info {
    border-color: $info-toast;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 100%; }
}

@keyframes fadeOut {
  0% { opacity: 100%; }
  100% { opacity: 0; }
}
