// font-family: 'Quantico', sans-serif;
// font-family: 'Chakra Petch', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@1,700&family=Quantico:wght@400;700&display=swap');
$quantico: 'Quantico', sans-serif;
$chakra-petch: 'Chakra Petch', sans-serif;

// Typography
%quantico {
    font-family: $quantico;
    letter-spacing: 0.02em;
}

%body {
    font-family: $quantico;
    font-size: 17px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0.02em;
}

%body-strong {
    font-family: $quantico;
    font-size: 17px;
    line-height: 27px;
    font-weight: 700;
    letter-spacing: 0.02em;
}

%small {
    font-family: $quantico;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    letter-spacing: 0.02em;
}

%small-strong {
    font-family: $quantico;
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 0.02em;
}

%caption {
    font-family: $quantico;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.02em;
}

%micro {
    font-family: $quantico;
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
    letter-spacing: 0.02em;
}

%micro-strong {
    font-family: $quantico;
    font-size: 11px;
    line-height: 15px;
    font-weight: 700;
    letter-spacing: 0.02em;
}

%chakra-petch-italic {
    font-family: $chakra-petch;
    font-style: italic;
}

%chakra-petch-italic-uppercase {
    font-family: $chakra-petch;
    font-style: italic;
    text-transform: uppercase;
}

%chakra-petch-bold-italic {
    font-family: $chakra-petch;
    font-weight: 700;
    font-style: italic;
}

%chakra-petch-bold-italic-uppercase {
    font-family: $chakra-petch;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

%chakra-petch-h1 {
    font-family: $chakra-petch;
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

%chakra-petch-h2 {
    font-family: $chakra-petch;
    font-size: 36px;
    line-height: 43px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

%chakra-petch-h3 {
    font-family: $chakra-petch;
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

%chakra-petch-h4 {
    font-family: $chakra-petch;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    font-style: italic;
    text-transform: uppercase;
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
    --ion-color-primary: #ffffff;
    --ion-color-primary-rgb: 255,255,255;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #e0e0e0;
    --ion-color-primary-tint: #ffffff;

    --ion-text-color: #ffffff;

    --ion-color-secondary: #282B3A;
    --ion-color-secondary-rgb: 40,43,58;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #151724;
    --ion-color-secondary-tint: #3e404e;

    --ion-color-tertiary: #ffe42a;
    --ion-color-tertiary-rgb: 255,228,42;
    --ion-color-tertiary-contrast: #000000;
    --ion-color-tertiary-contrast-rgb: 0,0,0;
    --ion-color-tertiary-shade: #e0c624;
    --ion-color-tertiary-tint: #ffe43e;

    --ion-color-success: #03a863;
    --ion-color-success-rgb: 3,168,99;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255,255,255;
    --ion-color-success-shade: #039457;
    --ion-color-success-tint: #1cb173;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255,196,9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb5953;
    --ion-color-danger-rgb: 235,89,83;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 0,0,0;
    --ion-color-danger-shade: #cf4e49;
    --ion-color-danger-tint: #ed6a64;

    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34,36,40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255,255,255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    --ion-color-medium: #7e7e85;
    --ion-color-medium-rgb: 126,126,133;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255,255,255;
    --ion-color-medium-shade: #6f6f75;
    --ion-color-medium-tint: #8b8b91;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244,245,248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0,0,0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-background-color: #282b3a;

    --input-background-dark: #151724;
}

// Colors
$mirage: #151724;
$tuna: #303241;
$bright-gray: #353849;
$abby: #424258;
$trout: #484A59;
$spun-pearl: #ababb7;
$athens-grey: #e7e7ed;
$whisper: #f2f2f8;


$burnt-sienna: #eb5953;
$lightning1: #ffbc17;
$lightning1-light: rgba(255, 188, 23, 0.25);
$lightning2-light: rgba(255, 234, 45, 0.35);
$lightning2: #ffea2d;
$candlelight: #FFD523;
$cerulean: #009ee1;
$dodger-blue: #00b2ff;
$malibu: #7ad7ff;
$jade: #00a863;
$steel-gray: #282B3A;
$jumbo: #7E7E85;
$lime: #6DCC30;
$vivid-violet: #A13DB6;
$shamrock: #2cda92;
$cruise: #B9E3D2;
$light-red: #FAAFAF;
$java: #23aac1;
$dark-red: #A10202;
$bubblegum: #35d3ed;
$punch: #da3731;

$lightning-yellow: linear-gradient(
  170deg,
  $lightning1 12.93%,
  $lightning2 77.48%
);

// Semantic colors
$nav-text: $spun-pearl;
$disabled-text: $spun-pearl;
$add-link-text: $spun-pearl;
$login-error-text: $spun-pearl;
$delete-modal-text: $spun-pearl;
$dark-text: $mirage;
$link-text: $dodger-blue;
$toggle-label-text: $spun-pearl;
$toggle-button-bg: $mirage;
$toggle-button-selected-text: $mirage;

$border: $bright-gray;
$focus-border: $malibu;
$thumbnail-border: $abby;
$icon-border-color: $abby;
$avatar-file-upload-border: $abby;
$tertiary-button-border: $spun-pearl;
$new-formation-button-border: $trout;

$select-arrow: $spun-pearl;
$select-focus-bg: $abby;

$card-bg: $tuna;
$signup-bg: $bright-gray;
$play-card-bg: $bright-gray;
$roster-bg: $bright-gray;
$roster-bg-hover: $trout;
$play-settings-bg: $bright-gray;
$input-bg: $mirage;
$dark-bg: $mirage;
$modal-overlay-bg: linear-gradient(
  0deg,
  rgba(64, 63, 76, 0.9),
  rgba(64, 63, 76, 0.9)
);
$modal-bg: $tuna;
$toast-bg: $whisper;
$play-editor-bg: $whisper;
$input-disabled-bg: $trout;

$logo-file-upload-bg: $tuna;

$success-toast: $jade;
$error-toast: $burnt-sienna;
$info-toast: $dodger-blue;

$loader-light: $abby;

$selected-text-color: $whisper;
$selected-bg: $bright-gray;
$filter-option-bg: $cerulean;

$dark-player-detail-text: $mirage;
$rank-text: $bright-gray;
$player-modal-background: $athens-grey;
$ranking-chart-bg: $lightning-yellow;
$header-position-text: $spun-pearl;
$readiness-bg: $trout;
$add-players-text: $spun-pearl;
$draft-mode-button-text: $jumbo;
$draft-mode-text: $spun-pearl;
$draft-mode-hover: $lightning1-light radial-gradient(circle, transparent 0%, $lightning1-light 1%) center/15000%;
$draft-mode-selected: $lightning2-light;
$draft-mode-bg: $steel-gray;

$gamePlan-select-btn: $cerulean;
$plays-selected-text: $mirage;
$add-to-gamePlan-btn-bg: $steel-gray;
$create-gamePlan-subtext: $spun-pearl;
$license-suspended-subtext: $spun-pearl;

// Loading animation
@keyframes cycle {
  0% {
    background-position: 0 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

// Grid Breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
);

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
    $min: map-get($breakpoints, $name);
    @return if($min != 0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
    $max: map-get($breakpoints, $name);
    @return if($max and $max > 0, $max - .02, null);
}

@mixin media-min-width($name, $breakpoints: $grid-breakpoints) {
    $min: breakpoint-min($name, $breakpoints);
    @if $min {
        @media (min-width: $min) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-max-width($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-width: $max) {
            @content;
        }
    } @else {
        @content;
    }
}
