@import "../../styles/variables";

.wysiwyg {
  position: relative;
  width: 100%;
  word-break: break-word;

  &Toolbar {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-top: 10px;
    padding: 6px;
    background-color: $tuna;
    border-radius: 4px;

    & > div {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 15px;

      &::after {
        position: absolute;
        width: 1px;
        height: 22px;
        right: -7px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $abby;
        content: "";
      }

      &:last-of-type {
        margin-right: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 2px;
    padding: 0;
    background-color: transparent;
    border: none;
    border-radius: 3px;
    transition: background-color 0.3s ease-in-out;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;
      background-color: $abby;
    }

    svg {
      fill: $spun-pearl;
    }
  }

  &ButtonActive {
    background-color: $abby;
  }

  &FontColorButtonWrapper {
    position: relative;
  }

  &FontColorButton,
  &FontColorButtonWhite {
    svg {
      path {
        &:first-of-type {
          fill: $white;
        }
      }
    }
  }

  &FontColorButtonGrey {
    svg {
      path {
        &:first-of-type {
          fill: $silver-chalice;
        }
      }
    }
  }

  &FontColorButtonRed {
    svg {
      path {
        &:first-of-type {
          fill: $persimmon;
        }
      }
    }
  }

  &FontColorButtonOrange {
    svg {
      path {
        &:first-of-type {
          fill: $flush-orange;
        }
      }
    }
  }

  &FontColorButtonYellow {
    svg {
      path {
        &:first-of-type {
          fill: $bright-sun;
        }
      }
    }
  }

  &FontColorButtonReef {
    svg {
      path {
        &:first-of-type {
          fill: $reef;
        }
      }
    }
  }

  &FontColorButtonGreen {
    svg {
      path {
        &:first-of-type {
          fill: $lime;
        }
      }
    }
  }

  &FontColorButtonJava {
    svg {
      path {
        &:first-of-type {
          fill: $java;
        }
      }
    }
  }

  &FontColorButtonBlue {
    svg {
      path {
        &:first-of-type {
          fill: $dodger-blue-2;
        }
      }
    }
  }

  &FontColorButtonRose {
    svg {
      path {
        &:first-of-type {
          fill: $razzle-dazzle-rose;
        }
      }
    }
  }

  &FontColorButtonPalette {
    position: absolute;
    display: flex;
    align-items: center;
    left: 50%;
    top: calc(100% + 9px);
    transform: translateX(-50%);
    padding: 10px;
    background-color: $mirage;
    border-radius: 6px;
    z-index: 1;
  }

  &PaletteButtonWrapper {
    margin-right: 8px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &PaletteButton {
    position: relative;
    display: flex;
    width: 22px;
    height: 22px;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: 2px solid $bright-gray;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
    }
  }

  &PaletteButtonActive {
    &::after {
      position: absolute;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      left: -4px;
      top: -4px;
      border: 2px solid $white;
      border-radius: 6px;
      background-color: transparent;
      content: "";
    }
  }

  &PaletteButtonWhite {
    background-color: $white;
  }

  &PaletteButtonGrey {
    background-color: $silver-chalice;
  }

  &PaletteButtonRed {
    background-color: $persimmon;
  }

  &PaletteButtonOrange {
    background-color: $flush-orange;
  }

  &PaletteButtonYellow {
    background-color: $bright-sun;
  }

  &PaletteButtonReef {
    background-color: $reef;
  }

  &PaletteButtonGreen {
    background-color: $lime;
  }

  &PaletteButtonJava {
    background-color: $java;
  }

  &PaletteButtonBlue {
    background-color: $dodger-blue-2;
  }

  &PaletteButtonRose {
    background-color: $razzle-dazzle-rose;
  }
}
