@import "../../../../styles/variables";

.quizPreview {
  display: flex;
  flex-direction: column;
  height: 320px;
  width: 180px;
  background-color: var(--ion-color-secondary);
  padding-top: 20px;

  &EmptyState {
    @extend %micro;
    color: $spun-pearl;
    margin-top: 110px;
  }

  .previewPhoneFooter {
    @extend %micro;
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $steel-gray;
    color: white;
    padding: 0 8px;

    .Previous {
      color: $abby;
      cursor: default;
      &[class~="Active"]{
        color: white;
        cursor: pointer;
      }
    }

    .Next {
      color: $abby;
      cursor: default;
      &[class~="Active"]{
        color: white;
        cursor: pointer;
      }
    }
  }
}
