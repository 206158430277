.refreshButton {
    background-color: var(--ion-color-secondary);

    &::before {
        background: var(--ion-color-medium);
    }

    img {
        filter: invert(100%) brightness(100%);
    }
}

.chartCardRow {
    >div {
        width: calc(50% - 10px);
        &:not(:last-of-type) {
            margin-right: 20px;
        }
    }
}

.chartContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    >div {
        height: 320px;
        min-width: 30%;
        flex: 1;
        margin: 8px;
    }
    // overall distribution, overall readiness by unit
    >:nth-child(1),>:nth-child(2) { 
        order: -4;
    }
    // plays
    >:nth-child(3),>:nth-child(4) { 
        order: -1;
    }
    // players
    >:nth-child(5), >:nth-child(6) { 
        order: -1; 
    }
    >:nth-child(6){
        max-width: calc(33.33% - 8px);
    }
    // lesons
    >:nth-child(7) { 
        order: -2;
    }

    @media (max-width: 1450px) {
        >div {
            min-width: 40%;
        }
        // overall distribution, overall readiness by unit
        >:nth-child(1),>:nth-child(2) { 
            order: -3;
        }
        // plays
        >:nth-child(3),>:nth-child(4) { 
            order: -1;
        }
        // players
        >:nth-child(5), >:nth-child(6) { 
            order: -1; 
        }
        >:nth-child(6){
            max-width: unset;
        }
        // lesons
        >:nth-child(7) { 
            order: 0;
        }
    }

    @media (max-width: 1024px) {
        >:nth-child(1),>:nth-child(2) { 
            min-width: 60%;
        }
    }

    @media (max-width: 850px) {
        >div {
            min-width: 60%;
        }
        // overall distribution, overall readiness by unit
        >:nth-child(1),>:nth-child(2) { 
            order: -3;
        }
        // plays
        >:nth-child(3),>:nth-child(4) { 
            order: -1;
        }
        // players
        >:nth-child(5), >:nth-child(6) { 
            order: -1; 
        }
        // lesons
        >:nth-child(7) { 
            order: 0;
        }
    }
}
