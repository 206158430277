@import "../../../../styles/variables";
@import "../../../../components/Modal/Modal.module.scss";

.verticalFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.editRosterWrapper {
  @extend .modalWrapper;
  top: 0;
  left: 0;
  z-index: 7;

  .editRoster {
    @extend .modal;
    background-color: $modal-bg;
    grid-template-rows: 55px 1fr;
    width: 75%;
    height: 90%;
    padding: 0;
    overflow: hidden;

    .modalHeader {
      display: flex;
      justify-content: space-between;
      padding: 25px 30px;
      margin: 0;
    }

    .closeIcon {
      height: 90px;
      padding: 0.2rem;
      cursor: pointer;
    }

    .editRosterBody {
      @extend .verticalFlex;
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 45px 1fr;

      .rosterTab {
        display: grid;
        background: $steel-gray;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 77px;
        @extend .verticalFlex;

        .rosterListWrapper {
          background-color: $roster-bg;
          overflow-y: scroll;

          .rosterList {
            height: 1px; // layout hack
            padding: 0;
            margin: 0;
            list-style-type: none;

            .rosterItem {
              display: grid;
              grid-template-columns: 45px 1fr 1fr 100px 40px;
              padding: 0 1rem;
              height: 70px;
              border-bottom: 1px solid var(--ion-color-secondary);

              span,
              button {
                display: flex;
                align-items: center;
              }

              span:last-child {
                justify-content: flex-end;
              }
            }
          }
        }

        .rosterTabFooter {
          display: flex;
          justify-content: flex-end;
          padding: 1rem;
          background: var(--ion-color-secondary);

          button {
            flex: 0;
          }

          span:first-child {
            margin-right: 1rem;
          }
        }
      }
    }
  }

  &.open {
    .editRoster {
      display: flex;
      flex-direction: column;
    }
  }
}

.editRosterOverlay {
  @extend .modalOverlay;
  top: 0;
  left: 0;
  z-index: 6;
}


.DeleteModal {
  font-size: 17px;
  color: $delete-modal-text;

  .capitalize {
    text-transform: capitalize;
  }

  .buttonSpacing {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20%;

    button {
      width: 32%;
      margin: 5px;
      cursor: pointer;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.inviteText {
  display: flex;
  max-width: 700px;
  margin: 0px 20px 40px;
}

.codeContainer {
  @extend %chakra-petch-h1;

  margin-bottom: 32px;
}

.codeActivated {
  background: rgba(0, 230, 136, 0.2);
  border: 2px solid #2CDA92;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 10px;
}

.codeDeactivated {
  background: rgba(230, 0, 0, 0.2);
  border: 2px solid #FF8883;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 10px;
}

.expiryDateSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.expiryDateCheckBox {
  width: 100%;
}

.playerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatarContainer {
  margin-right: 32px;
}

.playerName {
  @extend %chakra-petch-h3;
}

.playerInfoWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.contentWrapper {
  padding: 24px;
  background: $steel-gray;
  overflow-y: hidden;
  height: 65vh;
}

.contentWrapper:hover {
  overflow-y: auto;
}
