@import "../../../styles/variables";
@import "../../../styles/_variables";

.fieldOptions {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  background: $play-settings-bg;
  border-top: 1px solid var(--ion-color-secondary);
  padding: 18px 24px 18px 20px;

  .col {
    display: flex;
    flex-direction: column;

    &:first-of-type {
      flex-basis: 236px;
    }

    &:last-of-type {
      flex-grow: 1;
    }
  }

  .label {
    text-transform: uppercase;
    color: $toggle-label-text;
    margin-bottom: 8px;
  }

  .toggleButton {
    color: var(--ion-color-primary);
    background: $toggle-button-bg;
    height: 34px;
    width: 101px;
    font-size: 14px;
    outline: none;
    border: none;
    font-family: $quantico;

    &.selected {
      background: var(--ion-color-tertiary);
      color: $toggle-button-selected-text;
    }

    &:first-of-type {
      border-top-left-radius: 17px;
      border-bottom-left-radius: 17px;
    }

    &:last-of-type {
      border-top-right-radius: 17px;
      border-bottom-right-radius: 17px;
    }
  }
}
