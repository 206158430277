@import "../../styles/variables";

.label {
  --listClip: 0;
  --listOpacity: 0;
  --listPadding: 0;
  --pointerEvents: none;
  --speed: 0.2s;

  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;

  .selectLabel {
    text-transform: uppercase;
    font-size: 13px;
    color: $spun-pearl;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
  }

  &.alwaysOpen {
    display: block;

    &::after {
      content: none;
    }
  }

  .selectedIcon {
    position: absolute;
    padding-left: 10px;
  }

  .valueIcon {
    padding-left: 20px;
  }

  .selectedValue {
    @extend %quantico;

    position: absolute;
    left: 1rem;
    width: calc(100% - 60px);
    outline: none;
    background: transparent;
    border: none;
    font-size: var(--selectFontSize);
    color: var(--ion-color-primary);
    pointer-events: none;
    text-overflow: ellipsis;

    &::placeholder {
      color: var(--placeholderText);
      transition: color 0.15s;
    }
  }

  input.hide {
    display: none;
  }

  .select {
    width: 100%;
    height: var(--selectHeight);
    border: none;
    border-radius: 6px;
    outline: none;
    background: var(--selectBg);
    color: var(--selectText);
    transition: background 0.15s;

    ::-webkit-scrollbar {
      display: none;
    }

    &.hide {
      height: inherit;
      padding: 0;
    }

    .list {
      &.alwaysOpen {
        position: relative;
      }

      position: absolute;
      padding: var(--listPadding);
      margin: 0;
      left: 0;
      top: calc(100% + 5px);
      width: 100%;
      max-height: var(--listClip);
      overflow: scroll;
      list-style-type: none;
      opacity: var(--listOpacity);
      pointer-events: var(--pointerEvents);
      background: $input-bg;
      border-radius: 4px;
      box-sizing: border-box;
      font-size: var(--selectFontSize);
      color: var(--ion-color-medium);
      z-index: 2;
      transition: opacity var(--speed), max-height var(--speed), padding var(--speed);
      transition-timing-function: cubic-bezier(.50, 0, 0, 1);

      &:hover {
        .option.selected:not(:hover) {
          color: var(--ion-color-medium);
          background: transparent;
          transition-delay: 0.05s;
        }
      }

      .option {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        cursor: pointer;
        height: 34px;
        background: transparent;
        transition: background 0.15s, color 0.15s;

        &:focus,
        &.selected {
          &:not(.disabled) {
            color: $dark-text !important;
            background: var(--ion-color-tertiary) !important;
          }
        }

        &:hover,
        &.hover {
          &:not(.disabled) {
            color: $dark-text;
            background: var(--ion-color-medium);
          }
        }

        &.disabled {
          color: $abby;
          cursor: not-allowed;
        }

        .icon {
          padding-right: 8px;
        }
      }
    }

    .placeholder {
      color: var(--placeholderText);
    }
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 1rem;
    width: 1px;
    border-top: 5px solid $select-arrow;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  &.dark {
    --selectBg: #{$input-bg};
    --selectText: var(--ion-color-primary);
    --placeholderText: var(--ion-color-medium);
  }

  &.listOpen {
    --listClip: 200px;
    --listOpacity: 1;
    --listPadding: 0.5rem 0;
    --selectBg: #{$select-focus-bg};
    --selectText: var(--ion-color-primary);
    --placeholderText: var(--ion-color-primary);
    --pointerEvents: auto;
    --speed: 0.5s;
  }

  .showScrollbar {
    ::-webkit-scrollbar {
      display: inherit;
    }
  }

  //  Sizes
  &.large {
    --selectHeight: 56px;
    --selectFontSize: 17px;
  }

  &.medium {
    --selectHeight: 44px;
    --selectFontSize: 14px;
  }

  &.small {
    --selectHeight: 36px;
    --selectFontSize: 14px;
  }

  &.x-small {
    --selectHeight: 30px;
    --selectFontSize: 12px;
  }
}
