@import "../../../../styles/variables";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 625px;
  
  .title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
  }

  .whitePaper {
    margin-top: 30px;
    width: 268px;
    height: 375px;
    position: relative;
    background: rgba(243, 243, 243, 1);
    color: black;
    display: flex;
    flex-direction: column;
    padding: 48px 32px;
    .imgBlock {
      display: flex;
      justify-content: center;
      .logo {
        width: 40px;
        height: 40px;
      }
    }
    .teamName {
      color: black;
    }

    .coachBlock {
      display: flex;
      flex-direction: column;
      .coachDescription {
        text-transform: uppercase;
        color: $spun-pearl;
        font-size: 11px;
      }
      .coachName {
        font-size: 18px;
      }
    }

    .signatureBlock {
      margin-top: 30px;
      display: grid;
      grid-template-columns: 70% 30%;

      .signature {
        hr {
          margin: 20px auto;
          background-color: $spun-pearl;
          opacity: 0.7;
          height: 0.5px;
          border: none;
        }
        .signatureLine {
          display: flex;
          justify-content: space-between;
          position: relative;
          hr {
            width: 40%
          }
          .signatureFont {
            @extend %meie-script;
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 16px;
          }
        }
      }
      .ribbonBlock {
        margin-left: 10px;
        img {
          width: 65px;
        }
      }
    }
  }
  .whitePaper,
  .whitePaper::before,
  .whitePaper::after {
  /* Styles to distinguish sheets from one another */
  box-shadow: 1px 1px 1px rgba(0,0,0,0.25);
  border: 1px solid #bbb;
  }

  .whitePaper::before,
  .whitePaper::after {
    content: "";
    position: absolute;
    height: 95%;
    width: 99%;
    background-color: rgba(255, 255, 255, 0.5);
  }

  .whitePaper::before {
    left: 5px;
    top: 0;
    transform: rotate(5deg);
    z-index: -1;
    opacity: 0.5;
  }

  .whitePaper::after {
    top: 5px;
    right: -5px;
    transform: rotate(1deg);
    z-index: -2;
    opacity: 0.2;
  }

  .description {
    color: $spun-pearl;
    margin-top: 5px;
  }

  .actionButtons {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-top: 24px;

    .icon {
      margin-bottom: 12px;
      opacity: 0.3;
    }

    .actionButton {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 36px;
    }
  }

}