@import "../../shared/shared-with-mobile/styles/variables.scss";

.dynamicTimerIcon {
  position: relative;

  .textOverlay {
    position: absolute;
    top: 65px;
    left: 25px;
    height: 40px;
    width: 98px;
    background: var(--ion-color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    @extend %chakra-petch-h2;
    color: var(--ion-color-tertiary);

    &.redText {
      color: $punch;
    }
  }
}