@import "../shared/shared-with-mobile/styles/variables.scss";
@import "./variables";

body {
  @extend %small;

  margin: 0;
  height: 100vh;
  background: var(--ion-color-secondary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

div {
  box-sizing: border-box;
}

a {
  color: var(--ion-color-tertiary);
  text-decoration: none;
}

hr {
  border: none;
  border-top: 1px solid $border;
}

form {
  position: relative;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 1rem;
  }

  .formFooter {
    display: flex;
    justify-content: flex-end;

    button {
      width: inherit;
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 84px;

  &Slim {
    height: 70px;
  }

  &Link,
  .link {
    color: $add-link-text;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 12px;
    transition: color 0.3s;

    &:hover,
    &:focus {
      color: var(--ion-color-primary);
    }
  }
}

.pageTitle {
  @extend %chakra-petch-h3;

  margin: 0;
}

.card {
  display: block;
  padding: 1rem;
  background-color: $card-bg;
  border-radius: 6px;

  .cardTitle {
    margin: 0;
    size: 18px;
  }
}

.tableHeader {
  display: grid;
  padding: 1rem;
  font-size: 10px;
  text-transform: uppercase;
  color: $nav-text;
}

// Material UI datepicker dialog footer button overrides
div[class~="MuiPickersModal-dialogRoot"] {
  div[class~="MuiDialogActions-root"] {
    button {
      text-transform: none;
      padding: calc(0.57rem - 1px) calc(1rem - 1px);
      background: $card-bg;
      border-radius: 5px;
      border: 1px solid transparent;
      background-clip: padding-box;
      color: var(--ion-color-primary);
      font-size: 14px;
    }

    button:first-child {
      z-index: 1;
      padding: 0;

      span:first-child {
        position: relative;
        padding: calc(0.47rem - 1px) calc(1rem - 1px);
        background: $card-bg;
        border-radius: 5px;
        border: 1px solid transparent;
        background-clip: padding-box;
        color: var(--ion-color-primary);
      }

      span:last-child {
        content: '';
        position: absolute;
        display: block;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-radius: inherit;
        background: $lightning-yellow;
        z-index: -1;
      }
    }

    button:not(:first-child) {
      background: $lightning-yellow;
      background-clip: padding-box;
      color: black;

      &:focus {
        border: 2px solid $focus-border;
      }
    }
  }
}

// Phone Input
.phoneInput {
  width: 100% !important;

  &--heightNormal {
    height: 44px !important;
  }
}

// Phone Input Black Theme
.phoneInput, .phoneInputButton, .phoneInputDropdown {
  border-color: var(--ion-color-secondary-shade) !important;
  background-color: var(--ion-color-secondary-shade) !important;
  color: var(--ion-color-secondary-contrast) !important;
}

.phoneInput:disabled {
  color: var(--ion-color-medium) !important;
}

.phoneInputButton {
  &.open {
    border-color: var(--ion-color-secondary-shade) !important;
    background-color: var(--ion-color-secondary-shade) !important;
    .selected-flag {
      border-color: var(--ion-color-secondary-shade) !important;
      background-color: var(--ion-color-secondary-shade) !important;
    }
  }
  .selected-flag:hover,.selected-flag:focus {
    background-color: var(--ion-color-secondary-shade) !important;
  }
}

.phoneInputDropdown .preferred.active.country.highlight, .phoneInputDropdown .highlight {
  background-color: var(--ion-color-medium) !important;
}

.phoneInputDropdown .country:hover, .phoneInputDropdown .highlight:hover {
  background-color: var(--ion-color-secondary-tint) !important;
}
