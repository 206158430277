@import "../../styles/variables";

.container {
  pointer-events: none;
  background-color: $trout;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  img {
    max-width: 78.25%;
    width: 100%;
    max-height: 70%;
  }
}