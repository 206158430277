.phonePreviewContainer {
  width: fit-content;
  height: auto;
  align-self: center;
  text-align: center;
  zoom: 100%;
}

.phonePreview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 8px;
  height: 412px;
  width: 203.2px;
  background-color: black;
  border-radius: 12px;
  text-align: center;
}
