@import "../../styles/variables";

$avatar-image-size: 60px;
$logo-image-size: 140px;

.label {
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;

  &.avatar {
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;

    .icon {
      border: 1.5px solid $avatar-file-upload-border;
      padding: 1rem;
      border-radius: 50%;
      width: 25px;
      position: relative;
    }

    .uploadedImage {
      width: $avatar-image-size;
      height: $avatar-image-size;
      border-radius: 50%;
      
    }

    .helperText {
      position: relative;
      font-size: 11px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: var(--ion-color-tertiary);
    }

    .loader {
      width: $avatar-image-size;
      height: $avatar-image-size;
      border-radius: 50%;
      border: 1px solid $avatar-file-upload-border;
      z-index: 1;
    }
  }

  &.logo {
    width: $logo-image-size;
    height: $logo-image-size;
    border: none;
    background: $logo-file-upload-bg;
    border-radius: 6px;

    .icon {
      width: 15px;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(67deg)
        brightness(101%) contrast(104%);
    }

    .uploadedImage {
      width: $logo-image-size;
      height: $logo-image-size;
    }

    .helperText {
      position: relative;
      top: 8px;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: 0.02em;

      &.imageUploaded {
        bottom: 15px;
        left: 120px;
        top: -6px;
      }
    }

    .loader {
      width: $logo-image-size;
      height: $logo-image-size;
      border-radius: 6px;
      border: 1px solid $avatar-file-upload-border;
      z-index: 1;
    }
  }

  .icon {
    max-height: 30px;
  }

  .uploadedImage {
    position: absolute;
  }

  .input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  .loader {
    position: absolute;
    background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
    background-size: 300% 300%;
    animation: cycle 1s ease infinite;
  }
}
