@import "../../../../styles/variables";

.quizSidebar {
  background: $tuna;
  width: 240px;
  min-width: 240px;
  border-radius: 6px;
  padding: 20px 0px;
  margin-right: 24px;
  height: fit-content;

  label > input {
    background: $steel-gray !important;
  }

  label {
    margin-bottom: 10px;
  }

  .sidebarTitle {
    display: flex;
    justify-content: space-between;
    color: $spun-pearl;
    text-transform: uppercase;
    padding: 0px 10px 30px;

    .sidebarIcons {
      display: flex;
      justify-content: space-evenly;
    }

   button {
    padding: 8px;
    margin-top: -8px;

    &:first-child {
      margin-right: 4px;
    }
  }
    button > img,
    button > span {
      vertical-align: middle;
    }

    img {
      cursor: pointer;
            &:first-of-type {
            width: 18px;
          }
    }
  }

  .searchBarContainer {
    padding: 0px 10px;
  }

  .quizRow {
    display: flex;
    align-items: center;
    padding-left: 10px;
    transition: all 0.15s ease-out;

    &.selected {
      background: $abby;

      .quizName {
        color: $athens-grey;
        font-weight: bolder;
      }
    }

    .quizName {
      cursor: pointer;
      color: $spun-pearl;
      transition: all 0.15s ease-out;
      padding: 10px;
      width: 100%;
    }
  }

  .accordion {
    display: flex;
    padding: 16px;
    text-transform: uppercase;
    color: $spun-pearl;
    cursor: pointer;
    border-bottom: 1px solid $bright-gray;
    transition: all 0.25s ease;
    user-select: none;

    &.white {
      color: white;
    }

    img {
      width: 10px;
      margin-right: 16px;
      transition: all 0.25s ease;

      &.rotated {
        transform: rotate(90deg);
      }
    }
  }
}

.quizImportModalSize {
  min-width: 70% !important;
  max-width: 85% !important;
  background: $steel-gray!important;
  padding: 0!important;
}
