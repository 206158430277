@import "../../../styles/variables";

.questionCard {
  position: relative;
  width: 100%;
  max-height: 650px;
  text-align: left;
  border: 1px solid $abby;
  border-radius: 6px;
  background-color: $bright-gray;
  overflow-y: auto;

  &Header,
  &Body {
    padding: 16px;
  }

  &Header {
    box-shadow: inset 0px -1px 0px $trout;
  }

  &NormalSpacingWrapper {
    margin-bottom: 16px;
  }

  &SmallSpacingWrapper {
    margin-bottom: 8px;
  }

  &Title {
    font-size: 11px;
    line-height: 1.4;
    color: $athens-grey;
    text-transform: uppercase;
  }

  &AddMediaButton {
    display: flex;
    align-items: center;
    padding: 6px 8px 6px 0;
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  &AddMediaButtonIcon,
  &AnswerMediaLineCardIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &AddMediaButtonText {
    margin-left: 8px;
    font-size: 14px;
    line-height: 1;
    color: $spun-pearl;
  }

  &TitleSwitchWrapper,
  &SwitchWrapper,
  &AnswerLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &SwitchText {
    margin-left: 16px;
    font-size: 14px;
    color: $spun-pearl;
  }

  &AnswerLineIconCorrect {
    svg {
      fill: $jade;
    }
  }

  &AnswerLineIconIncorrect {
    svg {
      fill: $burnt-sienna;
    }
  }

  &AnswerInputWrapper {
    flex: 1;
    margin-left: 16px;
    margin-right: 8px;
  }

  &AnswerButton,
  &MainImageCloseButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    border-radius: 4px;

    &:hover {
      background-color: $trout;
      cursor: pointer;
    }

    svg {
      fill: $spun-pearl;
    }
  }

  &AnswerButton {
    width: 44px;
    height: 44px;
  }

  &MainImageWrapper {
    position: relative;
    width: 240px;
    height: 180px;
    background-color: $tuna;
    border-radius: 6px;
  }

  &MainImageCloseButton {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 4px;
    right: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &MediaAnswers {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &MediaAnswersWrapper {
    position: relative;
  }

  &MediaAnswersCard {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: $tuna;
    border-radius: 6px;
  }

  &MediaAnswersLineWrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
  }

  &MediaAnswersCardIconWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 55px;
    margin-right: 32px;
  }

  &MediaAnswersCardIconText {
    margin-top: 8px;
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
  }

  &Dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 160px;
    background-color: transparent;
    border: 1px dashed $jumbo;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &DropzoneContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &DropzoneIcon {
    svg {
      fill: $spun-pearl;
    }
  }

  &DropzoneText {
    margin-top: 8px;
    font-size: 12px;
    color: $spun-pearl;
  }

  &DropzoneTextYellow {
    color: $sunglow;
  }

  &MediaThumbnailWrapper {
    position: relative;
    margin-right: 20px;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  &MediaThumbnailButtons {
    position: absolute;
    top: 10px;
    right: 26px;
    display: flex;
    align-items: center;

    button {
      margin-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &MediaThumbnailButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background-color: $athens-grey;
    border: none;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }

    svg {
      fill: $spun-pearl;
    }
  }

  &MediaThumbnail {
    position: relative;
    width: 250px;
    height: 160px;
    border: 1px solid $jumbo;
    border-radius: 6px;
    overflow: hidden;

    &:hover {
      cursor: initial;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 0;
    }
  }

  &MediaThumbnailMargin {
    margin-top: 20px;
  }
}

.mediaGallerySetupModal {
  width: 99%;
  min-width: 99% !important;
  min-height: 99% !important;
  padding: 10px !important;
  border-radius: 15px !important;
}