@import "../../../styles/variables";
@import "../../../components/Modal/Modal.module.scss";
.verticalFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.printPlaysWrapper {
    @extend .modalWrapper;
    top: 0;
    left: 0;
    z-index: 7;
    .printPlays {
        overflow: hidden !important;

        @extend .modal;
        background-color: $modal-bg;
        grid-template-rows: 55px 1fr;
        padding: 1rem 1.5rem;
        height: 580px;
        width: 840px;
        left: 420px;
        top: 262px;
        border-radius: 12px;
        .modalHeader {
            padding-bottom: 1rem;
            margin: 0;
        }
        .closeIcon {
            float: right;
            padding: 0.2rem;
            cursor: pointer;
        }
        .printPlaysBody {
            display: flex;
            .printsFinal {
                background-color: #151724;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                width: 410px;
                height: 500px;
                grid-template-columns: repeat(2, 1fr);
                padding: 0;
                & > div {
                    all: unset;
                }
            }
            & > div {
                padding: 2rem 2rem 0;
                width: 50%;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: start;

                & > div {
                    display: flex;
                    ul {
                        list-style: none;
                        width: 190px;
                        li {
                            margin: 0.5rem 0;
                            display: flex;
                            width: 100%;
                            gap: 0.7rem;
                        }
                    }
                }

            }
            .printPlaysMessage{
                width: 92%;
                display: flex;
                align-items: center;
                gap: 1rem;
                padding: 0 8px;
                background-color: #151724;
                border-radius: 8px;
               position: absolute;
               bottom: 4rem;
               left: 0rem;
               font-size: 16px;
                
            }
            .preview {
                background-color: #151724;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                border-radius: 6px;
                img {
                    width: 100%;
                }
                p {
                    text-align: center;
                }
            }
        }
        &.open {
            .printPlays {
                display: grid;
            }
        }
    }
}
.printPlaysOverlay {
    @extend .modalOverlay;
    top: 0;
    left: 0;
    z-index: 6;
}
.twoPlaysPerPage {
    flex-direction: column;
    justify-content: flex-start;
    height: 260px;
}
.eightPlaysPerPage {
    flex-direction: column;
    justify-content: flex-start;
    height: 260px;
}

.mainPrintPage {
    height: 0 !important;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .singlePreview {
        h4 {
            color: black !important;
        }
        display: flex;
        justify-content: start;
        position: relative;
        width: 760px !important;
        height: 1045px !important;
        margin: 1rem;
        flex-direction: column;
        background-color: white;
        color: black;
        padding: 1rem;
        & > div {
            width: 100%;
            background-color: white;
            border: none;
            & > div {
                & > div {
                    & > div {
                        border: 1px solid black !important;
                    }
                }
            }
        }
        canvas {
            all: unset !important;
          border: 1px solid black !important;
        }
        section {
            position: absolute;
            top: 50%
        }
    }
}
.multiPreview {
    background-color: white;
    color: black;
    position: relative;
    page-break-after: always;
    height: 1055px !important;
    width: 760px !important;
}
.printPlays h3,
h4 {
    width: 100%;
    text-align: center;
}
@media print {
    .mainPrintPage { 
        all:unset;
        counter-reset: multipage;
        width: 760px;
        visibility: visible !important;
        margin: 0 !important;
        padding: 0 !important;
        height: auto;
        
        div {
            margin: 0 auto !important;
            padding: 0 !important;
        }
        .singlePreview {
            page-break-after: always;
            counter-increment: page;
            h4 {
                margin: 0;
                padding: 0;
            }
           
            & > div {
                width: 100%;
                background-color: white;

                & > div {

                    & > div {
                        border: 1px solid black !important;
                        width: 100%;
                        overflow:hidden !important;
                    }
                }
            }
           
            section {
                position: absolute;
                top: 50%;
                padding-left: 1rem;
            }
            p {
                padding-left: 1.5rem;
            }
        }
        .singlePreview::after{
            content: counter(page);
            text-align: center;
        }
    }
    .multiPreview {
        page-break-after: always;
        counter-increment: multipage;
        margin: 0;
        padding: 0;
        h2 {
            margin: 0;
            padding: 0;
            padding-left: 0.7rem;
            margin-left: -.5rem;
            width: 100%;
            overflow: hidden;
            margin-bottom: 2px;
            // white-space: nowrap;
            // text-overflow: ellipsis;
        }
        & > div {
            margin: 1px 5px 1px -0.5rem !important;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 0.8rem !important;
            & > div {
                background-color: white;
                border-bottom: 2px solid black !important;
            }
        }
        h4 {
            bottom: -1.5rem;
            margin-left: -0.5rem;
        }
    }
    .multiPreview::after{
        content: counter(multipage);
        text-align: center;
        width: 97.7%;
        position: absolute;
        bottom: -1.85rem;
    }
    .printPlays h3,
    h4 {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: 1rem;
    }
}


.printPlaysBody .printButtons {
    position: absolute;
    bottom: -1rem;
    right: 0;
    padding: 1rem;
    button {
        height: 36px;
        width: 65px;
        border-radius: 4px;
        padding: 8px 16px;
        margin-right: 16px;
    }
    .playThumbnail {
        position: fixed;
        top: 0;
    }
}


.printPlaysBody .printsPerPage {
    display: flex;
    gap: 2rem;
    li {
        width: 100%;
    }
    label {
        width: 190px;
    }
}

.printPlaysBody .printDraftPlays {
    display: flex;
    gap: 2.5rem;

}
