@import "../../../styles/variables";

.mediaListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 22px;
    height: 90px !important;
    background: transparent !important;

    &.selected {
        border-color: var(--ion-color-tertiary);
    }
}

.checkbox label {
    display: flex;
    justify-self: center;
    align-items: center;
}

.mediaThumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 30px;
    width: 60px;
}

.title {
    font-size: 17px;
    color: $whisper;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 40px;
    width: 370px;
}

.category {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
}

.label {
    color: #7E7E85;
}

.tagsWrapper {
    display: flex;
    flex-direction: row;
}

.tagChip {
    display: flex;
    background-color: #ABABB7;
    color: #151724;
    border-radius: 4px;
    padding: 1px 6px;
    margin-right: 10px;
}

.highlighttagChip {
    color: #151724;
    border-radius: 4px;
    padding: 1px 6px;
    margin-right: 10px;
    display: flex;
    background-color: #ABABB7;

    &:hover {
        background-color: #ffd623;
    }
}

.mediaImage {
    max-width: 100%;
    max-height: 100%;
    height: 60px;
    border-radius: 6px;
    object-fit: cover;
}

.audioIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    width: 100%;
}

.videoIcon {
    position: absolute;
    top: 24px;
    width: 25px;
    left: 18px;
}

.selected {
    border: 1px solid yellow;
}

.default {
    border-bottom: 1px solid grey !important;
}