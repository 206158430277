@import "../../../../styles/variables";

.InstallBuilder {
  width: 100%;
  padding: 16px;
  background-color: $tuna;
  border-radius: 6px;

  &QuizzesQuestionsModal {
    width: 1000px !important;
    max-width: 1200px !important;
    min-width: auto !important;
    padding: 0 24px !important;
  }

  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Column {
    display: flex;
    flex-direction: column;
  }

  &RowSpacingNormal {
    margin-top: 16px;
  }

  &RowSpacingLarge {
    margin-top: 24px;
  }

  &TopSection {
    padding-bottom: 26px;
    box-shadow: inset 0px -1px 0px $bright-gray;
  }

  &BottomSection {
    padding-top: 14px;
  }

  &TitleWrapper {
    display: flex;
    align-items: center;
  }

  &Title {
    font-size: 17px;
    line-height: 1;
  }

  &SlidesBody {
    position: relative;
  }

  &ManageButtonsWrapper {
    display: flex;
    align-items: center;

    & > button,
    & > span {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &TypeWrapper {
    width: 145px;
    height: 44px;
    margin-right: 16px;
  }

  &FullWidthWrapper {
    flex: 1;
  }

  &PublishToggleRow{
    display: flex;
    flex-direction: row;
    margin: 0 6px 0 16px;
    align-items: center;
    gap: 12px;
    height: 46px;
    width: 148px;
  }

  &CheckboxesWrapper {
    display: flex;
    flex-direction: column;

    & > label {
      margin-bottom: 26px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &SlidesTitle {
    font-size: 17px;
    color: $spun-pearl;
    font-weight: 700;
  }

  &AddSlideButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      flex: 1;
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &AddSlideButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bright-gray;
    border: none;
    border-radius: 6px;
    padding: 16px;
    cursor: pointer;

    &:hover {
      background-color: $abby;
    }

    img {
      position: relative;
      bottom: 1px;
      margin-right: 10px;
      width: 14px;
    }

    span {
      color: $spun-pearl;
    }
  }

  &SlideWrapper {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &BackButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;

    & > svg {
      fill: $white;
    }
  }

  &FilterBar {
    display: flex;
    & > div {
      flex-grow: 1;
      & > div {
        max-width: 250px;
        vertical-align: middle;
      }
      & > button {
        vertical-align: middle;
        margin-top: -16px;
        margin-left: 16px;
        & img {
          height: 20px;
        }
      }
    }
    & > label {
      max-width: 250px;
    }
  }

  &BackLink {
    svg {
      fill: $white !important;
    }
  }
}

.categoryEdit {
  &Line {
    display: flex;
  }

  &Selector {
    width: calc(100% - 136px - 16px);
  }

  &Button {
    width: 136px;
    margin-left: 16px;
    align-self: flex-start;
  }
}
