@import "../../styles/variables";

.AudioPlayer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background-color: $mirage;
  border-radius: 6px;
  overflow-x: auto;

  &TitleWrapper {
    margin-right: 16px;
  }

  &Title {
    color: $spun-pearl;
    text-transform: uppercase;
  }

  &RecordButtonWrapper {
    margin-right: 8px;
  }

  &PlayButton,
  &RecordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 24px;
    margin: 0;
    padding: 0;
    border-radius: 100px;
    border: none;
    transition: all 0.3s ease-in-out;

    &:hover {
      cursor: pointer;
    }

    svg {
      width: 10px;
    }
  }

  &RecordButton {
    background-color: $burnt-sienna;

    &:hover:not(&:disabled) {
      background-color: $vivid-tangerine;
      cursor: pointer;
    }

    &:disabled {
      border: 1px solid rgba($jumbo, 0.3);
      background-color: transparent;

      svg {
        fill: rgba($jumbo, 0.3);
      }
    }

    svg {
      fill: $white;
    }
  }

  &PlayButton {
    border: 1px solid $dodger-blue;
    background-color: $dodger-blue;

    &:hover:not(&:disabled) {
      border: 1px solid $malibu;
      background-color: $malibu;
      cursor: pointer;
    }

    svg {
      fill: $white;
    }

    &:disabled {
      border: 1px solid rgba($jumbo, 0.3);
      background-color: transparent;

      svg {
        fill: rgba($jumbo, 0.3);
      }
    }
  }

  &RecordBarWrapper {
    flex: 1;
    flex-direction: row-reverse;
    margin: 0 16px;
    height: 20px;
    overflow: hidden;
    & > span {
      width: 2px;
      min-height: 2px;
      margin: 1px;
      background: $spun-pearl;
      display: inline-block;
      vertical-align: middle;
      &[class~="recorded"]{
        background: $lightning-yellow;
      }
    }
  }

  &SeekBarWrapper {
    flex: 1;
    margin: 0 16px;
    overflow: hidden;
  }

  &SeekBar {
    width: 100%;
    height: 2px;
    min-height: 2px;
    border-radius: 1px;
    background-color: $bright-gray;
  }

  &TimeWrapper {
    color: $jumbo;
  }

  &FileControlsWrapper {
    display: flex;
    align-items: center;
    margin-left: 16px;

    button {
      margin-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &DeleteButton,
  &MediaGalleryButton,
  &SaveButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }

  &DeleteButton,
  &MediaGalleryButton {
    width: 24px;
    height: 24px;

    svg {
      fill: $alto;
    }
  }

  &DeleteButton {
    svg {
      width: 10px;
    }
    &.Recording {
      cursor: not-allowed;
      svg {
        fill: rgba($jumbo, 0.3);
      }
    }
  }

  &MediaGalleryButton {
    svg {
      width: 16px;
    }
    &.Recording {
      cursor: not-allowed;
      svg {
        fill: rgba($jumbo, 0.3);
      }
    }
  }

  &SaveButton {
    padding: 5px 8px;
    color: $spun-pearl;
    font-size: 12px;
    line-height: 1;
    border: 1px solid $jumbo;
    border-radius: 4px;
    &.Recording {
      cursor: not-allowed;
      border: 1px solid rgba($jumbo, 0.3);
      color: rgba($jumbo, 0.3);
    }
  }

  audio {
    display: none;
  }
}
