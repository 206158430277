@import "../../../styles/variables";

.addToPlaySetContainer {

  display: flex;
  flex-direction: column;

  .modalHeader {
    padding: 1rem;
    margin: 0;
    display: flex;
    justify-content: space-between;

    span.playSetTitle {
      display: inline-flex;
      justify-content: left;
      align-items: center;
      width: 100%;
      font-size: 16px;

      button {
        margin-left: 10px;
      }
    }

  }

  .modelEditedTitle {
    display: flex !important;
    align-items: center;
  }

  .closeIcon {
    float: right;
    padding: 0.2rem;
    cursor: pointer;
  }

  .checkIcon {
    float: right;
    padding: 0.2rem;
    cursor: pointer;
  }

  .searchBarSection {
    padding: 0 1rem;
    margin-bottom: 5px;
  }

  .playSetsListSection {
    width: 100%;
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    overflow: auto;

    .active {
      color: $mirage;
      background: $lightning-yellow !important;
    }

    .resultNotFound {
      display: flex;
      justify-content: center;
    }

    .playSetBtns {
      background: $add-to-gamePlan-btn-bg;
      border-top: unset;
      border-bottom: 1px solid #353849;
      border-left: unset;
      border-right: unset;
      border-radius: unset;
      justify-content: left;
      margin-bottom: 2px;

      &:first-child {
        margin-top: 5px;
        border-top: 1px solid #353849;
      }

      &:last-of-type {
        margin-bottom: 5px;
      }
    }
  }


  .modalFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5rem;
    background: $tuna;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    button {
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }
    }

    .createBtn {
      width: fit-content;
      margin-left: 15px;
    }
  }


}