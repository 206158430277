@import "../../../styles/variables";
.open {
    z-index: 10000;
    background-color: $steel-gray;
    opacity: 1;
    transform: none;
    max-height: calc(100% - 96px);
    outline: 0;
    position: absolute;
    width:459px;
    min-height: 16px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 8px;
    transition: opacity 267ms cubic-bezier(0.4,0,0.2,1) 0ms, transform 178ms cubic-bezier(0.4, 0, 0.2,1) 0ms;
    transform-origin: 0px 26px;
    border-radius: 6px;
    margin-left: 3px;
}

.close {
    opacity: 0;
    transform: scale(0.75, 0.5625);
    transform: 251ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 167ms cubic-bezier(0.4, 0, 0.2, 1) 84ms;
    transform-origin: 0px 26px;
    transform-origin: 0px 26px;
}

.defaultButtonColor {
    background-color: var(--ion-color-primary);
    color: $draft-mode-button-text;
}


.playModeSelector {
    .button {
        background-color: $dark-bg;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-right:6px;
        outline: none;
        border: none;
        font-family: $quantico;
        cursor: pointer;
    }
    .createPlayButtonPadding {
        padding: 12px 10px;
        min-width: 100px;
        width:120px;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 200%;
    }

    .editPlayButtonPadding {
        padding: 5.5px 15px !important;
    }
    .popupButtonColor {
        background-color: $abby;
        color: white;
    }

    .icon {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $draft-mode-button-text;
    }
}



.rowItem {
    padding: 16px 12px 12px 16px;
    cursor: pointer;
    p {
        color: rgba(255, 255, 255, 0.5);
    }
    .labelPlacement {
        display: inline-flex;

        .descriptionSpacing {
            margin-left: 2px;
        }
        span {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            font-size: 14px;

            .header {
                font-weight: bold;
            }

            .description {
                color:$draft-mode-text;
            }
            span {
                padding: 0px 0px 10px 20px;
            }
        }
    }

}

.grayText {
    color:$draft-mode-text;
}

.whiteText {
    color: white;
}

/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
 }
 .ripple:hover {
   background: $draft-mode-hover;
 }
 .ripple:active {
   background-color:$draft-mode-selected;
   background-size: 100%;
   transition: background 0s;
 }
