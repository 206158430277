@import "../styles/variables";


.app {
  height: 100%;
  width: 100%;
  color: var(--ion-color-primary);

  @include media-max-width(sm) {
    overflow: hidden;
  }

  &.modalOpen {
    overflow: hidden;
  }

  .routeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include media-max-width(sm) {
      display: none;
    }
  }
}

.page {
  width: 100%;
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
  max-width: 74.25rem; // This gets the page content to 1024 to match design
  padding: 0 2rem;
  box-sizing: border-box;
}

.widePage {
  max-width: 95vw;
}

.offCenterPage {
  height: calc(100vh - #{$app-header-height});
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
}
