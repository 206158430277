@import "../../../styles/variables";

$page-title-and-filters-height: 77px;

.dashboardSidebar {
    width: 200px;
    top: #{$page-title-and-filters-height};
    bottom: 0;
    left: 0;
    @include media-max-width(xl) {
        width: 150px;
    }

    .sidebarContainer {
        background-color: $card-bg;
        height: 100%;
        padding: 1rem 0 3rem;
    }


    .headerBar {
        display: flex;
        align-items: center;
        padding: 0.7rem 0.55rem;
        margin: 0 0.2rem;
        font-weight: bolder;
        font-size: 15px;
        cursor: pointer;
        opacity: 0.6;

        img {
            width: 24px;
        }

        .itemName {
            margin-left: 10px;
        }
    }

    .active {
        display: flex;
        align-items: center;
        padding: 0.7rem 0.55rem;
        border-left: 3px solid $lightning1;
        font-weight: bolder;
        font-size: 15px;
        background-color: $bright-gray;
        cursor: pointer;
        
        img {
            width: 24px;
        }

        .itemName {
            margin-left: 10px;
        }
    }

    .categoryName {
        color: $spun-pearl;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: normal;
        margin: 1.8rem 0.25rem 0.25rem 1rem;
    }

    .categoryFilter {
        display: flex;
        justify-content: space-between;
        color: var(--ion-color-medium);
        padding: 0.5rem 1rem;
        text-transform: uppercase;
        cursor: pointer;
        margin: 0 -1rem;

        &.selected {
            color: var(--ion-color-primary);
            background: $bright-gray;
        }
    }

    .schemeFilter {
        @extend .categoryFilter;
        padding-left: 2rem;
    }
}
