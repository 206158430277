@import "../../styles/variables";

.licenseCard {
  width: 600px;
  color: $white;
  border-radius: 6px;
  background: $bright-gray;

  &.bronze,
  &.silver,
  &.gold,
  &.platinum,
  &.bronze2023,
  &.silver2023,
  &.gold2023,
  &.youth2023,
  &.college2023 {
    color: $black;
  }

  &.bronze, &.bronze2023, &.youth2023 {
    background: linear-gradient(140.67deg, #FF9D2A 12.93%, #FFC9A1 77.48%), linear-gradient(140.67deg, #FFC9A1 12.93%, #FF9D2A 77.48%);
  }

  &.silver, &.silver2023 {
    background: linear-gradient(140.67deg, #A2A2A2 12.93%, #E1E1E1 77.48%), linear-gradient(140.67deg, #FFBC17 12.93%, #FFEA2D 77.48%);
  }

  &.gold, &.gold2023 {
    background: $lightning-yellow;
  }

  &.platinum, &.college2023 {
    background: linear-gradient(140.67deg, #BEBEBE 12.93%, #FFFFFF 77.48%), linear-gradient(140.67deg, #FFBC17 12.93%, #FFEA2D 77.48%);
  }

  &Top,
  &Bottom {
    padding: 24px;
  }

  &Top {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;

    &PreTitle {
      @extend %micro;
    }

    &Title {
      margin-top: 8px;
      font-size: 48px;
      line-height: 1;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  &Bottom {
    font-size: 17px;
    line-height: 34px;
    margin-top: 27px;
    padding-top: 14px;
    padding-bottom: 14px;
    border-top: 2px solid rgba($bright-gray, 0.2);

    &HeaderText {
      @extend %micro;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
}
