@import "../../styles/variables";

.roster {
  display: grid;
  grid-template-columns: [main] 1fr [side] 33.3%;
  column-gap: 1rem;
  margin-top: 1.5rem;

  .main,
  .side {
    > div {
      margin-bottom: 1rem;

      h2[class="cardTitle"] {
        font-size: 17px;
      }

      .noInvitations {
        margin-bottom: 0;
        color: $nav-text;
      }
    }

    .coachTable {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: start;
      margin-top: 0.5rem;

      p {
        padding: 2px 0 0 5px;
        margin: 2px 0 0 5px;
      }

      .role {
        color: $disabled-text;
        font-size: 12px;
      }
    }
  }

  .main {
    .Toolbar {
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-grow: 1;

      .filterListAlignment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px 0 5px 0;
        width: 50%;
        position: absolute;
        left:0;
        font-size: 12px;

        .filter {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 9px 12px;
          border-radius: 40px;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0 5px;
          color:$tertiary-button-border;
        }

        .selected {
          background: $sunglow;
          color:black;
        }
        .unselected {
          background-color: $input-bg;
        }
      }

      label {
        position: absolute;
        width: 35%;
        right:0
      }

    }

    h1.rosterTitle {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      margin: 0 0 0 2px;
      font-size: 17px;
    }

    .rosterTableStarter {
      padding-top: 0.3rem;
      margin: 0.2rem 0.2rem 0;

      .search {
        width:50%
      }
    }

    .rosterTableHeadStyle {
      font-size: 10px;
      color:$tertiary-button-border;
      .marginLeft{
        margin-left: 15%;
      }
      .marginLeftPos {
        margin-left: 3%;
      }
    }

    .VisuallyHidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 20px;
      width: 1px;
    }

    .TableHeadPadding {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .rosterTable {
      width:100%;
      margin-bottom: 0.5rem;

      .TableContainer {
        overflow-x: visible;
        border-radius: 6px;

        .centerPlayerName {
          display: flex;
          align-items: center;
          width:150%;
          padding-left: 5%;
          flex-grow: 1;

          p {
            margin-left: 10px;
          }
        }
        .rosterTableBody {
          background-color: $roster-bg;

          .row {
            border: 2px solid $tuna;
            transition: background-color 0.3s;

            &:hover {
              cursor: pointer;
              background: $roster-bg-hover;
            }
          }

          .PlayerCell {
            padding: 4px;
            width: 155%;
          }

          .PosCell {
            font-size: 12px;
          }

          .TableCell {
            font-size: 14px;
          }
        }
      }
    }
  }

  .emptyRosterContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:40px;
    margin: 120px auto 160px auto;
    width:334px;
    height: 179px;
    background: var(--ion-color-secondary);
    border-radius: 12px;

    h1 {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 0;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      color: $add-players-text
    }
  }
}
