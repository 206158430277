@import "../../styles/variables";

.teamDetails {
  .subscriptionCard {
    width: 100%;
  }

  .wrapper {
      height: 100vh;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 16px;
  }
}

.teamDetails {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;

  input {
    height: 56px;
  }

  &_LeftSide,
  &_RightSide {
    display: flex;
    flex-direction: column;
  }

  &_LeftSide {
    width: 146px;
    min-width: 146px;
  }

  &_RightSide {
    flex: 1;
    margin-left: 40px;
  }

  &_RowSmall {
    width: 100%;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &_RowMedium {
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &_FlexRow {
    display: flex;
    align-items: center;

    &_Columns {
      flex-direction: column;
    }
  }

  &_Label {
    @extend %caption;

    margin-bottom: 10px;
    color: #FFFFFF;
    text-transform: uppercase;
  }

  &_LogoButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 146px;
    height: 146px;
    margin: 30px auto;
    color: $spun-pearl;
    fill: $spun-pearl;
    background: transparent;
    border: 1px dashed $spun-pearl;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }

    span {
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &Image {
      width: 90%;
      max-height: 80px;
      object-fit: cover;
      object-position: center;
    }

    &CloseIcon {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &_FileUpload {
    &Input {
      display: none;
    }
  }

  &_City,
  &_State,
  &_Zip {
    display: flex;
    margin-bottom: 12px;
  }

  &_ColorPicker {
    flex: 1;

    button {
      height: 56px;
      margin-bottom: 12px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }

  .bottomButtons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 12px;
    }
  }
}
