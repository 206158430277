@import "../../../styles/variables";


.trialBanner {
  @extend %quantico;
  display: none;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 4px 48px;
  line-height: 1;
  white-space: nowrap;
  height:var(--app-trial-banner-height);

  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }


  // Width
  &.full {
    display: flex;
    width: 100%;
  }

  &.auto {
    display: inline-flex;
  }

  // Primary Theme
  &.primary {
    color: $mirage;
    border: none;
    background: $lightning-yellow;
    background-clip: padding-box;
  }

  .upgradeButton {
    color: white;
    background: $mirage !important;
    width: 115px;
  }

 
}
