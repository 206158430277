@import "../../styles/variables";

.playDetails {
  position: relative;

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .selectsContainer {
    margin: 1.25rem 0;

    .selectsContainerRow {
      &:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
        margin-bottom: 1rem;
      }
      &:nth-child(2) {
        display: grid;
        gap: 1rem;
      }
    }
    .secondChildOffense {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .secondChildDefense {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    label {
      background-color: $dark-bg;
      border-radius: 4px;
      margin-top: 25px;

      > div:first-of-type {
        transform: translate(0px, -25px);
      }

      > div:nth-of-type(2) {
        margin-top: -27px;
      }
    }
  }

  .continueButton {
    position: absolute;
    top: -50px;
    right: -2px;
    width: 150px;
  }

  .cancelButton {
    position: absolute;
    top: -50px;
    right: 168px;
    width: 90px;
  }
}
