@import "../../../styles/variables";

.header {
    padding: 1rem;
    border-bottom: 1px solid $bright-gray;
    .goBackArea{
        display: flex;
        font-size: 20px;
        align-items: center;
        cursor: pointer;
        img {
            width: 1.5rem;
            margin-right: 2rem;
        }
    }
  }

.imageEditorGrid {
    display: grid;
    grid-template-rows: 55vh 1fr;
    .mediaPreview {
        width: 100%;
        max-height: 55vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.5rem;
    
        .imagePreview {
          max-width: 100%;
          max-height: 100%;
        }
      }
}
.editToolbar {
    background: $card-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
        margin: 0.75rem;
        cursor: pointer;
        width: 2.5rem; 
    }

    .uploadButton {
      position: absolute;
      right: 0;
      margin-right: 1rem;
    }
  }

  .loader {
    position: absolute;
    background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
    background-size: 300% 300%;
    animation: cycle 1s ease infinite;
  }