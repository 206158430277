@import "../../styles/variables";

.shareCode {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-max-width(sm) {
        display: flex;
      }
    .wrapper {
        overflow: auto;
        height: 100vh;
        display: flex;
        flex-flow: column;
        align-items: center;
        padding: 16px;
        h3{
        font-family: 'Quantico';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 160%
        }
        .codeContainer {
            display: flex;
            flex-direction: column;
            background-color: #353849;
            padding: 16px;
            border-radius: 12px;
            align-items: center;
        }

        ion-button {
            --border-color: var(--ion-color-tertiary);
            --color: var(--ion-color-primary);
        }
    }
    button{
        margin-top: 24px;
        width: 95%;
    }
    .backBtn{
        width: 90%;
    }
    .invitationCode {
        font-weight: 700;
        font-family: "Chakra Petch";
        font-size: 48px;
    }
}
