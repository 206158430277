@import "../../styles/variables";

.checkbox {
  input {
    display: none;
  }

  &.hideCheckbox {
    input~span {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &LabelLess{
    input~span {
      padding: 10px !important;
    }
  }

  input~span {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-left: 1.9rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      border-radius: 4px;
      cursor: pointer;
    }

    &::before {
      width: 20px;
      height: 20px;
      left: 0;
      background: $lightning-yellow;
    }

    &::after {
      width: 18px;
      height: 18px;
      left: 1px;
      background: $input-bg;
    }

    a {
      font-weight: 700;
    }
  }

  input:checked~span,
  input:indeterminate~span {
    &::after {
      background-color: transparent;
    }
  }

  input:checked~span::after {
    background: url("../../resources/images/check.svg") no-repeat 50% 50%;
  }

  input:indeterminate~span::after {
    background: url("../../resources/images/dash.svg") no-repeat 50% 50%;
  }

  &.disabled {
    cursor: not-allowed;

    span {
      &::before,
      &::after {
        cursor: not-allowed;
      }

      &::before {
        background: var(--ion-color-medium);
      }
    }
  }
}

.checkboxWithPadding {
  input {
    display: none;
  }

  &.hideCheckbox {
    input~span {
      &::before,
      &::after {
        display: none;
      }
    }
  }

  &.hideCheckbox:hover {
    input~span {
      &::before,
      &::after {
        display: block;
      }
    }
  }

  input~span {
    position: relative;
    display: inline-flex;
    align-items: center;
    // padding-left: 1.9rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      border-radius: 4px;
      cursor: pointer;
    }

    &::before {
      box-sizing: border-box;
      padding: 26px;
      width: 20px;
      height: 20px;
      left: 0;
    }

    &:hover::after {
      border: 1px solid var(--ion-color-tertiary);
    }

    &::after {
      top: 1px;
      left: 1px;
      border: 1px solid #7E7E85;
      margin: 14px;
      width: 18px;
      height: 18px;
      left: 1px;
      background: $input-bg;
    }

    a {
      font-weight: 700;
    }
  }

  input:checked~span,
  input:indeterminate~span {
    &::after {
      background-color: transparent;
    }
  }

  input:checked~span::after {
    background: url("../../resources/images/check.svg") no-repeat 50% 50%;
    background-color: var(--ion-color-tertiary);
    border-radius: 4px;
    border: 1px solid var(--ion-color-tertiary);
  }

  input:indeterminate~span::after {
    background: url("../../resources/images/dash.svg") no-repeat 50% 50%;
  }

  &.disabled {
    cursor: not-allowed;

    span {
      &::before,
      &::after {
        cursor: not-allowed;
      }

      &::before {
        background: var(--ion-color-medium);
      }
    }
  }
}
