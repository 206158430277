@import "../../styles/variables";

.nav {
  display: flex;
  font-size: 11px;

  @include media-max-width(lg) {
    display: none;
  }

  @include media-min-width(xl) {
    font-size: 12px;
  }

  a {
    margin-right: 0.5rem;
    text-decoration: unset;
    color: $nav-text;

    @include media-min-width(xl) {
      margin-right: 2rem;
    }

    &[class="active"] {
      color: var(--ion-color-primary);
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}