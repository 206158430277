@import "../../../styles/variables";

.saveAsFormationModal {
  width: 320px;

  label:first-child {
    margin-bottom: 1rem;
  }

  .buttonRow {
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;

    button {
      width: inherit;

      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  .errorMessage {
    color: var(--ion-color-danger);
    margin-top: 0;
  }
}
