@import "../../styles/variables";
.container {
  border: 6px solid $lightning1;
  margin-right: -5px;

  .header {
    background: $lightning1;
    padding: 6px 30px;
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    .title {
      font-size: 17px;
      font-weight: bold;
      color: $mirage;
    }
    .logoutButton {
      color: black;
      border: 1px solid black;
    }
  }
}