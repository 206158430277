@import "../../../styles/variables";

.thumbnailContainer {
  width: 100%;
  height: 100%;
  position: relative;

  label {
    width: 100%;
    height: 100%;

    span:first-of-type {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  .thumbnailContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 12.5vh !important;
    width: 10.2vw !important;
    border: 2px solid transparent;
    border-radius: 6px;
    background-color: $bright-gray;

    .thumbnail {
      height: 12vh !important;
      width: 10vw !important;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
    }



    &:hover {
      border-color: #656568;
    }

    &.selected {
      border-color: var(--ion-color-tertiary) !important;
    }

    &.previewSelected {
      border-color: #cfcfcf !important;
    }

    &.addBorder {
      &:hover {
        border: 2px solid #5e5f69;
      }
    }

  }

  input[type="checkbox"]~span {
    padding-left: 0;

    &::before,
    &::after {
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 1;
      padding: unset !important;
      margin: 8px !important;
    }

    &:after {
      top: 5px;
      left: 5px;
    }
  }

  .mediaImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .audioIcon {
    padding: 20px;
    width: 36px;
  }

  .videoIcon {
    position: absolute;
  }
}

.mediaInfo {
  margin-top: 4px;
  font-family: $quantico;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  max-width: 11.5vw;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:last-child {
    font-size: 11px;
    margin-top: 2px;
    color: $jumbo;
  }
}