@import "../../../../styles/variables";

.cardSubtitle {
    color: #ffffff;
    font-size: 13px;
    margin-bottom: 20px;
    border-bottom: 1px solid $trout;
    padding-bottom: 10px;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 15px;
}

.table {
    border-collapse: separate;
    border-spacing: 0;
    min-width: 270px;
    max-height: 300px;
    height: 300px;
    overflow-y: hidden;
    display: block;
}

.table:hover {
    overflow-y: auto;
}

.table tr th,
.table tr td {
    border-right: 1px solid $trout;
    border-bottom: 1px solid $trout;
    padding: 5px;
}

.table tr th:first-child,
.table tr td:first-child {
    border-left: 1px solid $trout;
}

.table tr th {
    // background: #eee;
    border-top: 1px solid $trout;
    text-align: left;
    width: 100%
}

/* top-left border-radius */
.table tr:first-child th:first-child {
    border-top-left-radius: 6px;
}

/* top-right border-radius */
.table tr:first-child th:last-child {
    border-top-right-radius: 6px;
}

/* bottom-left border-radius */
.table tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
.table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}

.table thead {
    @extend %micro;

    color: $spun-pearl;
    text-transform: uppercase;
    border-radius: 6px;
}
