.mediaPlayerModal {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 60vh;
  }

  video {
    max-width: 100%;
  }
}
