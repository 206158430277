@import "../../styles/variables";

.licenseExpirationModal {
  position: relative;
  width: 740px;
  padding: 21px 0 0;
  background: var(--ion-color-secondary);
  border-radius: 12px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    font-size: 17px;
    padding: 0 24px;
  }

  .body {
    display: flex;
    gap: 14px;
    justify-content: space-between;
    padding: 0 24px 24px;
  }

  .bodyText {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    padding: 0 12px;

    .large {
      @extend %chakra-petch-h2;
    }

    .centered {
      text-align: center;
    }
  }

  .closeModalBtn {
    cursor: pointer;
    background: none;
    border: none;
    -webkit-appearance: none;
  }

  .footer {
    background-color: $tuna;
    display: flex;
    gap: 16px;
    justify-content: end;
    padding: 21px 24px 21px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}