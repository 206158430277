@import "../../styles/variables";

.label {
  @extend %quantico;

  --labelLeft: 16px;
  --labelRight: 16px;
  --labelOpacity: 1;
  --closeButtonOpacity: 0;
  --closeButtonCursor: normal;
  --closeButtonPointerEvents: none;

  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;

  .input,
  textarea {
    &:disabled {
      cursor: not-allowed;
    }
  }

  .input {
    width: 100%;
    padding: var(--labelTop) var(--labelRight) var(--labelBottom) var(--labelLeft);
    border: 1px solid transparent;
    border-radius: 6px;
    outline: none;
    transition: padding 0.3s;
    box-sizing: border-box;
  }

  textarea {
    resize: none;
    cursor: auto;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  span {
    position: absolute;
    top: 50%;
    left: var(--labelLeft);
    color: var(--ion-color-medium);
    line-height: 1;
    transform: translateY(-50%);
    transform-origin: 0;
    transition: all 0.3s;
  }

  &.hideLabel {
    span {
      opacity: 0 !important;
    }
  }

  &.disabled {
    span {
      color: $steel-gray;
    }
  }

  &.hasValue {
    --closeButtonOpacity: 1;
    --closeButtonCursor: pointer;
    --closeButtonPointerEvents: all;

    span {
      transform: scale(0.80);
      opacity: var(--labelOpacity);
    }

    &.dark {
      span {
        color: $white;
      }
    }

    &.disabled {
      span {
        color: $steel-gray;
      }
    }
  }

  .icon,
  .clearButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .clearButton {
    right: 18px;
    opacity: var(--closeButtonOpacity);
    cursor: var(--closeButtonCursor);
    pointer-events: var(--closeButtonPointer);
  }

  &.icon-left {
    .icon {
      left: 18px;
    }
  }

  &.icon-right {
    .icon {
      right: 18px;
    }
  }

  &.clearButton {
    &.icon-right {
      .icon {
        display: none;
      }
    }
  }

  // Themes
  &.dark {
    .input {
      color: $white;
      background-color: $input-bg;

      &:disabled {
        background-color: $input-disabled-bg;
        color: $steel-gray;
      }
    }

    &:hover {
      span {
        color: $spun-pearl;
      }
    }

    &:focus {
      span {
        color: $jumbo;
      }
    }

    &.hasValue {
      &:hover {
        span {
          color: $white;
        }
      }
    }

    &.error {
      .input {
        border: 1px solid $burnt-sienna;
      }
    }

    &.disabled {
      &:hover {
        span {
          color: $steel-gray;
        }
      }
    }
  }

  // Sizes
  &.medium {
    --labelTop: 10px;
    --labelBottom: 10px;

    .input {
      @extend %small;
    }

    &.hasValue {
      &.hasPlaceholder {
        --labelTop: 17px;
        --labelBottom: 3px;

        span {
          top: 6px;
        }
      }

      &.icon-left {
        --labelLeft: 50px;
      }

      &.icon-right {
        --labelRight: 50px;
      }
    }

    &.icon-left {
      --labelLeft: 50px;
    }

    &.icon-right {
      --labelRight: 50px;
    }

    &.clearButton {
      --labelRight: 50px;
    }
  }

  &.large {
    --labelTop: 13.5px;
    --labelBottom: 13.5px;

    .input {
      @extend %body;
    }

    &.hasValue {
      &.hasPlaceholder {
        --labelTop: 21px;
        --labelBottom: 6px;

        span {
          top: 8px;
        }
      }

      &.icon-left {
        --labelLeft: 50px;
      }

      &.icon-right {
        --labelRight: 50px;
      }
    }

    &.icon-left {
      --labelLeft: 50px;
    }

    &.icon-right {
      --labelRight: 50px;
    }

    &.clearButton {
      --labelRight: 50px;
    }
  }

  &.small,
  &.x-small {
    --labelLeft: 10px;
    --labelRight: 10px;

    .input {
      @extend %small;
    }

    &.hasValue {
      &.hasPlaceholder {
        --labelOpacity: 0;

        span {
          transform: translateY(-50%);
        }
      }

      &.icon-left {
        --labelLeft: 44px;
      }

      &.icon-right {
        --labelRight: 44px;
      }
    }

    &.icon-left {
      --labelLeft: 44px;
    }

    &.icon-right {
      --labelRight: 44px;
    }

    &.clearButton {
      --labelRight: 44px;
    }
  }

  &.small {
    --labelTop: 6px;
    --labelBottom: 6px;
  }

  &.x-small {
    --labelTop: 3px;
    --labelBottom: 3px;
  }
}
