.toolbar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .selects{ 
        width: 60%;
        display: flex;
        align-items: center;
    }
}

.searchHeight {
    height: 44px;
}

.paddingBottom {
    margin-bottom: 0;
}