@import "../../styles/variables";
@import "../../styles/_variables";

.playNotes {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  background: $play-settings-bg;
  border-top: 1px solid var(--ion-color-secondary);
  padding: 40px;

  .noteSection {
    width: 50%;
    cursor: default;
    &:first-of-type {
      margin-right: 40px;
    }
    .title {
      font-size: 13px;
      text-transform: uppercase;
      color: $spun-pearl;
      margin-bottom: 1rem;
    }

    .notesContainer {
      background: $mirage;
      width: 100%;
      height: 210px;
      border-radius: 6px;
      overflow-y: auto;

      textarea {
        height: 210px;
      }
    }

    .playerNotes {
      display: flex;
      padding: 6px 8px;
      border-bottom: 2px solid $bright-gray;
    }

    .playerRoleWrapper {
      background: $lightning-yellow;
      min-height: 26px;
      min-width: 26px;
      max-height: 26px;
      max-width: 26px;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.opponentPlayer {
        background: var(--ion-color-danger);
      }

      .playerRole {
        background: $mirage;
        height: 22px;
        width: 22px;
        border-radius: 11px;
        font-size: 10px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
