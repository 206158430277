@import "../../styles/variables";

$edit-play-toolbar-width: 120px;

.editPlayToolbar {
  background: $play-editor-bg;
  min-width: $edit-play-toolbar-width;
  max-width: $edit-play-toolbar-width;
  position: relative;

  .toolbarColumn {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    border-right: 1.5px solid $tuna;

    &.spaceBetween {
      justify-content: space-between;
    }

    &.smallGap {
      gap: 5px;
    }

    .playControls {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding-top: 0.75rem;

      .lineSnapButtonContainer {
        position: relative;
        width: 100%;
      }

      button {
        @extend .baseButton;
        width: 100%;
        padding: 0.25rem 0.5rem;
        border-radius: 8px;
        position: relative;
      }
    }

    .playerControls {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 8px;

      &.isFormation {
        grid-template-columns: 1fr;
      }

      &.hide {
        pointer-events: none;
        opacity: 0.3;

        * {
          opacity: 1 !important;
        }
      }
    }

    &.secondary {
      position: absolute;
      left: $edit-play-toolbar-width;
      z-index: 1;
      background-color: rgba(242, 242, 248, 0.9); // $whisper
    }

    &.coverageZoneButtonsContainer {
      position: absolute;
      top: 40%;
      padding: 0.75rem 0.5rem;
      left: $edit-play-toolbar-width;
      width: fit-content;
      height: auto;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      z-index: 1;
      background-color: rgba(242, 242, 248, 0.9); // $whisper

      button {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: unset;
        height: 36px;
        width: 36px;
        border-radius: 25%;

        &:not(:last-child) {
          margin-bottom: 0.25rem;
        }
        &:hover {
          background-color: rgba(242, 242, 248, 0.9); // $whisper
        }
    
        img {
          height: 24px;
          width: 24px;
        }
      }
    }

    label {
      width: 90px;
    }

    .doneButton {
      @extend .baseButton;
      border: 1px solid $spun-pearl !important;
      border-radius: 8px;
      margin-top: 1rem;
      padding: 0.25rem 2rem;

      &.hide {
        pointer-events: none;
        opacity: 0.3;
      }

      @media (hover: hover) {
        &:hover {
          border: 1px solid transparent !important;
          background: $lightning-yellow;
          background-clip: padding-box;
        }
      }
    }
  }

  .svgButtonWrapper {
    padding: 2px;
    border-radius: 25%;
    border: 2px solid transparent;
    margin-right: 6px;

    &.selected {
      border: 2px solid $mirage;
    }
  }

  .svgButton {
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 24px;
    }
  }

  .baseButton {
    font-family: $quantico;
    background: $spun-pearl;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $mirage;
    font-size: 14px;
    border: none;
    text-transform: uppercase;

    @media (hover: hover) {
      &:hover {
        background: $lightning-yellow;
        background-clip: padding-box;
  
        &.selected {
          border-color: $whisper;
        }
      }
    }

    &.selected {
      background: $lightning-yellow;
      border-color: $mirage;
      background-clip: padding-box;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.15;
    }
  }

  .squareButton {
    @extend .baseButton;
    height: 44px;
    width: 44px;
    border-radius: 25%;
    border: 2px solid transparent;

    img {
      height: 24px;

      &.undoIcon {
        height: 18px;
      }
    }

    span {
      position: relative;
      top: 1px;
      font-size: 18px;
      color: white;
    }

    .label {
      color: $mirage;
      text-transform: uppercase;
      font-size: 11px;
      letter-spacing: 0.5px;
      position: relative;
      top: 1px;
    }

    .selection {
      color: $mirage;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bolder;
      position: relative;
      top: -1px;
    }
  }

  .lineSnapToggles {
    position: absolute;
    top: 0px;
    left: calc(#{$edit-play-toolbar-width} - 1rem + 3px);
    color: black;
    padding: 16px;
    z-index: 1;
    background-color: rgba(242, 242, 248, 0.9); // $whisper
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    &Row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    &Copy {
      margin-left: 1rem;
    }
  }


  .colorOptionWrapper {
    border-radius: 20%;
    padding: 2px;
    border: 2px solid transparent;

    &.selected {
      border: 2px solid $mirage;
    }

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  .colorOption {
    margin: 0;
    padding: 0;
    border: none;
    height: 22px;
    width: 22px;
    border-radius: 20%;
    cursor: pointer;
  }

  .coverageZoneSliderContainer {
    position: relative;
    height: 200px;

    [class="MuiSlider-rail"] {
      display: none;
    }

    .coverageSliderBackground {
      position: absolute;
      left: -80px;
      top: 85px;
      width: 195px;
      transform: rotate(270deg);
    }
  }
}