@import "../../../../styles/variables";

.editQuiz {
  background: $tuna;
  padding: 16px;
  flex-grow: 1;
  border-radius: 6px;
  overflow: auto;

  .flexRow {
    display: flex;
    align-items: center;
  }

  .editQuizTopBar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .editQuizTitle {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 17px;
    }

    .editQuizTopButtons {
      button {
        height: 38px;
      }
    }
  }

  label {
    margin-bottom: 16px;
  }

  .quizFormatOptions {
    border-top: 1px solid $abby;
    padding-top: 24px;
    margin-bottom: 44px;

    .quizFormatOption {
      display: flex;
      cursor: pointer;
      margin-bottom: 16px;

      > :last-child {
        margin-left: 12px;
      }
    }
  }

  .quizTerms {
    .quizTermsHeaderBar {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      :first-child {
        color: $spun-pearl;
        text-transform: uppercase;
      }

      img {
        width: 18px;
        cursor: pointer;
      }
    }
  }

  .editQuizTerm {
    border: 1px solid $abby;
    border-radius: 6px;
    background: $bright-gray;
    margin-bottom: 16px;

    .quizTermContainer {
      padding: 16px;
      border-bottom: 1px solid $abby;

      .questionMediaContainer {
        position: relative;
        height: 180px;
        max-width: 240px;
        border-radius: 6px;
        background: $tuna;

        .removeQuestionMediaButton {
          position: absolute;
          top: 6px;
          right: 6px;
          cursor: pointer;
          width: 20px;
          z-index: 1;
        }
      }

      .addQuestionMediaButton {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: fit-content;

        img {
          margin-right: 8px;
        }

        div {
          color: $spun-pearl;
        }
      }
    }

    .quizTermHeader {
      display: flex;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid $abby;
      justify-content: space-between;

      img {
        width: 13px;
        cursor: pointer;
        margin-right: 20px;
      }

      .order {
        font-size: 15px;
        color: $spun-pearl;
        margin-right: 16px;
      }
    }

    .quizTermAnswers {
      padding: 16px;

      .answersLabel {
        color: $athens-grey;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-bottom: 20px;
      }

      p {
        color: $spun-pearl;
        // margin-bottom: 0;
      }

      .answersSection {
        display: flex;
        padding-bottom: 10px;
        margin-bottom: 20px;
        max-width: 794px;
        overflow-x: auto;

        .answerTypes {
          min-width: 136px;
          width: 136px;

          .dropUpload {
            .answerType {
              background-color: $steel-gray;
            }

            .mediaGalleryLink {
              color: $spun-pearl !important;
            }
          }

          .answerType {
            display: flex;
            border: 1px dashed var(--ion-color-medium);
            color: $spun-pearl;
            font-size: 12px;
            border-radius: 6px;
            padding: 16px;
            position: relative;
            height: 68px;
            cursor: pointer;
            transition: background-color 0.25s ease;

            .fileUploader {
              position: absolute;
              top: 0;
              right: 0;
              left: 0;
              bottom: 0;
              margin-bottom: 0 !important;
              max-width: 136px;
              z-index: 1;
              cursor: pointer;

              .fileUploaderInput {
                display: none;
              }
            }

            .mediaGalleryLink {
              text-decoration: underline;
              color: var(--ion-color-tertiary);
              cursor: pointer;
              position: absolute;
              z-index: 2;
              right: 36px;
              left: 52px;
              top: 29px;
              transition: background-color 0.25s ease;
            }

            img {
              width: 16px;
              margin-right: 10px;
            }

            div {
              text-align: center;
            }

            &:first-child {
              margin-bottom: 8px;
            }
          }
        }

        $textAreaPadding: 24px;
        $answerBoxWidth: 185px;
        $answerBoxHeight: 144px;

        .answerBox {
          position: relative;
          margin-left: 16px;
          border-radius: 6px;
          background: $tuna;
          min-width: $answerBoxWidth;
          max-width: $answerBoxWidth;
          max-height: $answerBoxHeight;
          min-height: $answerBoxHeight;
          display: flex;
          justify-content: center;
          align-items: center;

          .closeButton {
            position: absolute;
            cursor: pointer;
            top: 8px;
            right: 8px;
            width: 20px;
            z-index: 1;
          }

          .answerTextArea {
            font-family: $quantico;
            border-radius: inherit;
            background: $abby;
            color: white;
            min-width: $answerBoxWidth - 2 * $textAreaPadding;
            max-width: $answerBoxWidth - 2 * $textAreaPadding;
            max-height: $answerBoxHeight - 2 * $textAreaPadding;
            min-height: $answerBoxHeight - 2 * $textAreaPadding;
            border: none;
            outline: none;
            font-size: 15px;
            text-align: center;
            padding: $textAreaPadding;
            overflow-y: scroll;
            overflow-x: hidden;
            transition: background-color 0.25s ease;

            &:focus {
              background: $mirage;
            }

            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
  }

  .addTermButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $bright-gray;
    border-radius: 6px;
    padding: 16px;
    cursor: pointer;

    &:hover {
      background: $abby;
    }

    img {
      position: relative;
      bottom: 1px;
      margin-right: 10px;
      width: 14px;
    }

    span {
      color: $spun-pearl;
    }
  }
}

.categoryEdit {
  &Line {
    display: flex;
  }

  &Selector {
    width: calc(100% - 136px - 16px);
  }

  &Button {
    width: 136px;
    margin-left: 16px;
  }
}

.QuizBuilder{
  &NameAndType {
    display: flex;
  
    &Type {
      min-width: 200px;
      margin-right: 16px;
    }
  
    &PublishToggleRow{
      display: flex;
      flex-direction: row;
      margin: 0 6px 0 16px;
      align-items: center;
      gap: 12px;
      height: 46px;
      width: 148px;
    }
  }

  &Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Column {
    display: flex;
    flex-direction: column;
  }

  &RowSpacingNormal {
    margin-top: 16px;
  }

  &RowSpacingLarge {
    margin-top: 24px;
  }


  &BackButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
  
    &>svg {
      fill: $white;
    }
  }
}







.mediaGallerySetupModal {
  width: 99%;
  min-width: 99% !important;
  min-height: 99% !important;
  padding: 0 !important;
  border-radius: 15px !important;
}