@import "../../../styles/variables";

.teamCard {
  position: relative;
  width: 100%;
  padding: 24px;
  text-align: center;
  background-color: $tuna;
  border-radius: 6px;

  &__ColorsBar,
  &__NameWrapper {
    margin-top: 16px;
  }

  &__LevelWrapper {
    margin-top: 8px;
  }

  &__LinkWrapper {
    margin-top: 24px;
  }

  &__ColorsBar {
    display: flex;
    justify-content: center;
  }

  &__LogoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 60px;
    height: 60px;

    svg,
    img {
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &__NameWrapper,
  &__LevelWrapper {
    @extend %quantico;

    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__NameWrapper {
    font-size: 24px;
    font-weight: 700;
  }

  &__LevelWrapper {
    font-size: 14px;
  }

  &__ColorsBar {
    display: flex;
    align-items: center;

    span {
      width: 34px;
      height: 8px;

      &:first-of-type {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-of-type {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
  }
}
