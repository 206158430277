@import "../../../styles/variables";

.playbookSidebar {
  .sidebarContainer {
    background-color: $card-bg;
    padding: 1rem 1rem 3rem;
    border-radius: 6px;
  }

  .headerBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.25rem 0 0.75rem;
    color: $spun-pearl;
    font-weight: bolder;
    font-size: 15px;

    img {
      width: 16.5px;
      cursor: pointer;
    }
  }

  .categoryFilter {
    display: flex;
    justify-content: space-between;
    color: var(--ion-color-medium);
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0 -1rem;

    &.selected {
      color: var(--ion-color-primary);
      background: $bright-gray;
    }
  }

  .schemeFilter {
    @extend .categoryFilter;
    padding-left: 2rem;
    word-break: break-word;
  }
}
