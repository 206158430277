@import "../../../../styles/variables";

.previewPhoneContainer {
    .previewPhoneScreen {
        display: flex;
        flex-direction: column;
        height: 317.31px;
        width: 178.4px;
        background-color: $bright-gray;
        color: $jumbo;
        font-size: x-small;
        justify-content: space-between;

        ::-webkit-scrollbar {
            display: none;
        }

        .previewPhoneHeader {
            display: flex;
            flex-direction: row;
            height: 26.6px;
            width: 100%;
            background-color: $steel-gray;
            color: white;
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        .previewPhoneSlides{
            position: relative;
            height: 100%;
            margin: 0;
            padding: 0;
            list-style: none;
            text-align: left;
            overflow: hidden;
            .previewPhoneSlide{
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                overflow-y: auto;
                transition: all 0.3s;
                &[class~="previous-slide"]{
                    right: calc(100% + 10px);
                }
                &[class~="current-slide"]{
                    left: 0;
                    right: 0;
                }
                &[class~="next-slide"]{
                    left: calc(100% + 10px);
                }
            }
            &[class~="animating-previous"]{
                [class~="previous-slide"]{
                    right: 0;
                }
                [class~="current-slide"]{
                    left: calc(100% + 10px);
                    right: inherit;
                }
            }
            &[class~="animating-next"]{
                [class~="current-slide"]{
                    right: calc(100% + 10px);
                    left: inherit;
                }
                [class~="next-slide"]{
                    left: 0px;
                }
            }

            .previewPhoneSlideTextElement{
                margin: 20px;
                font-weight: bold;
                color: $whisper;
            }

            .previewPhoneSlidePlayElement{
                .previewPhoneSlidePlayTitle {
                    color: $white;
                    margin: 20px;
                    font-size: 24px;
                }
            }

            .previewPhoneSlideQuiz{
                &SplashIcon{
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    padding-top: 40px;
                }
                &SplashTitle{
                    @extend %chakra-petch-bold-italic;

                    color: $white;
                    font-size: 28px;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                }
                &SplashInfo{
                    display: flex;
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    padding-bottom: 20px;
                }
                &Header{
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    background-color: $steel-gray;
                    margin: 2px 10px 2px 10px;
                    padding: 2px 4px 2px 4px;
                    border-radius: 4px;
                    &Name{
                        @extend %chakra-petch-bold-italic;

                        color: white;
                        overflow: hidden;
                        max-height: 32px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .previewPhoneSlideQuizButton{
                    align-items: center;
                    text-align: center;
                    justify-content: center;
                    margin: 2px 10px 2px 10px;
                }
            }

        }

        .previewPhoneFooter {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: $steel-gray;
            color: white;

            .Previous {
                padding-left: 8px;
                color: $abby;
                cursor: default;
                &[class~="Active"]{
                    color: white;
                    cursor: pointer;
                }
            }

            .Next {
                padding-right: 8px;
                color: $abby;
                cursor: default;
                &[class~="Active"]{
                    color: white;
                    cursor: pointer;
                }
            }
        }
    }
}

.previewPhoneSlideQuestion{
    background-color: var(--ion-color-secondary);
    height: 100%;

    &.multipleChoiceQuestion {
        .previewPhoneSlideQuestionCard {
            height: 82px;
            max-height: 82px;
        }
    }

    &Card{
        display: flex;
        position: relative;
        background-color: $mirage;
        border: 3px solid white;
        border-radius: 6px;
        margin: 0px 12px;
        height: 110px;
        max-height: 110px;
        color: white;
        align-items: center;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        &Split{
            display: grid;
            position: relative;
            grid-template-rows: 1fr 3fr;
            height: 68px;
            width: 140px;
            &Text{
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow-x: hidden;
            }
            &Image{
                object-fit: contain;
                overflow: hidden;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    &Prompt{
        position: static;
        padding-top: 20px;
        margin-top: -12px;
        padding-bottom: 20px;
        margin-bottom: -12px;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: $bright-gray;
        color: white;
        &Choose{
            @extend %chakra-petch-bold-italic;
            font-size: 12px;
            margin-bottom: 2px;
        }
        &Instruction{
            font-size: xx-small;
        }
    }
    &List{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 0;
        &Answer{
            display: flex;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            margin: 0px 3px;
            cursor: pointer;
            color: white;
            border: 1px solid $spun-pearl;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
        &Selected{
            display: flex;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            margin: 0px 3px;
            cursor: pointer;
            color: white;
            border: 1px solid white;
            align-items: center;
            text-align: center;
            justify-content: center;
            outline: $java solid 2px;
        }
    }
    &Submit{
        padding: 0px 8px;
    }
}
