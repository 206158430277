@import "../../styles/variables";

.contentLimitModal {
  .buttonRow {
    display: grid;
    grid-auto-flow: column;
    gap: 1.25rem;
    margin-top: 1.25rem;
    justify-content: flex-end;

    button {
      width: inherit;
    }
  }
}
