@import "../../../styles/variables";
@import "../../../styles/_variables";

// override default MuiSlider styles to match designs
.MuiSlider-root {
  color: var(--ion-color-tertiary);
}

span.MuiSlider-thumb {
  width: 22px;
  height: 22px;
  margin-top: -11px;
  margin-left: -11px;
}

span.MuiSlider-rail {
  height: 4px;
  position: relative;
  top: 3px;
  background: var(--ion-color-primary);
}

span.MuiSlider-mark {
  background: var(--ion-color-primary);
  height: 9px;
  bottom: 5px;
}

span.MuiSlider-thumb {
  &::after {
    top: unset;
    left: unset;
    right: unset;
    bottom: unset;
    background: $lightning-yellow;
    width: 22px;
    height: 22px;
  }
}

span.MuiSlider-markLabel {
  top: 30px;

  &.MuiSlider-markLabelActive {
    color: var(--ion-color-tertiary);
  }
}
