.duplicatePlayModal {
  .buttonRow {
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;

    button {
      &:first-of-type {
        margin-right: 12px;
      }
    }
  }

  .errorMessage {
    color: var(--ion-color-danger);
  }
}
