@import "../../styles/variables";

.onboardingModal {
  .stepContainer {
    @include media-max-width(sm) {
      min-height: calc(100vh - 96px); // 96px is height of "RunningProgressBar" component, which is fixed to the top of the screen on mobile screen sizes
      max-height: calc(100vh - 96px); // 96px is height of "RunningProgressBar" component, which is fixed to the top of the screen on mobile screen sizes
      overflow-y: auto;
    }

    &.fullHeight {
      @include media-max-width(sm) {
        min-height: 100vh;
        max-height: 100vh;
      }
    }

    .emptySpaceToFixScrollingBug {
      display: none; // hidden except on iOS, where it is used as a temporary hacky fix to a not yet understood scrolling bug
      height: 40vh;

      &.show {
        @include media-max-width(sm) {
          display: block;
        }
      }
    }
  }

  .onboardingStep {
    padding: 24px 36px;
    height: 100%;
    position: relative;
    display: grid;
    grid-auto-flow: row;
    justify-items: center;
    gap: 67px;

    @include media-max-width(sm) {
      padding: 18px 28px;
      gap: 32px;
    }

    .group {
      display: grid;
      height: fit-content;
      width: 100%;
      gap: 16px;
      justify-items: center;

      &.noGap {
        gap: 0;
      }

      .stateAndZip {
        display: flex;
        gap: 16px;
        width: 100%;
      }
    }
    
    .limitWidth {
      max-width: 400px;

      @include media-max-width(sm) {
        max-width: none;
      }
    }
  }

  .progressBar {
    display: none;

    @include media-max-width(sm) {
      display: flex;
    }
  }

  .hideOnMobile {
    display: inline;

    @include media-max-width(sm) {
      display: none;
    }
  }

  .hideOnDesktop {
    display: none;

    @include media-max-width(sm) {
      display: inline;
    }
  }

  .onboardingStep.finalStep {
    gap: 20px;
    padding: 36px 0 0 0;

    @include media-max-width(sm) {
      padding: 24px 0 0 0;
    }
  } 

  .header {
    font-size: 24px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;

    @include media-max-width(sm) {
      font-size: 21px;
      line-height: 31.4px;
    }
  }

  .subheader {
    @extend .header;
    font-size: 20px;
  }

  .bulletList {
    background: $trout;
    padding: 24px 16px 24px 40px;
    border-radius: 12px;

    @include media-max-width(sm) {
      padding: 18px 16px 18px 40px;
    }

    li {
      line-height: 30px;
      font-size: 17px;
    }
  }

  &.finalStepContainer {
    margin: -1rem;
    border-radius: 4px;
    
    @include media-max-width(sm) {
      margin: 0;
      background: $lightning-yellow;
      border-radius: 0;
    }
  }

  .finalStepInner {
    display: grid;
    grid-auto-flow: row;
    justify-items: center;
    gap: 24px;
    padding: 24px 18px;
    width: 100%;
    background: $lightning-yellow;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    @include media-max-width(sm) {
      background: transparent;
      padding: 12px 18px 20px;
      gap: 36px;
    }

    .header {
      color: $mirage;

      &.altHeader {
        color: white;
      }
    }

    .appScreenshots {
      // commenting out because reduced height is making the image appear blurry
      // @include media-max-width(sm) {
      //   max-height: 260px;
      // }
    }

    .appStoreLinks {
      display: flex;
      justify-content: center;
      width: 100%;

      :first-child {
        margin-right: 1rem;
      }

      @include media-max-width(sm) {
        justify-content: space-evenly;
      }

      img {
        height: 51px;
      }
    }
  }

  .backButton {
    display: none;
    border-color: $mirage;
    color: $mirage;
    min-width: 300px;

    @include media-max-width(sm) {
      display: block;
    }
  }

  .closeModalBtn {
    @extend %quantico;
    background: $abby;
    height: 44px;
    width: 50%;
    max-width: 300px;
    outline: none;
    border: none;
    cursor: pointer;
    color: $white;
    font-size: 14px;
    line-height: 22.4px;
    border-radius: 4px;

    @include media-max-width(sm) {
      display: none;
    }
  }

  .onboardingGraphicThumb {
    @include media-max-width(sm) {
      max-height: 120px;
    }
  }
}