@import "../../styles/variables";

.mobileMenu {
  display: none;

  @include media-max-width(sm) {
    display: flex;
  }

  padding: 24px 36px;
  background: white;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  align-items: center;
  z-index: 5;
  background: $steel-gray;
  flex-direction: column;

  > * {
    margin-bottom: 48px;
  }

  .header {
    font-weight: 700;
    line-height: 38.4px;
    font-size: 24px;
  }

  .helpText {
    font-size: 17px;
    line-height: 24px;
    text-align: center;
  }

  .bottomButtons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin-bottom: 12px;

      &:last-of-type {
        margin-top: 48px;
      }
    }
  }
}