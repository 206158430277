@import "../../styles/variables";

.playbookSettings {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: 0;
  left: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  background: var(--ion-color-secondary);
  z-index: 5;

  .header {
    display: flex;
    padding: 1.25rem;
    border-bottom: 1px solid $bright-gray;

    img {
      margin-right: 1.25rem;
      cursor: pointer;
    }

    font-size: 18px;
  }

  .mainContent {
    display: flex;
    padding: 1.5rem 2rem;
  }

  .sidebar {
    margin-right: 5rem;

    .tab {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 18px;
      margin-bottom: 0.25rem;
      white-space: nowrap;

      &.selected {
        background: var(--ion-color-tertiary);
        color: var(--ion-color-secondary-shade);
      }
    }
  }

  .formationsGroup {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-bottom: 4rem;

    .newFormationButton {
      &:hover {
        border-color: var(--ion-color-primary-shade);
      }

      cursor: pointer;
      padding: 0.5rem;
      width: 290px;
      min-height: 216px;
      border: 2px solid $new-formation-button-border;
      border-radius: 6px;
      color: var(--ion-color-medium);
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 18px;
        margin-bottom: 0.25rem;
      }
    }

    div.formation {
      pointer-events: none;
    }

    .formation {
      &:hover {
        border-color: var(--ion-color-primary-shade);
      }

      border: 1px solid transparent;
      box-sizing: unset;
      padding: 0.5rem;
      color: var(--ion-color-primary);
      background-color: $play-card-bg;
      text-transform: capitalize;
      width: 274px;

      .formationName {
        font-size: 17px;
        margin-top: 0.5rem;
      }
    }
  }
}

.tagsTab {
  .categorySection {
    margin-bottom: 2rem;
  }

  .inputRow {
    width: 24rem;
    margin-top: .75rem;
    display: flex;
    align-items: center;

    .button {
      margin-left: 0.25rem;
    }

    .plusIcon {
      transition: opacity .25s ease;
      opacity: 0;
      pointer-events: none;

      &.showing {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  .categoryName {
    text-transform: uppercase;
    font-size: 13px;
    color: var(--ion-color-medium);
  }
}

.tabContent {
  .tabHeader {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 1.25rem;
  }
}
