@import "../../styles/variables";

.title {
    @extend %chakra-petch-h4;

    color: white;
    margin-bottom: 10px;
    margin-top: 10px;
}

.chartCard {
    background-color: $tuna;
    // border: 1px solid $abby;
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;

    .cardTitle {
        @extend %chakra-petch-h4;

        color: white;
    }

    .chartContainer {
        flex-grow: 1;
        min-height: 0px;
    }

    .flexRow {
        display: flex;
        align-items: center;
    }

    .clickableCell {
        cursor: pointer;
        color: $lightning2;
    }

    .nonClickableCell {
        color: $lightning2;
    }

    .usreListImg {
        margin-right: 10px;
    }

    .playerName {
        text-transform: capitalize;
    }

    .playerPosition {
        background-color: $mirage;
        border-radius: 6px;
        color: $spun-pearl;
        padding: 3px;
        margin-left: 8px;
        font-size: 10px;
    }

    .dataNotFound {
        width: 100%;
        height: 100%;
        max-width: 100%;
        color: #ffffff;
        background-color: #303241;
        border-width: 1px;
        border-color: #424258;
        border-style: solid;
        border-radius: 6px;

    }

    .dataNotFoundMsg {
        justify-content: space-around !important;
        padding: 24px !important;
        display: flex;
    }


}