@import "../../../styles/variables";

$page-title-and-filters-height: 117px;

.modalBtnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;

  .normalBtn {
    border-color: white;
  }

  button {
    margin-left: 14px;
  }
}

.editPlaySetContainer {
  display: flex;
  flex-direction: column;

  .modalHeader {
    padding: 1rem;
    margin: 0;
    display: flex;
    justify-content: space-between;

    span.playSetTitle {
      display: inline-flex;
      justify-content: left;
      align-items: center;
      width: 100%;
      font-size: 16px;

      button {
        margin-left: 10px;
      }
    }

  }

  .modelEditedTitle {
    display: flex !important;
    align-items: center;
  }

  .closeIcon {
    float: right;
    padding: 0.2rem;
    cursor: pointer;
  }

  .checkIcon {
    float: right;
    padding: 0.2rem;
    cursor: pointer;
  }

  .playsListContainer {
    display: flex;
    flex-direction: column;
    height: 60vh !important;
    overflow-y: auto;
    overflow-x: hidden;
    margin-left: 15px;
  }

  .playTitle {
    color: var(--ion-color-primary);
    padding-top: 0.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 11px;
    display: flex;
    align-items: center;
  }

  .draftTitleColor {
    color: $jumbo;
  }

  .play {
    padding: 0.5rem;
    background: #353849;
    border-radius: 4px;
  }

  .item {
    position: relative;
    padding: 0.5rem;
    background: #353849;
    border-radius: 4px;
    width: 250px;
    height: 215px;
    margin: 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 2em;
    font-weight: bold;
  }

  .selected {
    border: 1px solid var(--ion-color-tertiary);
  }

  .thumbnailSelected {
    display: none;
    position: absolute;
    align-items: center;
    left: 0;
  }

  .thumbnailNotSelected {
    width: 250px;
    height: 215px;
    position: absolute;
    left: 0;
    align-items: center;
    background: transparent;
    display: flex;

    &:hover {
      border: 1px solid grey;
      border-radius: 4px;
     }
  }

  .thumbnailHover {
    width: 250px;
    height: 213px;
    position: absolute;
    left: 0;
    align-items: center;
    background: transparent;
    display: flex;
    border: 1px solid grey;
    border-radius: 4px;
  }

  .hoverOutline {
    display: flex;
    height: 65px;
    width: 16px;
    background: var(--ion-color-tertiary);
    position: absolute;
    margin-left: -8px;
    z-index: 9;
    left: 8px;
    border-radius: 0 4px 4px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 9px;

    span {
      color: black;
      display: flex;
      align-items: center;
      line-height: 5px;
      justify-content: center;
      font-size: 14px;
    }
  }

  .hideDragIndicator {
    display: none;
  }

  .dragIndicator {
    display: flex;
    height: 65px;
    width: 16px;
    left: 8px;
    background: var(--ion-color-tertiary);
    position: absolute;
    margin-left: -8px;
    z-index: 9;
    border-radius: 0 4px 4px 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 9px;

    span {
      color: black;
      display: flex;
      align-items: center;
      line-height: 5px;
      justify-content: center;
      font-size: 14px;
    }
  }

  .buttonGroup {
    display: flex;
    flex-direction: column;


    .playTitle {
      font-size: 15px;
    }

    .play {
      padding: 0.5rem;
      background: $bright-gray;
      border-radius: 4px;
    }

    .buttonGroup {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        margin: 4px
      }

    }



    .duplicatePlaysText {
      margin-bottom: 1rem;
      font-size: 17px;
      color: $nav-text;
    }

    .duplicatePlaysContainer {
      .duplicatePlay {
        display: flex;
        justify-content: space-between;

        button {
          margin-left: 1rem;
        }
      }
    }

    .loader {
      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 60%;
        background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
        background-size: 300% 300%;
        animation: cycle 1s ease infinite;
        border-radius: 4px;
      }
    }

    .tagsRow {
      padding-top: 0.25rem;
      display: flex;
      gap: 0.35rem;
      flex-wrap: nowrap;
      overflow: hidden;
      height: 22px;

      >:first-child {
        flex: 4;
      }

      >:nth-child(n+2) {
        flex: 1;
      }

      .tag {
        display: block;
        justify-content: center;
        align-items: center;
        height: 18px;
        padding: 0 6px;
        border-radius: 9px;
        background: $spun-pearl;
        color: $steel-gray;
        white-space: nowrap;
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
      }
    }
  }

  .modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 25px;
    background: $tuna;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    button {
      &:last-of-type {
        margin-right: 0;
      }
    }

    .btn {
      width: fit-content;
      margin-left: 15px;
      min-height: 30px;
      height: 30px;
      padding: 0 10px;
    }
  }
}

.gamifiedPlayTooltip {
  &[class~="light"] {
    font-size: 14px;
    background-color: white !important;
    color: black !important;
  }
  display: flex;
}

.gamifiedPlayArrow {
  &[class~="light"] {
    color: white;
  }
}

.gamifiedIconContainer{
  z-index: 1;
  right: 0;
  top:0;
  position: absolute;
  cursor: pointer;
}
