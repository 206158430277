@import "../../styles/variables";

.container {
  height: 323px;
  position: relative;

  @include media-max-width(sm) {
    height: 300px;
  }

  .innerContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    background: $mirage;
    padding-top: 24px;

    left: -80px;
    right: -80px;
    border-radius: 12px;

    @include media-max-width(lg) {
      left: -40px;
      right: -40px;
    }

    @include media-max-width(md) {
      left: -20px;
      right: -20px;
    }

    @include media-max-width(sm) {
      left: -40px;
      right: -40px;
      border-radius: 0;
    }
  }

  .containerEnd {
    margin-top: 14px;
    display: grid;
    justify-content: center;
  }

  :global {
    .swiper-pagination {
      position: relative;
      display: grid;
      grid-auto-columns: auto;
      grid-auto-flow: column;
      grid-gap: 4px;
      align-items: center;
      width: auto;
      top: initial;
      bottom: initial;
      left: initial;
      right: initial;
    }
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: $spun-pearl;
      transition: all .1s ease;
    }
    .swiper-pagination-bullet-active {
      background: $lightning-yellow;
      width: 13px;
      height: 13px;
    }
  }
}

.swiperSlide {
  background: $bright-gray;
  border-radius: 12px;
  height: 240px;
  width: calc(100% - 80px);
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 28px;

  .slideNumber {
    @extend %chakra-petch-bold-italic;
    font-size: 24px;
    color: $sunglow;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid $sunglow;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slideText {
    padding: 0 20px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 16px;
    line-height: 27px;

    a {
      text-decoration: underline;
    }

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .slideTextWithImage {
    @extend .slideText;
    padding: 12px 20px 15px;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
  }
}

