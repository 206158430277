@import "../../../../styles/variables";

@import "../../../../components/Modal/Modal.module.scss";

.quizImportModalWrapper {
    .quizImportModal {
        .modalHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.2rem;
            margin: 0;
            font-size: 20px;
          }

          .quizModalContent {
            @extend .verticalFlex !optional;
            display: grid;
            grid-template-columns: 1fr 4fr;
            grid-template-rows: 1fr;
            .sidebar {
              margin-right: 1rem;
              margin-left: 1rem;
              max-height: 80%;

              .tab {
                cursor: pointer;
                padding: 0.5rem 1rem;
                border-radius: 18px;
                margin-bottom: 0.25rem;
                white-space: nowrap;

                &.selected {
                  background: var(--ion-color-tertiary);
                  color: var(--ion-color-secondary-shade);
                }
              }
            }

            .quizImportTab {
              display: grid;
              grid-template-columns: 1fr;
              grid-template-rows: 1fr 8fr 1fr;
              @extend .verticalFlex !optional;
              overflow: scroll;
              height: 80vh;
              .toolbar {
                margin-top: -1.2%;
              }
              .quizImportWrapper {
                background-color: $roster-bg;
                margin: 0px 20px;
                .quizList {
                  padding: 0;
                  margin: 10px;
                  list-style-type: none;

                  .quizItem {
                    display: grid;
                    grid-template-columns: 2fr 2fr 1fr;
                    padding: 0 1rem;
                    height: 70px;
                    border-bottom: 3px solid var(--ion-color-secondary);
                    cursor: pointer;


                    .tagContainer {
                      display: flex;
                      justify-content: flex-start;
                      align-items: center;


                    .tag {
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      padding: 4px 8px;
                      border-radius: 20px;

                      flex-grow: 0;
                      margin: 0px 10px;
                      color:$tertiary-button-border;
                      background-color: $input-bg;

                    }
                  }

                    span,
                    button {
                      display: flex;
                      align-items: center;
                    }

                    span:last-child {
                      justify-content: flex-end;
                    }
                  }
                }
              }
            }

          }
    }
    &.open {
      .quizImportModal {
        display: grid;
      }
    }
}
.quizImportModalOverlay {
  opacity: 0 !important;
}

.loader {
  position: absolute;
  background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
  background-size: 300% 300%;
  animation: cycle 1s ease infinite;
}

.buttonGroup {
  display: inline-flex;
  vertical-align: middle;
  span {
    margin: 5px;
  }
}