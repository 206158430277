@import "../../styles/variables";

.playThumbnail {
  width: 100%;
  height: inherit;
  cursor: pointer;
  background: var(--ion-color-secondary);
  border: 2px solid $thumbnail-border;
  border-radius: 6px;
  overflow: hidden;

  label {
    display: block;
    line-height: 0;
  }

  input[type="checkbox"]~span {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:after {
      top: 1px;
      left: 1px;
    }
  }

  input~span {
    padding-left: 0;
  }
}

.loader {
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 60%;
    background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
    background-size: 300% 300%;
    animation: cycle 1s ease infinite;
    border-radius: 4px;
  }
}
