@import '../../styles/variables';

.avatar {
  position: relative;
  display: inline-block;
  background-color: transparent;
  vertical-align: bottom;

  .smallIconContainer {
    width: 36px;
    height: 36px;
  }

  .extraSmallIconContainer {
    width: 32px;
    height: 32px;
  }

  .regularIconContainer {
    width: 100px;
    height: 100px;
  }

  .iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;

    &.hasBorder {
      border: 2px solid $icon-border-color;
    }

    .image {
      width: 100%;
    }

    .backup {
      width: 50%;
    }
  }

  .hexagon,
  .smallHexagon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../resources/images/Polygon3.svg") no-repeat 100%;
    background-size: cover;
    bottom: 0;
    right: -4px;
    width: 40px;
    height: 46px;
    color: $rank-text;
    font-weight: 700;
    font-size: 18px;
  }

  .smallHexagon {
    width: 19px;
    height: 22px;
    font-size: 11px;
  }

  .circle {
    @extend %chakra-petch-bold-italic;

    position: absolute;
    bottom: 17px;
    right: 28px;
    background-size: cover;
    height: 20px;
    width: 20px;
    background-color: $tuna;
    border-radius: 50%;
    border: 1px solid $jumbo;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 950px) {
      height: 17px;
      width: 17px;
      font-size: 10px;
    }
  }
}
