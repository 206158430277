@import "../../styles/variables";

$edit-play-toolbar-width: 120px;

.editPlay {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  top: 0;
  left: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: $whisper;
  z-index: 5;

  .playEditor {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .stageContainer {
      position: relative;
      width: calc(100% - #{$edit-play-toolbar-width});
    }

    .stageAbsoluteWrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  button {
    cursor: pointer;
  }

  .buttonRow {
    background: $play-editor-bg;
    width: 100%;
    padding: 1rem 0 1.5rem;
  }

  .playThumbnails {
    display: flex;
    flex-wrap: wrap;

    .playThumbnail {
      position: relative;
      margin: 1rem 1rem 0 0;

      .deletePlay {
        position: absolute;
        top: 0;
        right: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $tuna;
        z-index: 1;
        opacity: 0.7;
        cursor: pointer;
        user-select: none;
        color: var(--ion-color-danger);
        font-weight: bold;
      }
    }
  }
}

.adminBrowse {
  margin-top: 50px;
}

.editMediaPlayPage {
  width: 100%;

  .mediaPlayImageContainer {
    padding-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      width: 60%; // > 1450

      @media (max-width: 1450px) {
        width: 65%; // between 1260 and 1450
      }

      @media (max-width: 1260px) {
        width: 75%; // between 1024 and 1260
      }
      
      @media (max-width: 1024px) {
        width: 85%; // between 800 and 1024
      }

      @media (max-width: 769px) {
        width: 95%; // 0 to 800
      }
    }
  }
}
