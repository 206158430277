@import "../../../styles/variables";

.mediaGalleryTags {
    display: block;

    .modalHeader {
        height: 50px;
        background-color: #282B3A;
        width: 35vw;
        padding: 0.5rem;
        padding-right: 1.5rem;
        padding-left: 1rem;
        margin: 0;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: inset 0px -1px 0px #484A59;
        border-top-left-radius: 6px !important;
        border-top-right-radius: 6px !important;
    }

    .modalBody {
        width: 35vw;
        background-color: #282B3A;
        padding: 0.5rem;
        padding-right: 1.5rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        padding-left: 1rem;
        margin: 0;
        max-height: 70vh;
        min-height: 70vh;
        overflow-y: auto;
        font-size: 20px;
        align-items: center;
        justify-content: space-between;
    }


    .closeIcon {
        svg {
            fill: $white !important;
        }
    }



    .footer {
        background-color: $tuna;
        height: 50px;
        box-shadow: inset 0px 1px 0px #484A59;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 1rem;
        border-bottom-left-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
        background-color: #303241;
    }

}