@import "../../../../styles/variables";

.newFormationModal {
  .buttonRow {
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;

    button {
      width: inherit;

      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  .errorMessage {
    color: var(--ion-color-danger);
  }

  label:not(:first-of-type) {
    margin-top: 2rem;
  }
}
