@import "../../styles/variables";

.qqGallery {
  position: relative;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    box-shadow: inset 0px -1px 0px $bright-gray;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 14px 0;
  }

  &Title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  &CloseButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    & > svg {
      fill: $white;
    }
  }

  &Tabs {
    display: flex;
    align-items: center;
    margin-top: 16px;
    box-shadow: inset 0px -1px 0px $bright-gray;
  }

  &Tab {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &TabButton {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 11px;
    font-family: $quantico;
    font-size: 14px;
    color: $spun-pearl;
    background: none;
    border: none;

    &::before {
      position: absolute;
      bottom: 0;
      width: 60px;
      height: 3px;
      background-color: transparent;
      border-radius: 2px 2px 0 0;
      content: "";
    }

    &:hover {
      cursor: pointer;
    }
  }

  &TabButtonActive {
    color: $sunglow;
    font-weight: bold;

    &::before {
      background-color: $sunglow;
    }
  }

  &Filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }

  &QuizzesWrapper {
    position: relative;
    width: 100%;
    height: 600px;
    padding-bottom: 16px;
  }

  &QuizzesScrollSpace {
    padding-right: 16px;
  }

  &SearchWrapper,
  &SortWrapper {
    width: 200px;
  }

  &PlaybookWrapper {
    position: relative;
    width: 100%;
    height: 500px;
    padding-right: 1px;
  }

  &QuizCardWrapper {
    margin-bottom: 8px;
  }

  &QuizCardWrapperSpacing {
    margin-right: 16px;
  }

  &QuestionCard {
    margin: 16px 0;
  }
}
