@import "../../styles/variables";

.spreadsheetUpload {
  position: relative;

  &__Header,
  &__Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  &__Header {
    padding: 16px 16px 0;
  }

  &__Footer {
    padding: 14px 24px;
    background-color: $tuna;
  }

  &__Body {
    flex: 1;
    padding: 12px 40px 64px;

    &Step2 {
      padding: 12px 40px 0;
    }
  }

  &__Step1 {
    &Title {
      @extend %small;

      text-align: center;
    }

    &Dropbox {
      width: 240px;
      margin: 24px auto 0;
    }

    &Download {
      margin-top: 64px;
      text-align: center;

      &Text {
        @extend %small;
      }

      &Button {
        margin-top: 16px;
      }
    }
  }

  &__Step2 {
    &Title {
      @extend %small;
    }

    &Data {
      height: 400px;
      margin-top: 24px;
      overflow-y: auto;
    }

    &Row {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }

      & > * {
        margin-right: 9px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &PhoneInputWrapper {
      width: 220px;
      min-width: 220px;
      max-width: 220px;
    }
  }

  &__Dropbox {
    width: 100%;

    &Upload {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 26px 0;
      color: $white;
      border: 2px dashed $jumbo;
      border-radius: 6px;
      background-color: transparent;

      &:hover {
        cursor: pointer;
      }

      & > * {
        &:first-of-type {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &Icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: $spun-pearl;
        }
      }

      &Text {
        @extend %small;

        margin-left: 16px;
      }
    }

    &Description {
      @extend %micro;

      margin-top: 8px;
      color: $spun-pearl;
      text-align: center;
    }

    &InputFile {
      display: none;
    }
  }
}
