form {
    .phoneInputContainer {
        margin-bottom: 1rem;
    }
}

.toggle {
    display: flex;
    flex-direction: row;

    .switch {
        margin: 5px 10px;
        margin-right: 14px;
    }

    .help-text {
        margin-bottom: 10px;
        span {
            font-size: 13px;
            color: #7E7E85;
        }
    }
}
