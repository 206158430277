@import "../../styles/variables";

.percentageBar {
  display: block;
  font-size: 12px;
  width: 180px;
  color: $spun-pearl;
  &Container {
    height: 14px;
    background-color: $trout;
    border-radius: 3px;
    margin-bottom: 6px;
  }
  &Fill {
    background-color: $dodger-blue;
    border-radius: 3px;
    height: 100%;
  }
}

.percentageBarSegmented{
  display: grid;
  width: 100%;
  &Segment{
    background-color: $jumbo;
    height: 14px;
    margin-right: 2px;
    overflow: hidden;
  }
  &First{
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &Last{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &Filled{
    background-color: $lightning2;
  }
}
