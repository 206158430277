@import "../../styles/variables";

$page-title-and-filters-height: 77px;

.playbook {
    height: 100%;

    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        width: 80%;

        .mainButtons {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            .printButton {
                span {
                    margin-right: 5px;
                }
                svg {
                    width: 20px;
                    height: 16px;
                }
            }
        }

        .createPlayButton {
            img {
                filter: invert(100%) brightness(100%);
            }
        }
    }

    .plays {
        display: grid;
        grid-template-columns: 200px 1fr;
        gap: 1.5rem;
        height: calc(100% - #{$page-title-and-filters-height});
    }

    .playsList {
        position: relative;
        width: 100%;
        height: calc(100% - 60px);
        padding-right: 1px;
        overflow-x: auto;

        div[class="virtuoso-grid-list"],
        .grid {
            display: grid;
            grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
            gap: 0 1rem;
            padding-right: 0.5rem;
        }

        div[class="virtuoso-grid-item"],
        .loader {
            margin-bottom: 1rem;
            border: 1px solid transparent;
            background-color: $play-card-bg;
            border-radius: 6px;

            &:hover {
                border-color: var(--ion-color-primary-shade);
            }
        }

        .play {
            display: flex;
            flex-direction: column;
            position: relative;
            padding: 0.5rem;
            color: var(--ion-color-primary);
            text-decoration: none;
            font-size: 17px;
            text-transform: capitalize;
            border-radius: 4px;
            border: 2px solid transparent;

            &.selected {
                border-color: var(--ion-color-tertiary);
            }

            .playTitle {
                display: block;
                color: var(--ion-color-primary);
                padding-top: 0.25rem;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .draftTitleColor {
                color: $jumbo;
            }

            &.loader {
                padding-bottom: 2.5rem;
            }

            
        }

        .noPlaybook {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 2.5rem 1rem 2rem;

            img {
                width: 15px;
                height: 15px;
                padding: 1rem;
                margin-bottom: 0.5rem;
                border-radius: 50%;
                border: 1px solid var(--ion-color-medium);
                filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(67deg) brightness(101%) contrast(104%);
            }

            button {
                color: $link-text;
                text-decoration: underline;
                margin: 0.3rem 0;
            }
        }

        .gamifiedPlay {
            margin-left: 1px;
            margin-right: 1px;
          }

        
          
          
    }

    .addPlaysLink {
        cursor: pointer;
    }

    .gamePlanPlaysToolbar {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
        height: 56px;
        padding: 8px 24px;
        background: var(--ion-color-primary);
        line-height: 160%;
        z-index: 1;

        .playsSelected {
            font-size: 17px;
            color: $plays-selected-text;
            margin-right: 48px;
        }

        .selectAllBtn {
            margin-right: 35px;
        }

        .selectButtons {
            color: $gamePlan-select-btn;
        }

        .submitButtonContainer {
            display: flex;
            flex-direction: row;

            .deleteBtn {
                margin-right: 16px;
            }

            .addBtn {
                margin-right: 16px;
            }

            .playSet {
                margin-right: 16px;
                width: 120px;
                cursor: pointer;

                &::after {
                    border-bottom: 0 !important;
                    border-top: 5px solid $mirage;
                }
            }

            .playSet input {
                color: $mirage !important;

                &::placeholder {
                    color: $mirage !important;
                    transition: color 0.15s !important;
                }
            }

            .playSet div {
                height: 35px !important;
                color: black !important;
                border: none !important;
                background: $lightning-yellow !important;
                background-clip: padding-box !important;
            }

            .playSet div ul {
                top: calc(100% - 125px) !important;
                width: 200px !important;
            }

        }
    }
}


.filterBar {
    display: flex;
    gap: 0.5rem;

    // TODO: find a better way to target the GamePlan filter SingleSelect
    >:first-child {
        height: 44px;
    }

    .selectedGamePlanLabel {
        display: flex;
        align-items: center;
        white-space: nowrap;
        background: $mirage;
        padding: 0 12px;
        border-radius: 4px;

        span {
            margin-right: 12px;
        }

        img {
            cursor: pointer;
        }
    }
}

.importPlaysModal {
    background-color: var(--ion-color-secondary) !important;
}

.editPlaySetModal {
    background-color: var(--ion-color-secondary) !important;
    width: 570px;
    padding: 0 !important;
}

.createPlaySetModal {
    background-color: var(--ion-color-secondary) !important;
    width: 500px;
    padding: 0 !important;
}


.createGamePlanModal {
    background-color: var(--ion-color-secondary) !important;
    width: 450px;
    padding: 24px;
}

.importPlays {
    position: relative;
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 1rem;

    .modalPlaysList {
        height: 65vh;
        overflow: hidden;

        div[class="virtuoso-grid-list"],
        .grid {
            display: grid;
            grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
            gap: 0 1rem;
            padding-bottom: 100px;
        }

        div[class="virtuoso-grid-item"],
        .loader {
            margin-bottom: 1rem;
            border: 1px solid transparent;
            background-color: $play-card-bg;
            border-radius: 6px;

            &:hover {
                border-color: var(--ion-color-primary-shade);
            }
        }

        .playCard {
            padding: 1rem;
            background: $card-bg;
            border-radius: 6px;
            border: 2px solid transparent;

            .playTitle {
                margin-top: 5px;
                font-size: 17px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    &ButtonWrapper {
        position: absolute;
        top: -50px;
        right: -2px;
    }
}

.duplicatePlaysText {
    margin-bottom: 1rem;
    font-size: 17px;
    color: $nav-text;
}

.duplicatePlaysContainer {
    .duplicatePlay {
        display: flex;
        justify-content: space-between;

        button {
            margin-left: 1rem;
        }
    }
}

.loader {
    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 60%;
        background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
        background-size: 300% 300%;
        animation: cycle 1s ease infinite;
        border-radius: 4px;
    }
}

.tagsRow {
    padding-top: 0.25rem;
    display: flex;
    gap: 0.35rem;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 30px;

    >:first-child {
        flex: 4;
    }

    >:nth-child(n + 2) {
        flex: 1;
    }

    .tag {
        display: block;
        justify-content: center;
        align-items: center;
        height: 18px;
        line-height: 18px;
        padding: 0 6px;
        border-radius: 9px;
        background: $spun-pearl;
        color: $steel-gray;
        white-space: nowrap;
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: fit-content;
    }
}

.gamifiedPlayTooltip {
    &[class~="light"] {
      font-size: 14px;
      background-color: white !important;
      color: black !important;
    }
    display: flex;
  }
  
.gamifiedPlayArrow {
    &[class~="light"] {
      color: white;
    }
  }

.gamifiedIconContainer{
    z-index: 1;
    right: 0;
    top:0;
    position: absolute;
    cursor: pointer;
}
