@import "../../../styles/variables";

.tagCategoryTab {
    .categorySection {
        margin-bottom: 2rem;
    }



    .expand {
        transition: all 1s ease-in;
        opacity: 1;
        height: 100%;
        visibility: visible;
        max-height: 9999px;
    }

    .collapse {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.6s ease-in-out;
    }


    .tagCategory {
        margin-left: 2rem;
        margin-bottom: 2rem;
        align-items: center;

        .button {
            margin-left: 0.25rem;
        }

        .plusIcon {
            transition: opacity .25s ease;
            opacity: 0;
            pointer-events: none;

            &.showing {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .inputRow {
        width: 100%;
        margin-top: .75rem;
        display: flex;
        align-items: center;

        .dragButton {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: transparent;

            &:hover {
                background-color: $trout;
            }

            &>svg {
                fill: $spun-pearl;
            }
        }

        .dragButton {
            width: 24px;
            height: 24px;

            &:hover {
                background-color: transparent;
                cursor: grab;
            }
        }

        .button {
            margin-left: 0.25rem;
        }

        .arrow {
            display: inline-block;
            margin-right: 0.8rem;
            margin-left: 0.8rem;
            width: 6px;
            height: 6px;
            border-top: 2px solid #ABABB7;
            border-right: 2px solid #ABABB7;
            cursor: pointer;
        }

        .arrowTop {
            transform: rotate(-45deg);
            transition: transform .5s ease-in-out !important;
        }

        .arrowRight {
            transform: rotate(-315deg);
            transition: transform .5s ease-in-out !important;
        }

        .arrowBottom {
            transform: rotate(-225deg);
            transition: transform .5s ease-in-out !important;
        }

        .arrowLeft {
            transform: rotate(-135deg);
            transition: transform .5s ease-in-out !important;
        }

        .plusIcon {
            transition: opacity .25s ease;
            opacity: 0;
            pointer-events: none;

            &.showing {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }



    .categoryName {
        text-transform: uppercase;
        font-size: 13px;
        color: var(--ion-color-medium);
    }
}


.tabContent {
    .tabHeader {
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bolder;
        margin-bottom: 1.25rem;
    }
}