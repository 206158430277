@import "../../styles/variables";

.currentLeaders {
  @extend %chakra-petch-italic;

  display: flex;
  justify-content: space-between;

  .playerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    @include media-max-width(xl) {
      align-items: flex-start;
    }

    .playerName {
      @extend %chakra-petch-italic-uppercase;

      color: $spun-pearl;
      font-size: 12px;
      margin-top: 6px;

      @include media-max-width(xl) {
        font-size: 9px;
      }
    }

    .playerScore {
      @extend %chakra-petch-h3;

      @include media-max-width(xl) {
        font-size: 11px;
        text-align: left;
      }
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      .playerScore {
        color: $malibu;
      }
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      .playerScore {
        color: $dodger-blue;
      }
    }

    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9),
    &:nth-child(10) {
      .playerScore {
        color: $cerulean;
      }
    }


  }
}

.cardSubtitle {
  @extend %chakra-petch-italic;

  color: $spun-pearl;
  font-size: 13px;
  margin-bottom: 20px;
  border-bottom: 1px solid $trout;
  padding-bottom: 10px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 15px;
}
