@import "../../styles/variables";

.errorDisplay {
    display:flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem;

    .icon {
        width: 3rem;
    }

    .link {
        color: #ffe42a;
        text-decoration: underline;
        cursor: pointer;
    }
    .typography {
        color: $draft-mode-button-text;
        text-align: center;
    }
}