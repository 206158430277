@import "../../../styles/variables";

.formationSettingsToolbar {
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 1rem;
  background: $play-editor-bg;

  .leftControls {
    display: flex;

    img {
      width: 20px;
      margin-right: 1rem;
      cursor: pointer;
    }
  }

  .rightControls {
    display: flex;
    gap: 1rem;
  }
}
