@import "../../styles/variables";

.radioContainer {
  cursor: pointer;

  .circle {
    background: white;
    width: 20px;
    height:20px;
    border-radius: 50%;;
    min-width: 20px;
  }

  .outerCircle{
    background: $lightning-yellow;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .middleCircle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--ion-color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .innerCircle{
    width: 10px;
    height: 10px;
    background: $lightning-yellow;
    border-radius: 50%;;
  }
}

