@import "../../../../styles/variables";

$slide-element-width: 340px;
$play-aspect-ratio: 5 / 3;

.InstallSlide {
  position: relative;
  width: 100%;
  border-radius: 6px;
  background-color: $bright-gray;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    box-shadow: inset 0px -1px 0px $trout;
  }

  &Body {
    margin: 16px 0;
    padding: 0 16px;
  }

  &ViewOnlyBody {
    margin: 16px 0;
    padding: 0 16px 16px 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &Footer {
    padding: 0 16px 16px;
  }

  &PlaysModal {
    width: 1200px !important;
    max-width: 1200px !important;
    min-width: auto !important;
    padding: 0 24px !important;
  }

  &HeaderLeftSide,
  &HeaderRightSide,
  &NarrationSwitchWrapper {
    display: flex;
    align-items: center;
  }

  &HeaderTitle {
    margin-left: 16px;
    color: $spun-pearl;
  }

  &DragButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: transparent;

    &:hover {
      background-color: $trout;
    }

    &>svg {
      fill: $spun-pearl;
    }
  }

  &DragButton {
    width: 24px;
    height: 24px;

    &:hover {
      background-color: transparent;
      cursor: grab;
    }
  }

  &NarrationSwitchWrapper {
    margin-right: 16px;
  }

  &NarrationSwitchLabel {
    margin-left: 8px;
  }

  &NarrationSwitchLabelDisabled {
    opacity: 0.2;
  }

  &AudioPlayerWrapper {
    margin-bottom: 16px;
  }

  &MediaSelectWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
    border: 2px dashed $abby;
    border-radius: 6px;
  }

  &MediaSelectButtonWrapper {
    position: relative;
    margin-right: 17px;

    &::after {
      position: absolute;
      top: 50%;
      right: -8px;
      transform: translateY(-50%);
      width: 1px;
      height: 62px;
      background-color: $abby;
      content: "";
    }

    &:last-of-type {
      margin-right: 0;

      &::after {
        display: none;
      }
    }
  }

  &MediaSelectButton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100px;
    height: 85px;
    margin-right: 17px;
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &MediaSelectButtonIcon {
    width: 24px;
    height: 24px;
  }

  &MediaSelectButtonText {
    margin-top: 10px;
    font-size: 12px;
    color: $jumbo;
  }

  &AddSectionButtonWrapper {
    position: relative;
    display: inline-flex;
  }

  &AddSectionButtonDropdown {
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    margin: 0;
    padding: 4px 0;
    border-radius: 6px;
    background-color: $mirage;
    list-style: none;
    z-index: 5;
  }

  &AddSectionButtonDropdownButton {
    display: flex;
    align-items: center;
    padding: 7px 16px;
    color: $spun-pearl;
    background-color: $mirage;
    border: none;

    &:hover {
      background-color: $trout;
      cursor: pointer;
    }
  }

  &AddSectionButtonDropdownButtonImage {
    width: 20px;
  }

  &AddSectionButtonDropdownButtonText {
    min-width: 98px;
    margin-left: 10px;
    font-size: 14px;
    text-align: left;
  }

  &UploadedMediaContainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &UploadedMediaWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    background-color: $mirage;
    border-radius: 6px;
    flex: 1;
  }

  &UploadedMedia,
  &UploadedText,
  &UploadedPlay {
    position: relative;
  }

  &UploadedText {
    width: 100%;
  }

  &UploadedMedia,
  &UploadedPlay {
    width: $slide-element-width;
  }

  &Controls {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }

  &ControlsButtonWrapper {
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &UploadedImage {
    display: flex;
    width: $slide-element-width;
    height: auto;
    border-radius: 6px;
  }

  &PlayCard {
    border: 2px solid $trout;
    border-radius: 6px;

    &ThumbnailWrapper {
      height: calc(#{$slide-element-width} / #{$play-aspect-ratio});

      &>div {
        border: none;
        border-radius: 0;
      }
    }

    &TitleWrapper {
      padding: 8px 10px;
      background-color: $mirage;
    }

    &Title {
      color: $spun-pearl;
    }
  }

  &QuizCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 16px;
  }

  &QuizCardTitle {
    display: flex;
    flex-direction: column;
    word-break: break-word;
  }

  &QuizCardTitleLine1 {
    margin-bottom: 4px;
    font-size: 11px;
    line-height: 1;
    color: $spun-pearl;
  }

  &QuizCardTitleLine2 {
    font-size: 17px;
    line-height: 1;
    color: $white;
  }

  &QuizCardButtons {
    display: flex;
    align-items: center;
  }

  &QuizCardButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin-right: 8px;
    background-color: transparent;
    border: none;
    border-radius: 4px;

    &:hover {
      background-color: $trout;
      cursor: pointer;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &>svg {
      fill: $spun-pearl;
    }
  }

  &QuestionCard {
    position: relative;
    padding-bottom: 16px;
  }

  &QuestionCardPreTitle {
    margin-bottom: 4px;
    font-size: 11px;
    color: $spun-pearl;
    text-transform: uppercase;
  }

  &QuestionCardTitleWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &QuestionCardTitle {
    display: flex;
    align-items: flex-start;

    span {
      flex: 1;
      font-size: 17px;
      line-height: 1.6;
      color: $white;
      word-break: break-word;
    }
  }

  &QuestionCardTitleImage {
    width: 170px;
    height: 100px;
    margin-right: 16px;
  }

  &QuestionCardButtonsWrapper {
    display: flex;
    align-items: center;

    button {
      margin-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &AnswersWrapper {
    margin-top: 16px;
  }

  &QuestionCardItem {
    margin-bottom: 8px;
    color: $spun-pearl;
    border: 1px solid $jumbo;
    border-radius: 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &QuestionCardItemCorrect {
    color: $jade;
    border-color: $jade;
  }

  &QuestionCardItemText {
    padding: 7px 16px;
    word-break: break-word;
  }

  &QuestionCardItemImage {
    padding: 8px;
  }

  &QuestionCardItemThumbnailWrapper {
    width: 153px;
    height: 90px;
    border-radius: 6px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 0;
    }
  }



}

.mediaGallerySetupModal {
  width: 99%;
  min-width: 99% !important;
  min-height: 99% !important;
  padding: 0 !important;
  border-radius: 15px !important;
}