@import "../../styles/variables";

.positionSelectorRow {
  display: flex;
  margin-bottom: 16px;

  .positionType {
    color: $spun-pearl;
    width: 130px;
  }

  .toggle {
    border: 2px solid $jumbo;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    height: 32px;
    border-radius: 16px;
    margin-right: 16px;
    cursor: pointer;

    &.selected {
      border-color: $lime;
    }

    &.pillToggle {
      padding: 0px 16px;
    }

    &.circleToggle {
      width: 32px;
    }
  }
}
