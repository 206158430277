@import "../../styles/variables";

.positionsBlock {
  font-size: 12px;
  color: $whisper;
  text-transform: uppercase;
  margin-top: 6px;
  margin-bottom:-6px;
  width:fit-content;
  max-width: 420px;
  overflow-wrap: break-word;
  &[class~="dark"]{
    color: $jumbo;
    & > span {
      background-color: $tuna;
    }
  }
  &[class~="transparent"]{
    font-size: revert;
    color: revert;
    margin: revert;
    padding: revert;
    & > span {
      padding: revert;
      margin: revert;
      background-color: transparent;
    }
  }
  & > span {
    display: inline-block;
    background-color: $trout;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 2px 6px;
    border-radius: 4px;
    white-space: nowrap;
    &:last-child {
      margin-right: 0;
    }
  }
}
