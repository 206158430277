@import "../../../styles/variables";

.teamPlayers {
  position: relative;

  &__InviteCode {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    padding: 10px 26px;
    background-color: $trout;
    border-radius: 6px;

    &LeftSide {
      display: flex;
      align-items: center;

      & > * {
        margin-right: 24px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    &Copy {
      @extend %small;

      display: flex;
      flex-direction: column;
      width: 506px;
      color: $spun-pearl;

      & > * {
        margin-bottom: 4px;

        &:first-of-type {
          color: $white;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__PlayersList {
    &Row {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      & > * {
        margin-right: 10px;

        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 57px;
          min-width: 57px;
          max-width: 57px;
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__InviteWrapper {
    margin-bottom: 16px;
  }

  &__PlayersList {
    max-height: 350px;
    overflow-y: auto;
  }
}
