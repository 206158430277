// Color Name Generator: https://chir.ag/projects/name-that-color/

// height variables

#root {
  // Scss variables which we gonna assign using
  // useState in reactJS
  $app-trial-banner-height: 0;
}
// $app-header-height: calc(77px + #{$app-trial-banner-height});
$app-header-height: calc(77px + var(--app-trial-banner-height));


// Colors
$white: #fff;
$black: #000;
$mirage: #151724;
$ebony-clay: #252737;
$tuna: #303241;
$bright-gray: #353849;
$abby: #424258;
$trout: #484A59;
$alto: #dadada;
$silver-chalice: #ababab;
$spun-pearl: #ababb7;
$athens-grey: #e7e7ed;
$whisper: #f2f2f8;


$persimmon: #ff584e;
$burnt-sienna: #eb5953;
$flush-orange: #fb7800;
$vivid-tangerine: #ff8883;
$razzle-dazzle-rose: #ff38c7;
$lightning1: #ffbc17;
$lightning1-light: rgba(255, 188, 23, 0.25);
$lightning2-light: rgba(255, 234, 45, 0.35);
$lightning2: #ffea2d;
$bright-sun: #ffcc3d;
$sunglow: #ffe42a;
$candlelight: #FFD523;
$cerulean: #009ee1;
$dodger-blue: #00b2ff;
$dodger-blue-2: #2867f4;
$malibu: #7ad7ff;
$reef: #c8ffaf;
$java: #23aac1;
$bubblegum: #35d3ed;
$jade: #00a863;
$steel-gray: #282B3A;
$jumbo: #7E7E85;
$lime: #6DCC30;
$dark-red: #A10202;
$punch: #da3731;

$lightning-yellow: linear-gradient(140.67deg,
  $lightning1 12.93%,
  $lightning2 77.48%);

$lightning-yellow-hover: linear-gradient(140.67deg,
  $lightning2 12.93%,
  $lightning1 77.48%);

// Semantic colors
$nav-text: $spun-pearl;
$disabled-text: $spun-pearl;
$add-link-text: $spun-pearl;
$login-error-text: $spun-pearl;
$delete-modal-text: $spun-pearl;
$dark-text: $mirage;
$link-text: $dodger-blue;
$toggle-label-text: $spun-pearl;
$toggle-button-bg: $mirage;
$toggle-button-selected-text: $mirage;

$border: $bright-gray;
$focus-border: $malibu;
$thumbnail-border: $abby;
$icon-border-color: $abby;
$avatar-file-upload-border: $abby;
$tertiary-button-border: $spun-pearl;
$new-formation-button-border: $trout;

$select-arrow: $spun-pearl;
$select-focus-bg: $abby;

$card-bg: $tuna;
$signup-bg: $bright-gray;
$play-card-bg: $bright-gray;
$roster-bg: $bright-gray;
$roster-bg-hover: $trout;
$play-settings-bg: $bright-gray;
$input-bg: $mirage;
$dark-bg: $mirage;
$modal-overlay-bg: linear-gradient(0deg,
  rgba(64, 63, 76, 0.9),
  rgba(64, 63, 76, 0.9));
$modal-bg: $tuna;
$toast-bg: $whisper;
$play-editor-bg: $whisper;
$input-disabled-bg: $trout;

$logo-file-upload-bg: $tuna;

$success-toast: $jade;
$error-toast: $burnt-sienna;
$info-toast: $dodger-blue;

$loader-light: $abby;

$selected-text-color: $whisper;
$selected-bg: $bright-gray;
$filter-option-bg: $cerulean;

$dark-player-detail-text: $mirage;
$rank-text: $bright-gray;
$player-modal-background: $athens-grey;
$ranking-chart-bg: $lightning-yellow;
$header-position-text: $spun-pearl;
$readiness-bg: $trout;
$add-players-text: $spun-pearl;
$draft-mode-button-text: $jumbo;
$draft-mode-text: $spun-pearl;
$draft-mode-hover: $lightning1-light radial-gradient(circle, transparent 0%, $lightning1-light 1%) center/15000%;
$draft-mode-selected: $lightning2-light;
$draft-mode-bg: $steel-gray;

$gamePlan-select-btn: $cerulean;
$plays-selected-text: $mirage;
$add-to-gamePlan-btn-bg: $steel-gray;
$create-gamePlan-subtext: $spun-pearl;
$license-suspended-subtext: $spun-pearl;

// Font Families
$quantico: 'Quantico',
sans-serif;
$chakra-petch: 'Chakra Petch',
sans-serif;
$meie-script: 'Meie Script',
cursive;

// Typography
%quantico {
  font-family: $quantico;
  letter-spacing: 0.02em;
}

%meie-script {
  font-family: $meie-script;
}

%body {
  font-family: $quantico;
  font-size: 17px;
  line-height: 27px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

%body-strong {
  font-family: $quantico;
  font-size: 17px;
  line-height: 27px;
  font-weight: 700;
  letter-spacing: 0.02em;
}

%small {
  font-family: $quantico;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

%small-strong {
  font-family: $quantico;
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.02em;
}

%caption {
  font-family: $quantico;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

%micro {
  font-family: $quantico;
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: 0.02em;
}

%micro-strong {
  font-family: $quantico;
  font-size: 11px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: 0.02em;
}

%quantico-h3 {
  @extend %quantico;

  font-size: 24px;
  line-height: 38px;
  font-weight: 700;
}

%quantico-h4 {
  @extend %quantico;

  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
}

%chakra-petch-italic {
  font-family: $chakra-petch;
  font-style: italic;
}

%chakra-petch-italic-uppercase {
  font-family: $chakra-petch;
  font-style: italic;
  text-transform: uppercase;
}

%chakra-petch-bold-italic {
  font-family: $chakra-petch;
  font-weight: 700;
  font-style: italic;
}

%chakra-petch-bold-italic-uppercase {
  font-family: $chakra-petch;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
}

%chakra-petch-h1 {
  font-family: $chakra-petch;
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
}

%chakra-petch-h2 {
  font-family: $chakra-petch;
  font-size: 36px;
  line-height: 43px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
}

%chakra-petch-h3 {
  font-family: $chakra-petch;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
}

%chakra-petch-h4 {
  font-family: $chakra-petch;
  font-size: 18px;
  line-height: 25px;
  font-weight: 700;
  font-style: italic;
  text-transform: uppercase;
}


// Loading animation
@keyframes cycle {
  0% {
    background-position: 0 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

// Grid Breakpoints
// $grid-breakpoints: (
//         xs: 0,
//         sm: 576px,
//         md: 768px,
//         lg: 992px,
//         xl: 1200px,
// );
$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
);

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min !=0, $min, null);
}

// Maximum breakpoint width.
// The maximum value is reduced by 0.02px to work around the limitations of
// `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(md, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@mixin media-min-width($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  }

  @else {
    @content;
  }
}

@mixin media-max-width($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);

  @if $max {
    @media (max-width: $max) {
      @content;
    }
  }

  @else {
    @content;
  }
}