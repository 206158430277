@import "../../styles/variables";

.label {
  --listClip: 0;
  --listOpacity: 0;
  --listPadding: 0;
  --pointerEvents: none;
  --speed: 0.2s;

  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: 320px;
  // TODO: remove this margin
  margin-bottom: 1rem;

  .selectLabel {
    text-transform: uppercase;
    font-size: 13px;
    color: $spun-pearl;
    margin-bottom: 8px;
    letter-spacing: 0.5px;
  }

  &.alwaysOpen {
    display: block;

    &::after {
      content: none;
    }
  }

  .selectedValue {
    @extend %quantico;

    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 60px);
    outline: none;
    background: transparent;
    border: none;
    font-size: var(--selectFontSize);
    color: var(--ion-color-primary);
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder {
      color: var(--placeholderText);
      transition: color 0.15s;
    }
  }

  input.hide {
    display: none;
  }

  .numberSelected {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    padding: 0 2px 0 3px;
    left: 9px;
    background: $filter-option-bg;
    min-width: 16px;
    height: 16px;
    border-radius: 100px;
    font-size: 11px;
    box-sizing: border-box;
  }

  .showNumberSelected {
    padding-left: 16px;
    transition: padding-left 0.15s;
  }

  .select {
    box-sizing: border-box;
    width: 100%;
    max-width: 320px;
    height: var(--selectHeight);
    padding: 0;
    border: none;
    border-radius: 6px;
    outline: none;
    background: var(--selectBg);
    color: var(--selectText);
    transition: background 0.15s;

    ::-webkit-scrollbar {
      display: none;
    }

    &.hide {
      height: inherit;
      padding: 0;
    }

    .list {
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }

      &.alwaysOpen {
        position: relative;
        z-index: 2;
      }

      position: absolute;
      padding: var(--listPadding);
      margin: 0;
      left: 0;
      top: calc(100% + 5px);
      max-width: 320px;
      max-height: var(--listClip);
      overflow: scroll;
      list-style-type: none;
      opacity: var(--listOpacity);
      pointer-events: var(--pointerEvents);
      background: $input-bg;
      border-radius: 4px;
      box-sizing: border-box;
      color: var(--ion-color-medium);
      z-index: 3;
      transition: opacity var(--speed), max-height var(--speed),
      padding var(--speed);
      transition-timing-function: cubic-bezier(0.5, 0, 0, 1);

      &:hover {
        .option.selected {
          color: $selected-text-color;
          transition-delay: 0.05s;
        }
      }

      .option {
        padding: 0;
        background: transparent;
        transition: background 0.15s, color 0.15s;
        max-height: 68px !important;
        font-size: var(--selectFontSize);

        &:hover,
        &:focus,
        &.selected {
          color: $selected-text-color;
          background: $selected-bg;
        }

        label {
          display: flex;
          align-items: center;
          max-height: 68px !important;
          padding: 7px 1rem;
          margin: 0;
          cursor: pointer;
        }
      }
    }

    .placeholder {
      color: var(--placeholderText);
    }
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    right: 1rem;
    width: 1px;
    border-top: 5px solid $select-arrow;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
  }

  &.dark {
    --selectBg: #{$input-bg};
    --selectText: var(--ion-color-primary);
    --placeholderText: var(--ion-color-medium);
  }

  &.listOpen {
    --listClip: 200px;
    --listOpacity: 1;
    --listPadding: 0.5rem 0;
    --selectBg: #{$select-focus-bg};
    --selectText: var(--ion-color-primary);
    --placeholderText: var(--ion-color-primary);
    --pointerEvents: auto;
    --speed: 0.5s;
  }

  //  Sizes
  &.large {
    --selectHeight: 56px;
    --selectFontSize: 17px;
  }

  &.medium {
    --selectHeight: 44px;
    --selectFontSize: 14px;
  }

  &.small {
    --selectHeight: 36px;
    --selectFontSize: 14px;
  }

  &.x-small {
    --selectHeight: 30px;
    --selectFontSize: 12px;
  }
}
