@import "../../styles/variables";

.modalWrapper {
  --modalBg: #{$modal-bg};
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9;

  &.open {
    .modal {
      display: block;
      animation: rise 0.5s cubic-bezier(.39,0,0,1) forwards;
    }
  }

  &.closing {
    .modal {
      animation: fall 0.25s cubic-bezier(1,0,.61,1) forwards;
    }
  }

  &.second {
    z-index: 12;
  }

  .modal {
    display: none;
    min-width: 64px;
    max-width: 90%;
    padding: 1rem;
    pointer-events: all;
    background-color: var(--modalBg);
    border-radius: 4px;

    .modalHeader {
      margin-bottom: 1.5rem;
      font-size: 17px;
    }
  }

  .small {
    max-width: 355px;
  }

  .medium {
    max-width: 66.67%;
  }

  .large {
    min-width: 90%;
  }

  .responsive {
    min-width: 66.67%;
    max-width: 66.67%;

    // responsive modals should go fullscreen on mobile
    @include media-max-width(sm) {
      min-width: 100%;
      max-width: 100%;
      height: 100%;
      padding: 0;
    }
  }
}

@keyframes rise {
  from {
    margin-bottom: -80px;
    opacity: 0;
  }
  to {
    margin-bottom: 0;
    opacity: 1;
  }
}

@keyframes fall {
  from {
    margin-bottom: 0;
    opacity: 1;
  }
  to {
    margin-bottom: -80px;
    opacity: 0;
  }
}

.modalOverlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background: $modal-overlay-bg;
  pointer-events: none;
  cursor: pointer;
  transition: opacity 0.3s ease-out;
  z-index: 8;

  &.open {
    opacity: 1;
    pointer-events: all;
  }

  &.closing {
    opacity: 0;
    pointer-events: all;
    transition-delay: 0.1s;
  }

  &.second {
    z-index: 11;
  }
}
