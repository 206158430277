@import "../../styles/variables";

.colorPicker {
  position: relative;

  &__Button {
    @extend %caption;

    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    color: $spun-pearl;
    text-transform: uppercase;
    background-color: $mirage;
    border: none;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }

    &::after {
      position: absolute;
      display: block;
      top: 50%;
      right: 1rem;
      transform: translateY(-50%);
      width: 1px;
      border-top: 5px solid $spun-pearl;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: "";
    }
  }

  &__SketchPicker {
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
    z-index: 1;
  }

  &__Color {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 50%;
  }
}
