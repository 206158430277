@import "../../styles/variables";

.signup {
  display: grid;
  grid-template-columns: 380px 1fr;
  grid-template-areas: 'content visuals';
  width: 100%;
  height: 100%;

  @include media-max-width(md) {
    display: block;
  }
}

.login {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .content {
    width: 100%;
    padding: 1rem;
    align-items: center;
    justify-content: center;

    .logo {
      position: absolute;
      top: 2rem;
      left: 2rem;
    }

    form {
      width: 100%;
      max-width: 360px;
      padding: 2rem;
      border-radius: 12px;
      background: $card-bg;
      box-sizing: border-box;

      .title {
        text-align: center;
        margin: 1rem 0 2rem;
      }

      .body {
        font-size: 17px;
        margin-top: -1rem;
        text-align: center;
      }
    }

    .otherCta {
      position: absolute;
      bottom: 2rem;
    }
  }
  .helpButton {
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 30px;
    margin-top: 30px;
    color: $jumbo;
    border-color: $lightning1;
    width: 71px;
    &:hover {
      color: $mirage;
    }
  }
}

.content {
  grid-area: 'content';
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $signup-bg;
  padding: 2rem;

  @include media-max-width(md) {
    height: calc(100% - 4rem);
  }

  .helpBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    .otherCta {
      font-size: 17px;
      text-align: center;
      color: var(--ion-color-medium);
  
      a {
        color: var(--ion-color-primary);
      }
    }
  
    .helpButton {
      color: $jumbo;
      border-color: $lightning1;
      width: 71px;
      &:hover {
        color: $mirage;
      }
    }
  }
}

.visuals {
  grid-area: 'visuals';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-max-width(md) {
    display: none;
  }

  .hero {
    width: 70%;
    max-width: 720px;
  }

  .heroTitle {
    @extend %chakra-petch-h3;

    width: 350px;
    margin-top: 3rem;
    margin-bottom: 0;
    text-align: center;
  }

  .heroText {
    width: 330px;
    color: $nav-text;
    text-align: center;
    font-size: 12px;
  }
}

.logo {
  width: 160px;
}

.form {
  padding: 0 0.5rem;

  .title {
    color: var(--ion-color-primary);
    font-size: 18px;
    margin-bottom: 1rem;
    font-weight: 700;

    @include media-max-width(md) {
      text-align: center;
    }
  }

  button {
    width: 100%;
    padding: 0.5rem;
  }

  .passwordContainer {
    position: relative;
    display: flex;
    align-items: center;
  }

  .showPassword {
    position: absolute;
    right: 1rem;
    margin-top: -6px;
    z-index: 2;
    font-size: 12px;
    cursor: pointer;
    img {
      width: 20px;
    }
  }

  .forgot {
    display: flex;
    justify-content: flex-end;
    margin-top: -12px;
    margin-bottom: 6px;
    z-index: 2;
    font-size: 12px;
  }
  
  .formFooter {
    display: flex;
    margin-top: 1rem;

    > button,
    > span {
      width: calc(50% - 0.5rem);
    }

    > button {
      margin-left: 1rem;
    }
  }
}

.errorModal {
  --modalBg: var(--ion-color-secondary);
  text-align: center;
  font-size: 17px;

  p {
    color: $login-error-text;
  }
}

.success {
  $color: rgba(#ffe129, 0.99); // #ffe129 is --ion-color-secondary. Can't use vars in svgs

  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 225px;
  text-align: center;
  font-size: 17px;

  .check {
    display: block;
    padding: 1rem;
    margin: 0 auto 1rem;
    width: 44px;
    height: 44px;
    border: 1px solid var(--ion-color-tertiary);
    border-radius: 50%;
    background: url("data:image/svg+xml,<svg width='17' height='15' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M3.86333 7.58336L1.08333 4.80336L0.136665 5.74336L3.86333 9.47003L11.8633 1.47003L10.9233 0.530029L3.86333 7.58336Z' fill='#{$color}'/></svg>") no-repeat 50% 50%;
  }
}

.inputWrapper {
  position: relative;
}

.passwordMatchError{
  position: absolute;
  bottom: -4px;
  left: 0;
  font-size: 11px;
  color: var(--ion-color-danger);
}
