@import "../../../../../styles/variables";
@import "../../../../../components/Modal/Modal.module.scss";

$answerBoxWidth: 200px;
$answerBoxHeight: 150px;

.SelectedQuiz {
  position: relative;
}

.header {
  padding: 1.5rem 1.5rem 1.5rem 2.5rem;
  border-bottom: 1px solid $bright-gray;


  .goBackArea {
    display: flex;
    font-size: 24px;
    align-items: center;
    cursor: pointer;

    img {
      width: 1.5rem;
      margin-right: 2rem;

    }
  }

  &QuizPreview {
    margin: 0;
    padding: 16px 8px;

    span {
      font-size: 17px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 16px !important;
        padding: 8px;
      }
    }
  }
}

.quizModalContent {
  @extend .verticalFlex !optional;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .scrollableSection {
    display: grid;
    grid-template-columns: 1fr;
    overflow: auto;
    max-width: 100%;

    .headDescriptionWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &QuizPreview {
        padding: 1.5rem 0;
      }
    }

    .headDescription {
      padding: 1rem 1rem 1rem 3rem;

      &QuizPreview {
        padding: 0;
      }
    }

    .description {
      color: $spun-pearl;
    }

    .tagContainer {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tag {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        border-radius: 20px;
        flex-grow: 0;
        color: $tertiary-button-border;
        background-color: $input-bg;

      }
    }

    .selectedQuizWrapper {
      background-color: $bright-gray;
      margin: 1rem 3rem 0.2rem;
      padding-bottom: 8px;
      overflow: auto;
      border-radius: 8px;

      &Preview {
        margin: 0;
        padding: 0;
      }

      .selectedQuizList {
        list-style: none;
        list-style-position: outside;
        padding-left: 1rem;
        margin: 1rem;
        counter-reset: circle-counter;
        min-height: 350px;
        max-height: 350px;
        width: auto;


        .listItem {
          counter-increment: circle-counter;
          padding-bottom: 0.75rem;
          padding-top: 0.75rem;
          height: 160px;
          box-shadow: 0px 1px 0px #484A59;
          display: grid;
          width: auto;

          &:before {
            content: counter(circle-counter);
            border: 1px solid $spun-pearl;
            width: 1rem *1.5;
            height: 1rem *1.5;
            border-radius: 50%;
            display: inline-block;
            font-size: 1rem*.75;
            line-height: 1rem*1.5;
            color: $spun-pearl;
            text-align: center;
            margin-right: 2rem*.5;
            position: relative;
            background: $tuna;
          }

          &:after {
            box-shadow: 0px 1px 0px #484A59;
          }

          &:last-child {
            margin-bottom: 0;
          }

          .listItem {
            margin-left: 1rem*.25;

          }

          .questionName {
            margin-top: 5px;
          }

          .answerBox {
            position: relative;
            border-radius: 6px;
            background: $abby;
            width: $answerBoxWidth;
            height: $answerBoxHeight;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            margin: 12px;
            margin-top: 0.8px;

            img {
              // min-width: 160px;
              width: auto;
              height: auto;
            }

            .mediaBox {
              border-radius: 6px;
              background: $abby;
              width: 98%;
              height: 100%;
            }
          }

          .emptyBox {
            border-radius: 6px;
            background: $tuna;
            min-width: $answerBoxWidth;
            width: $answerBoxWidth;
            max-width: $answerBoxWidth;
            min-height: $answerBoxHeight;
            max-height: $answerBoxHeight;
            margin: 12px;
            margin-top: 0.8px;
          }
        }
      }
    }

  }

  .selectedQuizTabFooter {
    display: flex;
    justify-content: flex-end;
    padding: 0.75rem;
    background: $card-bg;
    z-index: 10000;

    button {
      flex: 0;
    }
  }

  &Preview {
    padding: 0 8px;
  }
}
