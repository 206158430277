@import "../../../../styles/variables";

.quizCategoriesModal {
  position: relative;
  width: fit-content;

  .closeModalButton {
    position: absolute;
    right: 4px;
    top: -4px;

    svg {
      fill: $white !important;
    }
  }
}
