@import "../../../../styles/variables";
@import "../../../../components/Modal/Modal.module.scss";

$app-header-height: 93px; // 77px app header height + 16px padding
$page-title-and-filters-height: 117px;

.toolbar {
  .filterBar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    height: 44px;

    .select {
      display: flex;
      align-items: center;
      width: 500px;

      .multi {
        padding-top: 16px;
        display: flex;
        align-items: center;
        width: 500px;
      }
    }
  }

  .searchHeight {
    height: 44px;
    min-width: 250px;
    align-self: flex-start;
  }
}

.modalHeader {
  padding: 1.2rem;
  margin: 0;
  font-size: 20px;

  .closeIcon {
    float: right;
    padding: 0.2rem;
    cursor: pointer;
  }
}

.importInstalls {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 6fr;
  column-gap: 1rem;

  .modalSidebar {
    width: 15rem;
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;

    .tab {
      cursor: pointer;
      padding: 0.5rem 1rem;
      border-radius: 18px;
      margin-bottom: 0.25rem;
      width: 15rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.selected {
        background: var(--ion-color-tertiary);
        color: var(--ion-color-secondary-shade);
      }
    }
  }

  .installTableHeader {
    display: grid;
    grid-template-columns: minmax(300px, 2fr) minmax(200px, 1fr) minmax(200px, 1fr) 160px;
    height: 15px;
  }

  .emptyList {
    position: relative;
    margin: 0;
    padding: 0rem 0rem 0rem 0rem;
    list-style-type: none;
    max-height: 50vh;
  }

  .installsList {
    position: relative;
    margin: 0;
    padding: 0rem 0rem 0rem 0rem;
    list-style-type: none;
    max-height: calc(60vh - 111px);
    overflow: auto;

    .loadInstall {
      padding: 0.7rem 0.7rem 0.5rem;
      margin: 10px 6px 10px 6px;
      border-radius: 6px;
      min-height: 60px;
      max-height: 60px;
      content: '';
      display: block;
      max-width: 100%;
      background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
      background-size: 300% 300%;
      animation: cycle 1s ease infinite;
      overflow: hidden;
    }
  }
}

.previewInstall {
  position: relative;

  .previewHeader {
    height: 60px;
    max-height: 60px;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
    padding-right: 1rem;
    overflow-x: auto;
    overflow-y: hidden;

    .leftHeader {
      display: flex;
      align-items: center;
      max-height: 60px;

      .imageButton {
        width: 1rem;
        padding: 1rem;
        cursor: pointer;
      }

      .imageButterPlaceholder {
        display: inline-block;
        padding: .5rem;
      }

      .installDetails {
        text-transform: uppercase;
        font-size: 14px;
        color: $spun-pearl;
        padding-top: 3px;
        padding-right: 12px;
        max-width: 50rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .installTitle {
        @extend %chakra-petch-bold-italic-uppercase;

        font-size: 22px;
        padding-right: 12px;
        max-height: 24px;
        max-width: 50rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .previewDetails {
    display: grid;
    position: relative;
    grid-template-columns: 3fr 1fr;
    margin: 0;
    padding: 0rem 0rem 0rem 0rem;
    height: calc(60vh - 60px);

    .previewSlides {
      border-radius: 6px;
      margin: 6px;
      overflow-y: auto;

      .previewSlide {
        width: 100%;
        padding-right: 6px;
        background-color: $tuna;
        margin-bottom: 16px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .previewDevice {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $mirage;
      border-radius: 6px;
      margin: 6px;
      overflow-y: auto;
    }
  }
}


  .renameInstallForm {
    background-color: #{$modal-bg};
    border-radius: 4px;
    padding: 16px;
    pointer-events: all;
  }

  .renameInstallHeader {
    margin-bottom: 1rem;
    font-size: 17px;
  }

  .renameInstallText {
    margin-bottom: 1rem;
    font-size: 17px;
    color: $nav-text;
  }

.listItem {
  display: grid;
  grid-template-columns: minmax(300px, 2fr) minmax(200px, 1fr) minmax(200px, 1fr) 160px;
  min-width: 880px;
  min-height: 60px;
  margin: 10px 6px 10px 6px;
  background-color: $roster-bg;
  overflow: visible;
  padding: 0.7rem 0.7rem 0.5rem;
  border-radius: 6px;

  .listTitle {
    overflow: hidden;
    overflow-wrap: break-word;

    .name {
      display: block;
      font-size: 17px;
      width: 95%;
    }

    .positions {
      font-size: 12px;
      color: darkgrey;
    }
  }

  .presentedby {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .category {
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: minmax(200px, 3fr);
    overflow: hidden;
    overflow-wrap: break-word;
  }

  .buttons {
    display: inline-flex;
    vertical-align: middle;

    .previewButton {
      margin-right: 6px;
      margin-left: 12px;
    }
  }
}
