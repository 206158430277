@import "../../../styles/variables";

.tagTab {
    .categorySection {
        margin-bottom: 2rem;
    }

    .inputRow {
        width: 100%;
        margin-top: .75rem;
        display: flex;
        align-items: center;
        transition: 2s ease-in !important;

        .dragButton {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            background-color: transparent;

            &:hover {
                background-color: $trout;
            }

            &>svg {
                fill: $spun-pearl;
            }
        }

        .dragButton {
            width: 24px;
            height: 24px;

            &:hover {
                background-color: transparent;
                cursor: grab;
            }
        }

        .button {
            margin-left: 0.25rem;
        }

        .arrowButton {
            margin: unset;
        }

        .arrowButton:hover {
            background-color: unset !important;
        }

        .plusIcon {
            transition: opacity .25s ease;
            opacity: 0;
            pointer-events: none;

            &.showing {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .categoryName {
        text-transform: uppercase;
        font-size: 13px;
        color: var(--ion-color-medium);
    }
}

.tabContent {
    width: 100%;

    .tabHeader {
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bolder;
        margin-bottom: 1.25rem;
    }
}