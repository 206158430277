@import "../../styles/variables";

.header {
  display: grid;
  grid-template-columns: 117px 1fr 56px 70px 240px;
  gap: 1rem;
  width: 100%;
  padding: 1.25rem 2rem;
  border-bottom: 1px solid $border;

  .mobileNavHamburger {
    display: none;
  }

  //TODO: 1280px
  @include media-min-width(xl) {
    grid-template-columns: 117px 1fr 70px 80px 300px;
    gap: 2rem;
  }

  //TODO: 1024px
  @include media-max-width(lg) {
    // grid-template-columns: 20px 117px 70px 80px 300px;
    grid-template-columns: 4vw 28vw 7vw 9vw 30vw;
    gap: 2rem;

    .mobileNavHamburger {
      display: block;
    }
  }

  //TODO: 768px
  @include media-max-width(md) {
    grid-template-columns: 20px 117px 70px 80px 300px;
    gap: 2rem;

    .mobileNavHamburger {
      display: block;
    }
  }

  .helpLink {
    z-index: 5;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  .logo {
    @extend %chakra-petch-bold-italic;

    color: var(--ion-color-tertiary);
    align-self: center;
  }

  div {
    align-self: center;
  }

  .accountWidget {
    position: relative;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .customLabel {
      position: absolute;
      left: 1rem;
      z-index: 5;
      resize: horizontal;
      display: flex;
      justify-content: center;
      width: 65%;

      .teamType {
        color: var(--ion-color-medium);
      }
    }

    .teamPicker {
      z-index: 4;

      >div {
        max-height: 32px;
        border-radius: 16px;
      }

      // input {
      //   visibility: hidden;
      // }
    }

    .teamPickerBg {
      width: 100%;
      height: 32px;
      border-radius: 16px;
      background-color: $card-bg;
      z-index: 4;
    }

    .accountMenu {
      position: absolute;
      right: 0;
      top: 50%;
      width: 130px;
      z-index: 3;
      cursor: pointer;

      @include media-min-width(xl) {
        width: 160px;
      }

      &::after {
        visibility: hidden;
      }

      >div {
        position: absolute;
        max-height: 32px;
        background-color: transparent;
      }

      input {
        visibility: hidden;
      }

      ul {
        padding: 0;

        li {
          &:first-child {
            border-bottom: 1px solid $border;
            padding: 0.3rem 1rem;
          }

          &:last-child {
            padding: 0.3rem 1rem;
          }
        }
      }
    }
  }


}

.mobileNav {
  position: absolute;
  left: 0;
  top: 0;
  justify-content: center;
  background: $steel-gray;
  width: 150px;
  height: 100vh;
  z-index: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  display: none;

  @include media-max-width(lg) {
    display: block;
  }

  ul {
    list-style: none;
    display: inline-grid;

    li {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;

      button:hover {
        background-color: unset !important;
      }

      button:focus {
        border: unset !important;
      }

      span {
        font-size: large;
      }
    }

    a {
      margin-right: 0.5rem;
      text-decoration: unset;
      color: $nav-text;
      margin-top: 8px;

      &[class="active"] {
        color: var(--ion-color-primary);
        font-size: 15px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.hideMobileNav {
  visibility: hidden;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0);
}

.showMobileNav {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.TeamListing {
  margin: 0 0 60px;
  padding: 0;
  max-width: 800px;

  &Single {
    display: grid;
    grid-template-columns: 74px 1fr 120px 140px 75px;
    gap: 18px;
    background-color: $mirage;
    border-radius: 8px;
    padding: 18px 27px;
    align-items: center;
    margin-bottom: 16px;

    img {
      max-width: 100%;
    }

    &Details {
      font-size: 14px;
      color: $spun-pearl;
      margin-bottom: 16px;

      strong {
        display: inline-block;
        font-size: 17px;
        padding-right: 8px;
        color: $white;
        overflow-wrap: break-word;
        max-width: 200px;
      }

      &Address {
        color: $white;
      }

      &SmallHeader {
        text-transform: uppercase;
        color: $white;
        font-size: 11px;
        display: block;
        line-height: 0.5;
      }

      &Color {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-right: 8px;
        margin-top: 12px;
      }

      &Setup {
        width: 80px;
        margin: 8px 0px;
      }

      &Tooltip {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 14px;

        &Line {
          margin: 10px 5px;
          margin-right: 10px; // Makes the centering look better

          >a {
            color: $dodger-blue-2;
            text-decoration: underline;

            &:hover {
              color: $dodger-blue;
            }
          }
        }

        &Bullet {
          display: inline-block;
          width: 16px;
          height: 16px;
          border: 2px solid $alto;
          border-radius: 10px;
          position: relative;
          vertical-align: middle;
          margin-right: 14px;

          &>svg {
            position: absolute;
            left: 2px;
            top: 3px;
            width: 12px;
            height: 12px;
            fill: transparent;
            display: none;
          }

          &[class~="active"] {
            border-color: $lightning2;

            &>svg {
              display: block;
            }
          }
        }
      }
    }
  }

  &Empty {
    display: grid;
    grid-template-columns: 74px 1fr 120px;
    gap: 18px;
    background-color: $tuna;
    border-radius: 8px;
    padding: 18px 27px;
    align-items: center;
    margin-bottom: 16px;

    &Team {
      border: 2px solid $abby;
      border-radius: 37px;
      width: 74px;
      height: 74px;
      font-size: 11px;
      color: $spun-pearl;
      text-align: center;
      padding: 9px;

      strong {
        display: block;
        font-size: 24px;
        color: $white;
      }
    }
  }

  &PendingInvite {
    display: grid;
    grid-template-columns: 74px 1fr 80px 80px;
    gap: 18px;
    background-color: $mirage;
    border-radius: 8px;
    padding: 18px 27px;
    align-items: center;
    margin-bottom: 16px;

    &TeamName {
      @extend %body-strong;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}