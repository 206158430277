@import "../../../styles/variables";

.playSetThumbnail {
  width: 100%;
  height: inherit;
  cursor: pointer;
  background: var(--ion-color-secondary);
  border: 2px solid $thumbnail-border;
  border-radius: 6px;
  overflow: hidden;

  .playsCountContainer {
    position: absolute;
    bottom: 15px;
    left: 5px;
    z-index: 1;

    span {
      background-color: #3B6AEB;
      font-size: 11px;
      font-weight: 500;
      padding: 1px 5px;
      border-radius: 6px;
    }
  }

  label {
    display: block;
    line-height: 0;
  }


  input[type="checkbox"]~span {
    position: relative;

    &::before,
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    &:after {
      top: 1px;
      left: 1px;
    }
  }

  input~span {
    padding-left: 0;
  }
}

// .editPlaySetModal {
//   background-color: var(--ion-color-secondary) !important;
//   width: 500px;
//   padding: 0 !important;
// }

.loader {
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 60%;
    background-image: linear-gradient(270deg, var(--ion-color-secondary) 35%, $loader-light, var(--ion-color-secondary) 65%);
    background-size: 300% 300%;
    animation: cycle 1s ease infinite;
    border-radius: 4px;
  }
}