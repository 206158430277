@import "../../../styles/variables";

.mediaThumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;

  &.InstallBuilderSlideImage {
    width: 340px;

    img {
      border-radius: 6px;
    }
  }

  &.deletedMedia {
    color: var(--ion-color-danger);
    padding: 0 1rem;
    text-align: center;
  }

  .mediaImage {
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
  }

  .videoThumbnailOverlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    .playIcon {
      width: 56px;
    }
  }

  .audioIcon {
    width: 48px;
  }
}
