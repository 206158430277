@import "../../styles/variables";

// $app-header-height: 77px; // 77px app header height + 16px padding
$page-title-and-filters-height: 117px;

.dashboard {
    height: calc(100vh - #{$app-header-height});

    .dashboardContainer {
        display: flex;
    

        .dashboardContent {
            padding: 26px 24px;
            width: calc(100% - 200px);
            overflow-y: auto;
            max-height: calc(100vh - #{$app-header-height});

            @include media-max-width(xl) {
                width: calc(100% - 150px);
            }
        }
    }

    .dashboardWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        padding-right: 1px;
        // background-color: black;
        justify-content: center;
        justify-items: center;
        align-items: center;
        display: flex;
    }
}

.dashboardHeader {
    justify-content: space-between;
    display: flex;
    margin-bottom: 26px;

    &__Title {
        font-size: 17px;
        line-height: 27px;

        &:last-of-type {
            margin: 0;
        }
    }
}

.dashboardCard {
    background: $tuna;
    border-radius: 6px;
    padding: 0.75rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

    .cardTitle {
        @extend %chakra-petch-h4;

        color: white;
        margin-bottom: 4px;
    }

    .cardSubtitle {
        color: $spun-pearl;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .chartContainer {
        flex-grow: 1;
        min-height: 0px;
        height: 100%;
    }
}

.chartCardRow {
    display: flex;
    justify-content: space-between;

    >div {
        width: calc(50% - 10px);
        height: 320px;

        &:not(:last-of-type) {
            margin-right: 20px;
        }
    }
}

.searchAndFilterBtn {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;

    >div {
        min-width: 200px;
        max-width: 300px;
    }

    .filterBtns {
        min-width: 300px;

        .label {
            .select {
                height: 30px !important;
            }
        }
    }

    .toggleTableData {
        padding-top: 1px;
        margin-left: 2px;
    }

    .selectCategories {
        >div {
            max-height: 32px;
        }
    }
}

.searchAndFilterBtnForPlayerDrillIn {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;

    >div {
        min-width: 150px;
        max-width: 200px;
    }

    .selectCategories {
        >div {
            max-height: 32px;
        }
    }
}

.nameColumn {
    display: flex;
    align-items: center;
}

.lessonName {
    text-transform: capitalize;
    color: $lightning2;
}

.playName {
    text-transform: capitalize;
    color: $lightning2;
}

.chartCardRowHalfs {
    display: flex;
    justify-content: space-between;

    >div {
        width: 50%;
        height: 300px;

        &:not(:last-of-type) {
            margin-right: 1.25rem;
        }
    }
}

.contentWrapper {
    padding: 24px;
    background: $steel-gray;
}

.dashboardCard {
    background: $tuna;
    border-radius: 6px;
    padding: 0.75rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

    .cardTitle {
        @extend %chakra-petch-h4;

        color: white;
        margin-bottom: 4px;
    }

    .cardSubtitle {
        color: $spun-pearl;
        font-size: 13px;
        margin-bottom: 20px;
    }

    .chartContainer {
        flex-grow: 1;
        min-height: 0px;
        height: 100%;
    }

    .cardSubtitleWithBottomBorder {
        color: #ffffff;
        font-size: 13px;
        margin-bottom: 20px;
        border-bottom: 1px solid $trout;
        padding-bottom: 10px;
        margin-left: -24px;
        margin-right: -24px;
        padding-left: 15px;
    }
}


.searchAndFilterBtnForDrillIn {
    display: flex;
    justify-content: space-between;

    .filterBtns {
        min-width: 300px;
    }
}

.drillInTitle {
    text-transform: capitalize;
    color: $lightning2;
    cursor: pointer;
}

.breadcrumb {
    display: flex;
}

.media {
    height: 660px !important;
    border-radius: 6px;
    border: 2px $abby solid;

    display: flex;
    justify-content: center;
    align-items: center;
}

.mediaForPlayDrillIn {
    height: 660px !important;
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;

    >div:first-child {
        display: flex;
        align-items: center;
    }
}

.graphs {
    height: 100% !important;
}

.arrowRight {
    width: 20px;
}

.userListUser {
    display: flex;
    align-items: center;
}