@import "../../../styles/variables";

.quizCard {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 16px;
  color: $white;
  background-color: $bright-gray;
  border-radius: 6px;

  &ButtonsWrapper {
    display: flex;
    align-items: center;
  }

  &ButtonWrapper {
    flex: 1;
    margin-right: 24px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &TermsWrapper {
    font-size: 12px;
    text-transform: uppercase;
  }

  &Name {
    font-size: 17px;
  }
}
