@import "../../styles/variables";

.adminPage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  position: relative;

  &__Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Actions {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__Filters {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
  }
}

.teamSetupRank {
  margin-left: 1px;
  margin-right: 1px;
}

.teamSetupTooltip {
  &[class~="light"] {
    font-size: 14px;
    background-color: white !important;
    color: black !important;
  }
}

.teamSetupArrow {
  &[class~="light"] {
    color: white;
  }
}

.freeTier {
  color: white;
  padding: 6px;
  text-align: center;
  width: 90px;
  margin-left: auto;
  margin-right: auto;
}

.tier {
  border-radius: 3px;
  color: $mirage;
  width: 90px;
  padding: 6px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &Platinum {
    @extend .tier;
    background: linear-gradient(140.67deg, #bebebe 12.93%, #ffffff 77.48%);
  }
  &Gold {
    @extend .tier;
    background: linear-gradient(140.67deg, #ffbc17 12.93%, #ffea2d 77.48%);
  }
  &Silver {
    @extend .tier;
    background: linear-gradient(140.67deg, #a2a2a2 12.93%, #e1e1e1 77.48%);
  }
  &Bronze {
    @extend .tier;
    background: linear-gradient(140.67deg, #ff9d2a 12.93%, #ffc9a1 77.48%);
  }
  &Trial {
    @extend .tier;
    background: linear-gradient(140.67deg, #23aac1 12.93%, #23aac1 77.48%);
  }
  &Premier {
    @extend .tier;
    background: linear-gradient(140.67deg, #23aac1 12.93%, #23aac1 77.48%);
  }
}

.iconCell {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
}

.searchBar {
  display: flex;
  justify-content: flex-end;

  label {
    width: 30%;
    min-width: 280px;
  }
}

.adminContainer {
  display: flex;
  flex-direction: column;

  .searchBar {
    display: flex;
    justify-content: flex-end;

    label {
      width: 30%;
      min-width: 250px;
    }
  }

  .userList {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }

  &__Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__SingleSelect {
    width: 30%;
    min-width: 250px;
  }

  &__Body {
    margin-top: 1rem;
  }

  &__Table {
    position: relative;

    &Grid {
      display: grid;
      grid-template-columns: 80px 350px 80px 1fr repeat(3, auto);
      align-items: center;
      column-gap: 2rem;
      margin-bottom: 0.2rem;
      overflow-x: auto;

      &:last-of-type {
        margin-bottom: 0;
      }

      & > span {
        margin-bottom: 0.5rem;
        font-size: 10px;
        text-transform: uppercase;
        color: $spun-pearl;
      }
    }
  }
}

.licenseHolderModal,
.addLicensesModal {
  width: 450px !important;
  max-width: 450px !important;
  &__Row {
    width: 100%;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.manageLicenseModal {
  &__Header {
    padding-bottom: 1rem;
    margin: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px #353849;
  }

  &__Body {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__Row {
    width: 100%;
    margin-bottom: 16px;
    margin-right: 8px;
    padding-right: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__ButtonsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Footer {
    margin-top: 16px;
  }

  &__Stats {
    background-color: $mirage;
    padding-left: 16px;
    margin-left: 8px;
    margin-right: -16px;
    &Header {
      margin-top: 2rem;
    }
  }
}

.addLicensesModal {
  &__Row {
    width: 100%;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__ButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  &__Footer {
    margin-top: 16px;
  }
}

.licenseStatBlock {
  &__Label {
    font-family: $quantico;
    font-size: 11px;
  }

  &__Value {
    font-family: $quantico;
    font-size: 14px;
    margin-bottom: 1rem;
  }
}

.orderId {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.tierSelect {
  padding-top: 12px;
}

.paymentSelect {
  display: flex;
  flex-direction: row;
  padding-top: 8px;
  &__Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
  }
}

.tnRepSelect {
  padding-top: 12px;
}

.suspendLicenseSwitch {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 4px;
  gap: 12px;
}

.incrementTeamsButtons {
  display: flex;
  column-gap: 8px;
}

.filterLicenseModal {
  &__Body {
    max-height: 360px;
    max-width: 500px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__Row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 8px;
    margin-right: 8px;
    padding-right: 16px;
    gap: 10px;
    &__TnRep {
      margin-bottom: 0px;
    }
  }

  &__Header {
    font-weight: 700;
    margin-top: 16px;
  }

  &__Payment {
    display: flex;
    column-gap: 8px;
  }

  &__Footer {
    margin-top: 16px;
  }

  &__ButtonsWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.licenseHolderTableHeader {
  display: grid;
  grid-template-columns: 1fr 120px 120px;

  span:not(:first-child) {
    text-align: center;
  }
}

.listItem {
  display: grid;
  grid-template-columns: 1fr 120px 120px 1rem;
  grid-template-areas:
    "summary summary summary spacer"
    "licenses licenses licenses licenses";
  padding: 0;
  max-height: 52px;
  background-color: $roster-bg;
  overflow: hidden;
  transition: background-color 0.5s;

  > span {
    cursor: pointer;
  }

  &:hover {
    background-color: lighten($roster-bg, 2);
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }

  span:not(:first-child) {
    text-align: center;
  }

  &.expanded {
    max-height: 1000px;
  }

  .active {
    padding: 1rem 0 0;
    color: var(--ion-color-success);
  }

  .inactive {
    padding: 1rem 0 0;
    color: var(--ion-color-danger);
  }

  .unassigned {
    color: $nav-text;
  }

  .email {
    padding: 1rem 0 1rem 1rem;
  }

  .licensesList {
    grid-area: licenses;
    position: relative;
    margin: 0;
    padding: 0.5rem 1rem 0.5rem 2rem;
    background: var(--ion-color-secondary);
    list-style-type: none;

    &Item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      &Column {
        display: flex;
        align-items: flex-start;

        span {
          &:first-of-type {
            margin-right: 4px;
          }

          &:last-of-type {
            flex: 1;
            text-align: left;
          }
        }
      }
    }

    &LeftSide {
      display: flex;
      align-items: center;
      width: 100%;

      div {
        margin-right: 2rem;

        &:first-of-type {
          max-width: 55px;
        }

        &:last-of-type {
          flex: 1;
          margin-right: 0;
        }

        &:nth-of-type(4) {
          width: 160px;
        }
      }
    }

    &RightSide {
      margin-left: 1rem;
    }

    &EditButtonWrapper {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 20px;
    }
  }
}

.licenseLabel {
  display: inline-flex;
  z-index: 10000;
  pointer-events: all !important;
  span {
    margin-right: 6px;
  }
  .icon :hover {
    cursor: pointer !important;
  }
}
