@import "../../styles/variables";

.playsGallery {
  position: relative;

  &Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    box-shadow: inset 0px -1px 0px $bright-gray;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 14px 0;
  }

  &Title {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
  }

  &CloseButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;

    &:hover {
      cursor: pointer;
    }

    &>svg {
      fill: $white;
    }
  }

  &Filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
  }

  &SearchWrapper,
  &SortWrapper {
    width: 200px;
  }

  &PlaybookWrapper {
    position: relative;
    width: 100%;
    height: 500px;
    padding-right: 1px;
  }

  &PlayCardItem {
    display: inline-flex;
    margin-right: 18px;
    margin-bottom: 24px;

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }

  &PlayCard {
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 226px;
    padding: 8px 8px 11px;
    background-color: $bright-gray;
    border: 2px solid transparent;
    border-radius: 6px;

    &:hover {
      cursor: pointer;
    }
  }

  &PlayCardSelected {
    border: 2px solid $lightning2;
  }

  &PlayCardThumbnail {
    width: 100%;
    height: 150px;
  }

  &PlayCardTitle {
    width: 100%;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 20px;
  }

  &PlayCardTags {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin-top: 4px;
  }

  &PlayCardTag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin-right: 4px;
    padding: 1px 8px;
    font-size: 11px;
    line-height: 1.4;
    color: $ebony-clay;
    white-space: nowrap;
    border-radius: 100px;
    background-color: $spun-pearl;

    &:last-of-type {
      margin-right: 0;
    }
  }
}