@import "../../../../../styles/variables";
@import "../../../../../components/Modal/Modal.module.scss";

.renameQuizForm {
  background-color: #{$modal-bg};
  border-radius: 4px;
  padding: 16px;
  pointer-events: all;
}

.renameQuizHeader {
  margin-bottom: 1rem;
  font-size: 17px;
}

.renameQuizText {
  margin-bottom: 1rem;
  font-size: 17px;
  color: $nav-text;
}