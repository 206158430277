@import "../../../styles/variables";

.deletePlayModal {
  .buttonRow {
    display: flex;
    margin-top: 2rem;
    justify-content: flex-end;

    button {
      width: inherit;

      &:first-of-type {
        margin-right: 1rem;
      }
    }
  }

  p {
    color: $delete-modal-text;
  }
}
