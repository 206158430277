@import "../../styles/variables";
@import "../../components/Modal/Modal.module.scss";

.verticalFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.wrapper {
    @extend .modalWrapper;
    top: 0;
    left: 0;
    z-index: 7;
    .welcome {
        overflow: hidden !important;

        @extend .modal;
        background-color: $modal-bg;
        grid-template-rows: 55px 1fr;
        padding: 0;
        width: 995px;
        left: 420px;
        top: 262px;
        border-radius: 12px;
        .modalHeader {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            background-color: #303241;
            height: 92px;
            margin: 0;
        }
        .modalFooter {
            height: 92px;
            margin: 0;
            background-color: #303241;
        }
        .footer {
            height: 100%;
            padding: 36px;
            position: relative;
            background-color: #303241;

            > div {
                position: absolute;
                left: 46%;
                bottom: 120px;
            }
        }
        .closeIcon {
            float: right;
            padding: 0.2rem;
            cursor: pointer;
        }
        .welcomeBody {
            display: flex;
            height: 475px;
            flex-direction: column;
            gap: 25px;
            align-items: center;
            padding: 36px 80px 70px;
            text-align: center;
            background-color: #151724;

            &.image {
                height: 220px;
                margin: auto;
                overflow: hidden;
                display: block;
            }
        }
    }
}
.welcomeOverlay {
    top: 0;
    left: 0;
    z-index: 6;
}


.welcome h3,
h4 {
    width: 100%;
    text-align: center;
}


