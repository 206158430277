@import "../../styles/variables";

$app-header-height: 77px;

.installsPage {
  height: 100%;
  // Logic for the top two sections flex
  &Wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    & > * {
      flex: 1;
    }
  }

  // Top right, aligned with title
  &HeaderButtons {
    text-align: right;
    & button {
      width: fit-content;
      display: inline-flex;
      margin-left: 18px;
    }
    .libraryButton{
      padding-bottom: 3px !important;
    }
  }

  &CategorySelect {
    & > label {
      width: 190px;
    }
  }
  &Search {
    text-align: right;
    & > label {
      width: 250px;
    }
  }

  &FilterBar {
    display: flex;
    align-items: center;
    & > div {
      flex-grow: 1;
      & > div {
        max-width: 250px;
        vertical-align: middle;
      }
    }
    & > label {
      max-width: 250px;
      place-self: flex-start;
    }

    &CategoryCombo {
      display: flex;
      align-items: flex-start;
    }
  }

  &List {
    &Header {
      padding-bottom: 0;
      grid-template-columns: 1fr 150px 75px 180px 75px 75px 75px;
      &[class~="simplified-admin"] {
        grid-template-columns: 1fr 150px 75px 35px;
      }
      gap: 16px;
      & > span {
        &:first-child {
          justify-self: start;
        }
        align-self: center;
        justify-self: center;
      }
    }
    &Actual {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    &Item {
      color: $spun-pearl;
      grid-template-columns: 1fr 150px 75px 180px 75px 75px 75px;
      &[class~="simplified-admin"] {
        grid-template-columns: 1fr 150px 75px 35px;
      }

      margin: 12px 0;
      display: grid;
      gap: 16px;
      > * {
        &:first-child {
          justify-self: start;
        }
        align-self: center;
        justify-self: center;
      }
      & [class~="title"] {
        font-size: 17px;
        color: $whisper;
        margin-top: -5px;
        word-break: break-word;
      }
      & [class~="category"] {
        word-break: break-word;
      }
      &Actions {
        & > img {
          margin-left: 12px;
          cursor: pointer;
          &:first-child {
            margin-left: 0;
          }
        }
      }
      &ActionButton {
        width: 18px;
        height: 18px;
        padding: 8px;
        border-radius: 4px;
        transition: background-color 0.3s;
        &:hover,
        &[class~="active"] {
          background-color: $trout;
        }
      }
      &ActionOverflow {
        margin: 0;
        padding: 0;
        list-style: none;
        & > li {
          padding: 8px 16px;
          margin-right: -7px;
          cursor: pointer;
          transition: all 0.3s;
          &:first-child {
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
          }
          &:hover {
            color: #000 !important;
            background-color: $lightning2;
          }
        }
      }
      &NoAvgScore {
        font-size: 17px;
      }

      &AvgScore {
        font-size: 17px;
        text-decoration: underline;
      }
    }
    &DraftItem {
      & [class~="title"] {
        color: $jumbo;
      }
    }
  }

  &Empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $tuna;
    height: 500px;
    padding: 0;
    border-radius: 6px;
    align-content: center;
    justify-content: center;
    & button {
      display: inline;
      margin: 0 9px;
    }
    h3 {
      font-size: 24px;
      margin-bottom: 6px;
      margin-top: -12px;
    }
  }
  &Actions {
    display: flex;
    margin-top: 24px;
  }
  &Subline {
    color: $spun-pearl;
  }

  &Archive {
    &Header {
      grid-template-columns: 1fr 85px 200px 75px 75px 100px 100px 100px 40px;
      &[class~="simplified-admin"] {
        grid-template-columns: 1fr 100px 95px 40px;
      }
      margin: 0;
      padding: 32px 0 0 0;
    }
    &Actual {
      margin-top: 16px;
      margin-bottom: 32px;
      padding: 0;
      list-style: none;
    }
    &NoItems {
      color: $jumbo;
      background-color: $tuna;
      border-radius: 6px;
      margin: 16px 0;
      display: flex;
      height: 100px;
      align-items: center;
      justify-content: center;
    }
    &Item {
      display: grid;
      grid-template-columns: 325px 85px 200px 75px 75px 100px 100px 120px 34px;
      overflow-wrap: break-word;
      &[class~="simplified-admin"] {
        grid-template-columns: 878px 100px 100px 34px;
      }
      border: 1px solid $abby;
      &:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
      &:not(:last-child) {
        border-bottom: 0;
      }
      &:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }
      & > &Cell {
        border-right: 1px solid $abby;
        padding: 6px 8px;
        &:last-child {
          border-right: 0;
        }
        &Bar > div {
          margin-top: 2px;
          margin-bottom: -2px;
        }
        &List span {
          padding-left: 6px;
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }

  // Install Builder
  &BuilderPreviewContainer {
    display: flex;
    overflow: hidden;
    height: 100%;
  }

  &BuilderContainer {
    width: 61%;
    padding: 24px 0;
    max-height: calc(100vh - #{$app-header-height});
    overflow-y: auto;
  }

  &BuilderWrapper {
    width: 100%;
    max-width: 840px;
    margin: 0 auto;
  }

  .installPreviewContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 39%;
    background-color: $mirage;
    box-shadow: -1px 0px 0px $bright-gray;
    max-height: calc(100vh - #{$app-header-height});

    &PreviewHeader {
      color: $spun-pearl;
      @extend %body-strong;
      position: absolute;
      top: 20px;
    }
  }
}

.playerToolTipList {
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    display: grid;
    gap: 4x;
    margin: 8px 0;
    align-items: center;
    grid-template-columns: auto 1fr;
  }
  &.playerToolTipListScores {
    li {
      grid-template-columns: auto 1fr auto;
    }
  }
  &Title {
    display: block;
    font-weight: 900;
    padding-top: 12px;
    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }
  }
  &Name {
    padding-left: 4px;
  }
  &Score {
    padding-left: 16px;
    padding-right: 4px;
  }
  img {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    vertical-align: middle;
    margin-right: 4px;
  }
}

.installsFeatured {
  background-color: $tuna;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 24px;
  &Head {
    display: grid;
    grid-template-columns: 2fr 1fr;
    font-size: 14px;
    color: $spun-pearl;
    &Toggle {
      color: $lightning2;
      cursor: pointer;
      text-decoration: underline;
      padding-left: 8px;
      &:hover {
        color: $lightning1;
      }
    }
    &Pagination {
      text-align: right;
      transition: all 0.3s;
      &[class~="hide"] {
        opacity: 0;
      }
    }
    &Arrow {
      display: inline-flex;
      width: 16px;
      height: 16px;
      padding: 4px;
      margin: -5px 0;
      margin-left: 16px;
      cursor: pointer;
      border-radius: 4px;
      justify-content: center;
      transition: all 0.3s;
      &[class~="disabled"] {
        opacity: 0.5;
        &:hover {
          filter: none;
          cursor: default;
        }
      }
      &:hover {
        filter: brightness(123%);
      }
    }
  }
  &List {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 16px;
    text-transform: uppercase;
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
    &[class~="open"] {
      padding-top: 16px;
    }
    & > li {
      margin: 0;
      padding: 16px;
      display: block;
      background-color: $bright-gray;
      border-radius: 6px;
      display: grid;
      grid-template-rows: min-content 1fr min-content;
      cursor: pointer;
      transition: all 0.3s;
      opacity: 1;
      &[class~="hide"] {
        height: 0px;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0;
      }
    }
    &Type {
      font-size: 11px;
      line-height: 15.4px;
      color: $jumbo;
      margin-top: -4px;
      margin-bottom: 4px;
    }
    &Title {
      font-size: 14px;
      line-height: 22.4px;
      color: white;
      margin-bottom: 8px;
    }
  }
}

// Modal
.installDeleteModal {
  max-width: 520px !important;
  padding: 14px 24px 24px 24px !important;
  p {
    font-size: 17px;
    color: $spun-pearl;
  }
  &Message {
    margin-top: -8px;
    margin-bottom: 24px;
  }
  &Actions {
    text-align: right;
    button {
      display: inline-block;
      width: fit-content;
      margin-left: 12px;
    }
  }
}


.buttonDismiss{
  color: #3B6AEB !important;
  &:hover {
   background: unset !important;
  }
}
.tooltipStep1Footer{
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

.tooltipStep2Footer{
display: flex;
justify-content: end;
margin: 20px 0 0 0;
}
