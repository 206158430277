@import "../../../styles/variables";

.editPlayDetails {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  background: $play-settings-bg;
  border-top: 1px solid var(--ion-color-secondary);
  padding: 22px 24px;

  .topRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    label {
      margin-right: 1rem;
    }

    button {
      width: 140px;
      padding: unset;

      &:not(:last-of-type) {
        margin-right: 1rem;
      }
    }
  }

  .selectsContainer {
    margin: 1.25rem 0;

    .selectsContainerRow {
      &:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 1rem;
        margin-bottom: 1rem;
      }
      &:nth-child(2) {
        display: grid;
        gap: 1rem;
      }
    }
    .secondChildOffense {
      grid-template-columns: 1fr 1fr 1fr;
    }
    .secondChildDefense {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    label {
      background-color: $dark-bg;
      border-radius: 4px;
      margin-top: 25px;

      > div:first-of-type {
        transform: translate(0px, -25px);
      }

      > div:nth-of-type(2) {
        margin-top: -27px;
      }
    }
  }
}
