@import "../../styles/variables";

$spinnerDiameter: 94px;

.pdfImport {
  padding: 24px 40px;
  height: 100%;
  overflow-y: auto;
  position: relative;

  &.noHorizontalPadding {
    @include media-max-width(sm) {
      padding: 24px 0;
    }
  }
  
  &::-webkit-scrollbar {
    display:none;
  }

  .limitWidth {
    max-width: 400px;

    @include media-max-width(sm) {
      max-width: none;
    }
  }

  .pdfImportStart {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    .instructions {
      @extend .limitWidth;

      display: grid;
      gap: 36px;

      > div {
        font-size: 17px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;

        &.header {
          font-size: 24px;
          font-weight: 700px;
          line-height: 38px;

          @include media-max-width(sm) {
            font-size: 21px;
            line-height: 31px;
          }
        }
  
        img {
          width: 100%;
        }

        &:last-child {
          margin-bottom: 60px;
        }
      }
    }
  }

  .pdfImportChooseFile {
    display: grid;
    justify-content: center;
    gap: 36px;

    @include media-max-width(sm) {
      gap: 20px
    }

    .pdfImportLabel {
      @extend .limitWidth;
      cursor: pointer;
  
      .pdfImportInput {
        display: none;
      }
  
      .fileHandler {
        color: $white;
        border: 2px dashed $jumbo;
        border-radius: 4px;
        height: 76px;
        display: grid;
        grid-auto-flow: column;
        gap: 12px;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;

        &.fileSelected {
          border: 2px solid $white;
        }
      }

      .helperText {
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        color: $spun-pearl;
        width: 100%;
        text-align: center;
      }
    }

    .slideBoxContainer {
      width: 100%;
      height: 323px;
      background: $mirage;

      @include media-max-width(sm) {
        height: 300px;
      }
    }
  }

  .pdfImportFileFormat {
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    height: 100%;

    .header {
      font-size: 17px;
      margin-bottom: 12px;
    }

    .formatChoices {
      display: flex;
      justify-content: center;
      margin-bottom: 50px;

      :first-child {
        margin-right: 38px;
      }
    }

    .playTypeChoices {
      @extend .limitWidth;

      display: grid;
      grid-auto-flow: row;
      gap: 14px;
      margin-bottom: 28px;

      .selected {
        background: $jade;
        border: none;
        color: $mirage;
      }
    }
  }

  .pdfImportProcessing {
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: 40px 1fr 90px 70px;
    justify-items: center;
    gap: 14px;
    height: 100%;

    .header {
      margin-bottom: 16px;
    }

    .helperText, .bottomButtons, .header {
      padding-left: 40px;
      padding-right: 40px;
    }

    .contentContainer {
      background: $mirage;
      min-height: 260px;
      max-height: 460px;
      position: relative;
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;

      @include media-max-width(sm) {
        border-top: 1px solid $spun-pearl;
        border-bottom: 1px solid $spun-pearl;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        max-height: 380px;
      }

      .tryAgain {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > div {
          margin-bottom: 10px;
        }
      }
      
      &.playsContainer {
        overflow-y: auto;
        padding: 12px 40px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 12px;
      }

      .deleteMediaPlayButton {
        background: $trout;
        padding: 10px;
        border-radius: 8px;
        display: grid;
        grid-template-columns: 1fr 108px 36px;
        gap: 10px;

        .playName {
          font-size: 16px;
          font-weight: 700;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-top: 17px;
        }

        .mediaPlayThumbnail {
          max-width: 100%;
          max-height: 100%;
        }

        .mediaPlayThumbnailContainer {
          display: flex;
        }

        .trashIconContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .trashIcon {
          fill: $spun-pearl;
        }
      }
    }

    @keyframes spinner {
      to {transform: rotate(360deg);}
    }
     
    .spinner:before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: $spinnerDiameter;
      height: $spinnerDiameter;
      margin-top: calc(#{$spinnerDiameter} * -1 / 2);
      margin-left: calc(#{$spinnerDiameter} * -1 / 2);
      border-radius: 50%;
      border: 3px solid $sunglow;
      border-top-color: $mirage;
      animation: spinner 1.33s linear infinite;
    }

    .loadingText {
      font-size: 12px;
      margin-top: 150px;
    }

    .helperText {
      font-size: 17px;
      font-weight: 700;
      line-height: 27px;
      text-align: center;


      .mobileText {
        display: none;
        @include media-max-width(sm) {
          display: block;
        }
      }

      .desktopText {
        display: block;
        @include media-max-width(sm) {
          display: none;
        }
      }
    }
  }

  .header {
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    text-align: center;

    @include media-max-width(sm) {
      font-size: 21px;
      line-height: 31px;
    }
  }

  .bottomButtons {
    @extend .limitWidth;

    display: grid;
    gap: 14px;
    align-content: center;
    width: 100%;
  }
}