@import "../../styles/variables";
@import "../../styles/_variables";

.playSettingsToolbar {
  position: relative;
  border-bottom: 2px solid $tuna;

  .modified-tertiary-button { // one-off button styles for our singular light themed page
    color: $jumbo;
    border-color: $mirage;

    &:hover {
      color: $mirage;
    }
  }

  .topToolbar {
    background: $play-editor-bg;
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
    padding-right: 1rem;
    color: $mirage;

    .leftControls {
      display: flex;
      align-items: center;

      button {
        @extend .modified-tertiary-button;
        width: 140px;
        margin-left: 2rem;
      }
    }

    .imageButton {
      width: 1.5rem;
      padding: 1rem;
      cursor: pointer;
    }

    .backButton {
      cursor: pointer;
      padding: 1rem;
    }

    .playTitle {
      @extend %chakra-petch-bold-italic-uppercase;

      font-size: 22px;
      word-break: break-word;
    }

    .playDetails {
      text-transform: uppercase;
      font-size: 14px;
      color: $jumbo;
      padding-top: 3px;
      word-break: break-word;
    }

    .rightControls {
      display: flex;
      align-items: center;

      .helpButton {
        @extend .modified-tertiary-button;
        width: 71px;
      }
    }

    .tabButtons {
      padding-right: 2rem;
      min-width: 250px;

      button {
        background: $spun-pearl;
        letter-spacing: 0.5px;
        height: 30px;
        line-height: 0;
        border-radius: 7.5px;
        font-family: $quantico;
        font-size: 13px;
        color: $mirage;
        text-transform: uppercase;
        padding: 9px 12px;
        border: none;
        margin-right: 0.5rem;

        span {
          margin-left: 6px;
          position: relative;
          top: -1px;
        }

        &:hover {
          background: $lightning1;
        }
      }
    }

    .tabName {
      font-size: 17px;
      letter-spacing: 1px;
      font-weight: bold;
      text-transform: capitalize;
    }

    .doneButton {
      line-height: 0;
      width: 80px;
      height: 38px;
      font-family: $quantico;
      color: $mirage;
      font-size: 15px;
      border: 1px solid $spun-pearl;
      border-radius: 4px;
      background: transparent;
    }
  }
}
