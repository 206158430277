@import "../../styles/variables";

.quizPage {
  display: flex;
  height: 100%;
  max-height: 100%;

  &EditQuizWrapper {
    display: flex;
    justify-content: center;
    width: 61%;
    overflow: auto;
    max-height: 100%;

    @include media-min-width(lg) {
      padding: 20px;
    }

    > div {
      max-width: 1080px;
    }
  }

  &QuizPreviewContainer {
    width: 39%;
    background: $mirage;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &QuizPreviewHeader {
    @extend %body-strong;
    color: $spun-pearl;
    position: absolute;
    top: 20px;
  }

  .emptyStatePrompt {
    background: $tuna;
    border-radius: 6px;
    padding: 20px;
    flex-grow: 1;
    max-height: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      width: unset;
      margin-top: 12px;
    }
  }
}

.buttonDismiss{
  color: #3B6AEB !important;
  &:hover {
   background: unset !important;
  }
}
.tooltipStep1Footer{
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
}

.tooltipStep2Footer{
display: flex;
justify-content: end;
margin: 20px 0 0 0;
}

.tooltipQuizStep1{
margin: 0 0 0 30px;
}
