@import "../../../styles/variables";

.addToGamePlanContainer {
  padding-left: 24px;
  padding-right: 24px;
  max-height: 500px;
  overflow: auto;

  .processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .gamePlanBtns {
    background: $add-to-gamePlan-btn-bg;
    margin: 8px 4px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
