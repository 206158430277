@import "../../styles/variables";

// $app-header-height: 77px; // 77px app header height + 16px padding
$page-title-and-filters-height: 117px;
$modal-header-height:54px;

.thumbnailContainer {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 82%;
  position: relative;

  .audio {
    .audioFullScreen {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #151724;
    }
  }

  label {
    width: 100%;
    height: 100%;

    span:first-of-type {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }
  }

  .thumbnailContents {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  input[type="checkbox"]~span {
    padding-left: 0;

    &::before,
    &::after {
      position: absolute;
      top: 4px;
      left: 4px;
      z-index: 1;
      padding: 2px;
    }

    &:after {
      top: 5px;
      left: 5px;
    }
  }

  .mediaImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .audioIcon {
    padding: 20px;
    width: 36px;
  }

  .videoIcon {
    position: absolute;
    top: 40px;
  }

  .mediaInfo {
    margin-top: 4px;
    font-family: $quantico;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    width: fit-content;
    max-width: 11.5vw;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:last-child {
      font-size: 11px;
      margin-top: 2px;
      color: $jumbo;
    }
  }
}

.mediaWrapper {
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  align-items: 'center';
  height: '100%';
  background-color: '#151724';
}

.mediaGallery {
  display: block;

  .modalHeader {
    padding: 0.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
    padding-left: 1rem;
    margin: 0;
    height: $modal-header-height;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: inset 0px -1px 0px #353849;
  }

  .uploadInProgress {
    width: 240px;
  }

  .uploadInProgress::after {
    display: inline-block;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  @-webkit-keyframes ellipsis {
    to {
      width: 1.25em;
    }
  }

  .closeIcon {
    svg {
      fill: $white !important;
    }
  }

  .newlyUploaded {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0.2;
    }

    100% {
      opacity: 1;
    }
  }

  .mediaAndSidebarGrid {
    display: flex;
    height: calc(100vh - #{$modal-header-height} - #{$app-header-height}) !important;

    .mediaContainer {
      padding-left: 1rem;
      padding-right: 1rem;
      background-color: $steel-gray;
      width: 66.66%;
      padding-bottom: 5rem; // bottom gets cut off without this, not sure why

      .mediaToolbar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;

        .mediaToolbarRight {
          display: flex;
          margin-right: 1rem;

          .gridIconButton {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
            border: 1px solid #7E7E85;
          }

          .listIconButton {
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border: 1px solid #7E7E85;
          }

          .active {
            border-color: #E7E7ED !important;
          }
        }

        .mediaToolbarCenter {
          width: 100%;
        }

        .mediaToolbarLeft {
          display: flex;
          flex-direction: row;
          align-items: center;

          fieldset {
            min-width: 100px;
          }

          .mediaFilter {
            margin: 0;
          }

          .fileUploader {
            cursor: pointer;
            margin: 0 1rem;

            &.first {
              margin: 0 1rem 0 0;
            }

            button {
              pointer-events: none;
            }

            .fileUploaderInput {
              display: none;
            }
          }

          .switchLabel {
            margin-left: 0.5rem;
            white-space: nowrap;
          }
        }
      }

      .uploadDropOver {
        pointer-events: none;

        &>* {
          &::before {
            content: "Drop to Upload";
            position: absolute;
            display: flex;
            inset: 0;
            align-items: center;
            justify-content: center;
            font-size: 24px;
          }

          &>* {
            opacity: 0.125;
            filter: blur(5px);
            transition: all 0.25s ease;
          }
        }
      }

      div[class="virtuoso-grid-list"],
      .grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        gap: 0 1rem;
        padding-right: 0.5rem;
      }

      div[class="virtuoso-grid-item"],
      .loader {
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 6px;
      }

      .loader {
        margin-bottom: 1rem;
        border: 1px solid transparent;
        background-color: $play-card-bg;
        border-radius: 6px;

        &:hover {
          border-color: var(--ion-color-primary-shade);
        }
      }

      .mediaItem {
        color: var(--ion-color-primary);
        border-radius: 4px;
        cursor: pointer;

        &.selected {
          border-color: var(--ion-color-tertiary);
        }

        &.loader {
          padding-bottom: 2.5rem;
        }
      }
    }

    .leftSidebar {
      background-color: $tuna;
      display: flex;
      flex-direction: column;
      width: 18%;
      padding-bottom: 100px; // adjust the height as per footer height
      overflow: auto;


      .leftSidebarBtnContainer {
        margin: 12px 24px;


        .textButton {
          color: var(--ion-color-tertiary);
          margin-left: 6px;
          padding: 4px;
          cursor: pointer;
        }

        .leftSidebarText {
          height: 400px;

          .headerBar {
            display: flex;
            justify-content: space-between;
            color: $spun-pearl;
            font-weight: bolder;
            font-size: 15px;
            margin-top: 0.5rem;

            .headerBarTitle {
              margin-top: 0.3rem;
              color: var(--ion-color-primary);
            }

            img {
              width: 16.5px;
              cursor: pointer;
            }
          }

          .tagFolderContainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(--ion-color-medium);
            padding: 0.5rem 0.5rem;
            text-transform: uppercase;
            cursor: pointer;

            &.selected {
              color: var(--ion-color-primary);
              background: $bright-gray;
            }

            .title {
              text-transform: lowercase;
              width: 75%;

              .arrow {
                display: inline-block;
                margin-right: 0.8rem;
                margin-left: 0.8rem;
                width: 5px;
                height: 5px;
                border-top: 2px solid #ABABB7;
                border-right: 2px solid #ABABB7;
                cursor: pointer;
              }

              .arrowTop {
                transform: rotate(-45deg);
                transition: transform .5s ease-in-out !important;
              }

              .arrowRight {
                transform: rotate(-315deg);
                transition: transform .5s ease-in-out !important;
              }

              .arrowBottom {
                transform: rotate(-225deg);
                transition: transform .5s ease-in-out !important;
              }

              .arrowLeft {
                transform: rotate(-135deg);
                transition: transform .5s ease-in-out !important;
              }
            }

            .title::first-letter {
              text-transform: capitalize;
            }
          }

          .tagContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: var(--ion-color-medium);
            padding: 0.5rem 0.5rem;
            text-transform: uppercase;
            cursor: pointer;

            &.selected {
              color: var(--ion-color-primary);
            }

            .title {
              text-transform: lowercase;
              width: 91%;
              padding-left: 2rem;
            }

            .title::first-letter {
              text-transform: capitalize;
            }
          }

          .mediaTypeFilter {
            display: flex;
            justify-content: space-between;
            color: var(--ion-color-medium);
            padding: 0.5rem 1rem;
            text-transform: uppercase;
            cursor: pointer;

            &.selected {
              color: var(--ion-color-primary);
              background: $bright-gray;
            }

            .mediaType {
              text-transform: lowercase;
            }

            .mediaType::first-letter {
              text-transform: capitalize;
            }
          }
        }

      }

      .expand {
        transition: all 0.4s ease-in;
        opacity: 1;
        visibility: visible;
      }

      .collapse {
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0s ease-in;
      }
    }

    .sidebar {
      background-color: $tuna;
      display: flex;
      flex-direction: column;
      width: 18%;
      padding-bottom: 100px; // adjust the height as per footer height
      overflow: auto;

      .mediaPreview {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .imagePreview {
          max-width: 100%;
          max-height: 100%;
        }

        .videoPreview {
          max-width: calc(100% - 1px);
          max-height: 100%;
          padding-right: 1px;
        }

        .audioPreview {
          padding-top: 1rem;
        }
      }
    }

    .sidebar {
      background-color: $tuna;
      display: flex;
      flex-direction: column;
      width: 18%;

      .multiSelectPreview {
        height: 149px;
        min-height: 149px;
        background-color: $mirage;
        justify-content: center;
        align-items: center;
        display: flex;

        span {
          color: $jumbo !important;
        }
      }

      .sidebarBtnContainer {
        margin: 12px 24px;

        .sidebarBtnsContainer {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .textButton {
          color: var(--ion-color-tertiary);
          margin-left: 6px;
          padding: 4px;
          cursor: pointer;
        }

        .emptySidebarText {
          height: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .sidebarBtns {
          margin-top: 12px;
          width: 100%;

          &:first-of-type {
            margin-top: 0;
          }
        }

        .confirmationButtons {
          display: flex;
          justify-content: flex-end;

          button {
            width: fit-content;

            &:first-child {
              margin-right: 1rem;
            }
          }
        }

        .inputTitle {
          margin: 12px 0 5px 0 !important;
          font-size: 12px;
        }

        .uploadedDate {
          margin-top: 0;
        }

        .tagsList {
          margin: 1rem 0;

          .title {
            text-transform: uppercase;
          }
        }

        .addTagContainer {
          .addTagBtn {
            color: $sunglow;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }


      }

    }

  }



  .tagBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    margin: 0.5rem 0;
    background-color: #151724;

    .tag {
      font-size: 14px;
      text-transform: uppercase;
    }

    .imageButton {
      width: 12px;
      height: 12px;
      align-items: center;

      &:hover {
        transform: scale(1.2);
        transition: all 0.5s ease-in;
        align-items: center;
        cursor: pointer;
      }
    }

  }

  .tagDropDownContainer {
    padding: 0.5rem 1rem;
    border-radius: 6px;
    margin: 0.5rem 0;
    background-color: #151724;
    min-height: 150px;
    max-height: 250px;
    overflow: auto;


    .tagFolderTitle {
      color: $jumbo;
      font-weight: 300;
      letter-spacing: 0;
      text-transform: uppercase;
    }

    .tag {
      color: $spun-pearl;
      margin: 0.3rem 0;
      padding: 0.5rem 0;
      font-size: 16px;
      cursor: pointer;

      &:hover {
        color: #ffffff;
      }
    }

  }

  .expand {
    transition: all 0.4s ease-in;
    opacity: 1;
    visibility: visible;
  }

  .collapse {
    min-height: 0 !important;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-in;
  }

  .listContainer {
    padding: 0;
    height: calc(100vh - 275px);
    overflow-y: auto;
  }

}

.gamePlanPlaysToolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: 56px;
  padding: 8px 24px;
  background: var(--ion-color-primary);
  line-height: 160%;
  z-index: 1;

  .mediaSelected {
    font-size: 17px;
    color: $plays-selected-text;
    margin-right: 48px;
  }

  .selectAllBtn {
    margin-right: 35px;
  }

  .selectButtons {
    color: $gamePlan-select-btn !important;
  }

  .submitButtonContainer {
    display: flex;
    flex-direction: row;

    .deleteBtn {
      margin-right: 16px;
    }

    .addBtn {
      margin-right: 16px;
    }

  }
}


.mediaGallerySetupModal {
  width: 99%;
  min-width: 99% !important;
  min-height: 99% !important;
  padding: 10px !important;
  border-radius: 15px !important;
}

.mediaGallerySetupModalFullView {
  width: 100%;
  min-width: 100% !important;
  min-height: 100% !important;
  padding: 10px !important;
}

.mediaGalleryTagsSetupModal {
  padding: unset !important;
  border-radius: 6px !important;
}

.footer {
  background-color: $tuna;
  height: 64px;
  box-shadow: inset 0px 1px 0px #484A59;
  border-top: 1px solid $trout;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.deleteBtn {
  margin-right: 16px;
}