@import "../../../../styles/variables";

.accountSetupModal {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  color: $white;

  &__Tuna {
    background-color: $tuna;
  }

  &__Navigation {
    display: flex;
    align-items: center;
    padding: 16px 24px 0;
  }

  &__Header {
    @extend %body;

    padding: 16px 24px;
  }

  &__Tabs {
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: $steel-gray;
    border-bottom: 1px solid $trout;

    &Button {
      @extend %small;

      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 51px;
      color: $spun-pearl;
      border: none;
      border-radius: 0;
      background: none;

      &Number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-right: 10px;
        border: 1px solid $spun-pearl;
        border-radius: 50%;
      }

      &Active {
        @extend %small-strong;

        color: $sunglow;

        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 233px;
          height: 3px;
          background-color: $sunglow;
          border-radius: 2px 2px 0 0;
          content: "";
        }

        & > span {
          &:first-of-type {
            border-color: $sunglow;
          }
        }
      }
    }
  }

  &__Body {
    flex: 1;
  }

  &__Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: revert;
    padding: 14px 24px;
    box-shadow: inset 0 1px 0 $trout;

    &LeftSide,
    &RightSide {
      display: flex;
      align-items: center;
      flex: 1;

      & > * {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &RightSide {
      justify-content: flex-end;
    }

    &LeftSide {
      justify-content: flex-start;
    }

    &Button {
      display: flex;
      margin-right: 16px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__Row {
    margin-bottom: 36px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &Clean {
      margin: 0;
    }
  }

  &__Group {
    padding: 16px;
    border: 1px solid $abby;
    border-radius: 6px;
  }

  &__Step {
    &_Header {
      padding: 16px;
      text-align: center;

      &FirstRow,
      &SecondRow {
        display: block;
      }

      &FirstRow {
        @extend %quantico;

        font-size: 20px;
        line-height: 32px;
      }

      &SecondRow {
        margin-top: 20px;
        @extend %chakra-petch-h2;
      }
    }

    &_LicenseActivation,
    &_FreeTrial {
      &Wrapper {
        display: flex;
        flex-direction: column;
        width: 350px;
        margin: 0 auto;

        & > * {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &Input {
        position: relative;

        &Tooltip {
          position: absolute;
          left: calc(100% + 11px);
          top: 50%;
          transform: translateY(-50%);

          &:hover {
            cursor: pointer;
          }

          svg {
            fill: $spun-pearl;
          }
        }
      }
    }

    &_LicenseActivation {
      &Wrapper {
        padding: 64px 0 100px;
      }
    }

    &_FreeTrial {
      &Wrapper {
        padding: 64px 0 160px;
      }
    }

    &_LicenseWelcome {
      padding: 16px 24px 24px;

      &_Image {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }

      &_Buttons {
        display: flex;
        align-items: center;
      }

      &_Button {
        @extend %body-strong;

        height: 105px !important;
        margin-right: 24px;
        flex: 1;

        &:last-child {
          margin-right: 0;
        }
      }

      &_GroupHeader {
        @extend %body-strong;

        margin-bottom: 8px;
      }

      &_Inputs {
        display: flex;
        align-items: center;
        margin: 16px 0;

        & > * {
          margin-right: 9px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }

      &_Input {
        width: 278px;
      }

      &_BuyLicense {
        display: flex;
        align-items: center;

        & > * {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }

        &Text {
          width: 250px;
          color: $spun-pearl;
        }
      }

      &_FreeLicense {
        margin-top: 8px;
      }
    }
  }

  &__Step_1_2 {
    padding: 40px;

    &_Header,
    &_Footer {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &_Header {
      &Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 44px;
        height: 44px;
        border: 4px solid $white;
        border-radius: 50%;

        path {
          stroke: $white;
        }

        &.bronze {
          border-color: #FF9D2A;

          path {
            stroke: #FF9D2A;
          }
        }

        &.silver {
          border-color: #A2A2A2;

          path {
            stroke: #A2A2A2;
          }
        }

        &.gold {
          border-color: $candlelight;

          path {
            stroke: $candlelight;
          }
        }

        &.platinum {
          border-color: #BEBEBE;

          path {
            stroke: #BEBEBE;
          }
        }
      }

      &Text {
        @extend %body;

        margin-top: 16px;
        line-height: 17px;
      }
    }

    &_Footer {
      &Text {
        @extend %body;
      }

      &Button {
        margin-top: 8px;
      }
    }

    &_Body {
      display: flex;
      justify-content: center;
      margin: 40px 0;
    }
  }

  &__Step_2_1 {
    padding: 40px 40px 86px;
  }

  &__Step_2_2 {
    padding: 16px;
  }

  &__Step_2_3 {
    padding: 8px 16px;
  }

  &__Step_3 {
    padding: 40px;

    &__TopBanner {
      padding: 24px;
      background-color: $tuna;
      border-radius: 6px;

      &Title {
        @extend %quantico-h4;

        display: flex;
        align-items: center;

        & > * {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &Description {
        @extend %body;

        margin-top: 16px;
      }

      &TooltipLink {
        color: $sunglow;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__BottomBanner {
      margin-top: 32px;

      &Title {
        @extend %body;
      }

      &Cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 40px;
        row-gap: 20px;
        max-width: 100%;
        margin-top: 16px;
        overflow-y: auto;

        &MultipleRows {
          height: 330px;
        }
      }
    }
  }

  &__TeamCard {
    width: 266px;
  }

  &__SpreadSheetUploadModal {
    display: flex;
    flex-direction: column;
    width: 680px;
    max-width: 680px !important;
    min-width: 680px !important;
    padding: 0 !important;
    background-color: $steel-gray !important;
  }
}
