@import "../../../styles/variables";

.inviteWithCode {
  padding: 0 26px;
  background-color: $trout;
  border-radius: 6px;

  &__Top {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Bottom {
    padding-bottom: 16px;
  }

  &__LeftSide {
    display: flex;
    align-items: center;

    & > * {
      margin-right: 24px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &__Copy {
    @extend %small;

    display: flex;
    flex-direction: column;
    width: 506px;
    color: $spun-pearl;

    & > * {
      margin-bottom: 4px;

      &:first-of-type {
        color: $white;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &__Code {
    padding: 0 16px;

    &Placeholder {
      color: $spun-pearl;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }

  &__CodeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__CodeNumber {
    @extend %quantico-h3;
  }

  &__CodeCopy {
    @extend %small;

    margin: 0;
    padding: 0;
    color: $sunglow;
    line-height: 1;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &__CodeExpirationWrapper {
    display: flex;
    align-items: center;
    height: 30px;
    margin-left: 54px;

    & > * {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__DatePickerWrapper {
    width: 150px;
  }
}
